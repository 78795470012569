import React from 'react';


/**
 * 
 * @param {id} props 
 * @returns 
 */
function LanguageSwitcher (props) {
    const languages = [
        { code: 'en', name: 'English'},
        { code: 'es', name: 'Español'}
      ]

    const options = languages.map(language => (
        <li key={language.code}>
          <button className="language-button" title={language.name} onClick={(e) => {
            props.setLanguage(language.code);
            console.log("setting language to " + language.code)
            }}>
            <span className={"language-code " + (language.code === props.language ? "selected" : "")}>{language.code}</span>
          </button>
        </li>
      ));

    return (
        <>
             <ul className="language-list">
             {options}
             </ul>
        </>
    )
}

export default LanguageSwitcher;