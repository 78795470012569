import React, { useState, useEffect, useContext } from 'react';
import { ORDER_BY_CREATIONDATE_DESC, FILTER_VERIFIED, FILTER_UNVERIFIED, FILTER_ALL } from '../utils/constants.js';
import InfoPane from '../components/InfoPane.js'
import AuthContext from '../stores/authContext'
import { Link } from "react-router-dom"; 


function LearnPage (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)

    const [concepts, setConcepts] = useState([])
    const [relations, setRelations] = useState([])
    const [textSearch, setTextSearch] = useState("")
    const [filterMode, setFilterMode] = useState(FILTER_ALL)
    const [mode, setMode] = useState("relations")
    const [loading, setLoading] = useState(false)

    const requestNextConceptPage = () => {
        const amount = props.amount ? props.amount : 15;

        console.info("Learnpage.requestNextConceptPage -- concepts length and amount requested", concepts.length, amount);

        if(userState && authReady) {

            setLoading(true)
    
            refreshUser()
            
            fetch('/.netlify/functions/load-concepts', 
              {
                  method: 'POST',
                  headers: userState && {
                  Authorization: 'Bearer ' + userState.token
                  },              
                  body: JSON.stringify({
                    page: concepts.length,
                    amount: amount,
                    query: textSearch,
                    sortMode: ORDER_BY_CREATIONDATE_DESC,
                    filterMode: filterMode
                  })
              })
            .then(response => {
                if(!response.ok) {
                    throw Error('Learnpage.requestNextConceptPage -- response NOK',response.errorMessage)
                }
      
                return response.json()
            })
            .then(response => {
                console.info("Learnpage.requestNextConceptPage -- number of concepts loaded",response.length);
                setConcepts(concepts.concat(response))
                setLoading(false)
            })
            .catch(err => {
                console.error(err);
            })
          }

    }

    const requestNextRelationPage = () => {
        var amount = props.amount ? props.amount : 15;
        console.info("LearnPage.requestNextRelationPage -- query", textSearch);



        if(userState && authReady) {

            setLoading(true)
    
            refreshUser()

            fetch('/.netlify/functions/load-relations', 
              {
                  method: 'POST',
                  headers: userState && {
                  Authorization: 'Bearer ' + userState.token
                  },              
                  body: JSON.stringify({
                    page: relations.length,
                    amount: amount,
                    query: textSearch,
                    sortMode: ORDER_BY_CREATIONDATE_DESC,
                    filterMode: filterMode,
                    onlyMasters: true
                  })
              })
            .then(response => {
                if(!response.ok) {
                    throw Error('Learnpage.requestNextRelationPage -- response NOK',response.errorMessage)
                }
      
                return response.json()
            })
            .then(response => {
                console.info("Learnpage.requestNextRelationPage -- number of concepts loaded",response.length);
                setRelations(relations.concat(response))
                setLoading(false)
            })
            .catch(err => {
                console.error(err);
            })
          }

    }

    const requestNewRelationPage = () => {
        var amount = props.amount ? props.amount : 15;
        console.info("LearnPage.requestNewRelationPage -- query", textSearch);


        if(userState && authReady) {

            setLoading(true)

            refreshUser()
    
            fetch('/.netlify/functions/load-relations', 
              {
                  method: 'POST',
                  headers: userState && {
                  Authorization: 'Bearer ' + userState.token
                  },              
                  body: JSON.stringify({
                    page: 0,
                    amount: amount,
                    query: textSearch,
                    sortMode: ORDER_BY_CREATIONDATE_DESC,
                    filterMode: filterMode,
                    onlyMasters: true
                  })
              })
            .then(response => {
                if(!response.ok) {
                    throw Error('Learnpage.requestNextRelationPage -- response NOK',response.errorMessage)
                }
      
                return response.json()
            })
            .then(response => {
                console.info("Learnpage.requestNextRelationPage -- number of concepts loaded",response.length)

                let _relations = [].concat(relations)
                response.forEach(_r => {
                    if(!_relations.find(r => r.id === _r.id)) {
                        _relations.push(_r)
                    }
                })
                setRelations(_relations)
                setLoading(false)
            })
            .catch(err => {
                console.error(err);
            })
          }

    }

    const requestNewConceptPage = () => {
        var amount = props.amount ? props.amount : 15;
        console.info("LearnPage.requestNewConceptPage -- query", textSearch);


        if(userState && authReady) {

            setLoading(true)

            refreshUser()
    
            fetch('/.netlify/functions/load-concepts', 
              {
                  method: 'POST',
                  headers: userState && {
                  Authorization: 'Bearer ' + userState.token
                  },              
                  body: JSON.stringify({
                    page: 0,
                    amount: amount,
                    query: textSearch,
                    sortMode: ORDER_BY_CREATIONDATE_DESC,
                    filterMode: filterMode
                  })
              })
            .then(response => {
                if(!response.ok) {
                    throw Error('Learnpage.requestNewConceptPage -- response NOK',response.errorMessage)
                }
      
                return response.json()
            })
            .then(response => {
                console.info("Learnpage.requestNewConceptPage -- number of concepts loaded",response.length)

                let _concepts = [].concat(concepts)
                response.forEach(r => {
                    if(!_concepts.find(_c => _c.dbid === r.dbid)) {
                        _concepts.push(r)
                    }
                })
                setConcepts(_concepts)
                setLoading(false)
            })
            .catch(err => {
                console.error(err);
            })
          }

    }
 
    useEffect(() => {
        console.log("LearnPage.useEffect -- user:", userState)

        let headers
        if(authReady) {

            if(userState) {
                refreshUser()

                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }

            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: "learn"
                })
            })
           
        }

    }, [authReady, userState])


    useEffect(() => {
     
        if(userState && authReady){
            mode === "concepts" ? requestNewConceptPage() : requestNewRelationPage()
        }
    }, [authReady, userState, filterMode, textSearch, mode])
        
    


        var conceptsList = concepts.filter(c => c.label.toLowerCase().includes(textSearch.toLowerCase()) || (c.synonyms && c.synonyms.toLowerCase().includes(textSearch.toLowerCase()))).map(e => 
            <InfoPane 
            {...props} 
            key={"c"+e.dbid}
            element={e} showLinks={false} showExploreButton={true} showDiscussButton={true} className="infoLearnPane"/>
            );

        var relationsList = relations.filter(r => r.sourceLabel.toLowerCase().includes(textSearch.toLowerCase()) || r.targetLabel.toLowerCase().includes(textSearch.toLowerCase())).map(e => 
            <InfoPane 
            {...props} 
            key={"r"+e.dbid}
            element={e} showLinks={false} showExploreButton={true} showDiscussButton={true} className="infoLearnPane" />
            );

        console.log("Learnpage -- roles: ", !userState ? null : userState.roles)

        return (
            <div className={"standardbodywrapper " + props.screenMode}>
                
                <div className={"learnPageWrapper " + props.screenMode}>
                    <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titlelearn">
                        {props.language === "en" && "LEARN"}
                        {props.language === "es" && "APRENDER"}
                    </div>

                    <div className="learnPage">
                   
                        {!authReady && <p><br/><br/>...</p>}
                        {authReady && !userState && 
                        <>
                            <p><br/><br/>You must be logged in<br/><br/></p>
                            <p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link></p>
                        </>}

                        {authReady && userState && !userState.hasPro && 
                        <>
                        {!userState.stripeID && 
                                <p>You have no subscription at this moment. Sign up for a free trial to experience Ninatoka.</p>
                            }
                            {userState.stripeID && 
                                <p>You have no subscription at this moment.</p>
                            }                                                        
                        <stripe-pricing-table pricing-table-id={userState.pricingTable.pricingTableID}
                                publishable-key={userState.pricingTable.pricingTableKey}
                                customer-email={userState.email}
                                client-reference-id={userState.dbid}>
                            </stripe-pricing-table>  
                        </>}

                        {authReady && userState && userState.hasPro && 
                        <>
                        <div>
                            <form className="learnFilters">
                                <div>
                                    <label>
                                        {props.language === "en" && "Filter on versions"}
                                        {props.language === "es" && "Filtrar por versiones"}
                                    </label>
                                    <div><input type="radio" name="filter" value={FILTER_VERIFIED} onChange={(e) => {
                                        setFilterMode(FILTER_VERIFIED)
                                    }} checked={filterMode === FILTER_VERIFIED}/>
                                        
                                    <span>&#160;
                                        {props.language === "en" && "Only active versions"}    
                                        {props.language === "es" && "Solo versiones activas"}
                                    </span></div>

                                    <div><input type="radio" name="filter" value={FILTER_ALL} onChange={(e) => {
                                    setFilterMode(FILTER_ALL)
                                        }} checked={filterMode === FILTER_ALL} />

                                    <span>&#160;
                                        {props.language === "en" && "All versions"}    
                                        {props.language === "es" && "Todas las versiones"}
                                    </span></div>
                                    </div>
                                <div>
                                    <label>
                                        {props.language === "en" && "Relations or Concepts"}
                                        {props.language === "es" && "Relaciones o Conceptos"}
                                    </label>
                                    <div><input type="radio" name="mode" value="relations" onChange={(e) => {
                                        setMode("relations")}} checked={mode === "relations"}/>
                                        
                                    <span>&#160;
                                        {props.language == "en" && "Relations"}
                                        {props.language === "es" && "Relaciones"}
                                    </span></div>

                                    <div><input type="radio" name="mode" value={"concepts"} onChange={(e) => {
                                    setMode("concepts")
                                        }} checked={mode === "concepts"} />

                                    <span>&#160;
                                        {props.language === "en" && "Concepts"}
                                        {props.language === "es" && "Conceptos"}
                                    </span></div>
                                </div>
                                <div>
                                
                                    <label>
                                        {props.language === "en" && "Text filter"}
                                        {props.language === "es" && "Filtro de texto"}
                                    </label><br />
                                    <input placeholder="Filter" className="mine shorttextfield" type="text" id="searchText" name="searchText" value={textSearch} onChange={(e) => {setTextSearch(e.target.value)}}/><br/>
                                </div>

                            </form>
                        </div>

                        <div className={props.screenMode === "mobile" ? "learnRelations mobile" : "learnRelations"}>
                            {mode === "concepts" && conceptsList}
                            {mode === "relations" && relationsList}
                            {loading && props.language === "en" && <p>Loading...</p>}
                            {loading && props.language === "es" && <p>Cargando...</p>}
                        </div>
                        <div className="toolbox learnToolbar">
                            {props.language === "en" && <button title="Load more" onClick={mode === "concepts" ? requestNextConceptPage : requestNextRelationPage}><span className="glyphicon glyphicon-menu-right right"/></button>}
                            {props.language === "es" && <button title="Cargar más" onClick={mode === "concepts" ? requestNextConceptPage : requestNextRelationPage}><span className="glyphicon glyphicon-menu-right right"/></button>}
                        </div>
                        </>}
                    </div>
                </div>

               
               
            </div>
        );
    
}

export default LearnPage;