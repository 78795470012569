import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from '../stores/authContext'
import { Link } from "react-router-dom"; 



/**
 * 
 * @param {id} props 
 * @returns 
 */
function Signup (props) {
    let navigate = useNavigate()

    console.info("RelevantElementsPane render triggered");

    const {userState, authReady, signupUser} = useContext(AuthContext)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [privacy, setPrivacy] = useState(false)
    const [terms, setTerms] = useState(false)
    const [feedback, setFeedback] = useState("")
    const [language, setLanguage] = useState(props.language);

    
    const handleSignup = (e) => {
        e.preventDefault()
        if(!name || !email || !password) {
            setFeedback("Please fill the required fields.")
        }
        else {
            signupUser(name, email, password, language, (result) => {
                if(result === "Success") {
                    setFeedback("You should have received a confirmation email. Click the link in the email to complete your signup. Then choose your subscription to start exploring Ninatoka with a free trial period.")
                }
                else {
                    setFeedback("Failed to sign up. This user might already exist.")
                }
            }
            )
           
            
        }
       
    }

 
    useEffect(() => {
        console.log("ManageRolesPage.useEffect -- user:", userState)

        if(authReady) {

            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                body: JSON.stringify({
                    visitedPage: "signup",
                    comment: ""
                })
            })
            

        }
       
    }, [authReady, userState])
        





    return (
        

            <div className="box">
                
                <br/>

                
                <form onSubmit={handleSignup}>
                    <br/>
                    <p>
                    <label>
                    {props.language === "en" && "Your name *"}
                    {props.language === "es" && "Tu nombre *"}
                    </label>
                    <input required type="text" className="mine" value={name} onChange={(e) => setName(e.target.value)} />
                    </p>
                    <p>
                    <label>
                    {props.language === "en" && "Email *"}
                    {props.language === "es" && "Correo electrónico *"}
                    </label>
                    <input required type="text" className="mine" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </p>
                    <p>
                    <label>
                    {props.language === "en" && "Password *"}
                    {props.language === "es" && "Contraseña *"}
                    </label>
                    <input required type="password" className="mine" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </p>
                    <p>
                    <label>
                    {props.language === "en" && "Preferred communication language *"}
                    {props.language === "es" && "Idioma de comunicación preferido *"}
                    </label>
                    <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                        <option value="nl">{props.language === "en" && "Dutch"}{props.language === "es" && "Neerlandés"}</option>
                        <option value="en">{props.language === "en" && "English"}{props.language === "es" && "Inglés"}</option>
                        <option value="es">{props.language === "en" && "Spanish"}{props.language === "es" && "Español"}</option>
                    </select>
                    </p>
                    <br/>
                    <p>
                        
                        <input required type="checkbox" className="mine" value={privacy} onChange=
                        {(e) => 
                            {
                                setPrivacy(e.target.checked)
                                }
                         } />
                         &nbsp; 
                            {props.language === "en" && <>I have read and agree to the <a href="20230507NinatokaPrivacyPolicy.pdf">privacy policy</a></>}
                            {props.language === "es" && <>He leído y acepto <a href="20230507NinatokaPrivacyPolicy.pdf">la política de privacidad</a>.</>}
                         
                         <br/>                      
                        <input required type="checkbox" className="mine" value={terms} onChange=
                        {(e) => 
                            {
                                setTerms(e.target.checked)
                                }
                         } />
                         &nbsp; 
                         {props.language === "en" && <>I have read and agree to the <a href="20230507NinatokaTermsAndConditions.pdf">terms and conditions</a></>}
                         {props.language === "es" && <>He leído y acepto <a href="20230507NinatokaTermsAndConditions.pdf">los términos y condiciones</a></>}
                         
                    </p>
                    <p>
                        <br/>{feedback}
                    </p>
                    
                    <p>
                    <button disabled={!(privacy && terms && name && password && email)} onClick={handleSignup}>{props.language === "en" && "Sign up"}{props.language === "es" && "Registrarse"}</button>
                    </p>
                    
                    {props.language === "en" &&
                    <p>
                        Already a member? <Link to="/login">Log in</Link> instead.
                    </p>
                    }
                    {props.language === "es" &&
                    <p>
                        ¿Ya eres miembro? <Link to="/login">Inicia sesión</Link> en su lugar.
                    </p>
                    }

                </form>
                
            </div>

        
    );    

}

export default Signup;