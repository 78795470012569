import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from '../stores/authContext'
import { Link } from "react-router-dom"; 



/**
 * 
 * @param {id} props 
 * @returns 
 */
function Login (props) {
    let navigate = useNavigate()

    console.info("RelevantElementsPane render triggered");

    const {authReady, userState, loginUser, forgotPassword} = useContext(AuthContext)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [feedback, setFeedback] = useState("")

    const handleLogin = (e) => {
        e.preventDefault()

        props.language === "en" && setFeedback("Logging you in...");
        props.language === "es" && setFeedback("Iniciando sesión...");
        loginUser(email, password, (userState, error) => {
            if(userState && userState.user) {
                props.language === "en" && setFeedback("Login successful.");
                props.language === "es" && setFeedback("Inicio de sesión exitoso.")
                if(props.navigate) {
                    console.log("Login.handleLogin -- navigating to " + props.navigate);
                    navigate(props.navigate);
                }
                else {
                    navigate(-1);
                }
            }
            else{
                console.error("Login.handleLogin -- error", error)
                props.language === "en" && setFeedback("Login failed. Is your username and password correct?");
                props.language === "es" && setFeedback("Inicio de sesión fallido. ¿Es correcto tu nombre de usuario y contraseña?");
            }
        })
    }
    
    const handleForgotPassword = (e) => {
        e.preventDefault()

        if(!email) {
            props.language === "en" && setFeedback("Please enter your email for password reset.");
            props.language === "es" && setFeedback("Por favor, ingresa tu correo electrónico para restablecer la contraseña.");
        }

        else {
            forgotPassword(email, () => {
                props.language === "en" && setFeedback("You should have received a password recovery email. Click the link in the email to update your password.");
                props.language === "es" && setFeedback("Deberías haber recibido un correo electrónico de recuperación de contraseña. Haz clic en el enlace del correo electrónico para actualizar tu contraseña.");
            })
        }
       
    }

     
    useEffect(() => {
        console.log("ManageRolesPage.useEfferole -- user:", userState)

        if(authReady) {

            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                body: JSON.stringify({
                    visitedPage: "login",
                    comment: ""
                })
            })
            

        }
       
    }, [authReady, userState])
        

    return (
           

                <div className="box">
                    <form onSubmit={handleLogin}>
                        <br/>
                        <p>
                        <label>                    
                            {props.language === "en" && "Email *"}
                            {props.language === "es" && "Correo electrónico *"}
                        </label>
                        <input required type="text" className="mine" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </p>
                        <p>
                        <label>
                            {props.language === "en" && "Password *"}
                            {props.language === "es" && "Contraseña *"}
                        </label>
                        <input type="password" className="mine" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </p>

                        <div>
                            <button onClick={handleLogin}>
                                {props.language === "en" && "LOG IN"}
                                {props.language === "es" && "INICIAR SESIÓN"}
                            </button> 
                        </div>
                        
                        {feedback && 
                            <>
                                <br/>
                                <p>{feedback}</p>
                            </> }
                        

                        <br/>
                        <p>
                            {props.language === "en" && <>Not a member yet? <Link to="/signup"> Sign up</Link> instead.</>}
                            {props.language === "es" && <>¿Aún no eres miembro? <Link to="/signup"> Regístrate</Link> en su lugar.</>}                   
                        </p>
                        
                        <p>
                            {props.language === "en" && <>Forgot your password? <a href="#" onClick={handleForgotPassword}>Get a password recovery email.</a></>}
                            {props.language === "es" && <>¿Olvidaste tu contraseña? <a href="#" onClick={handleForgotPassword}>Recibe un correo electrónico de recuperación de contraseña.</a></>}
                        
                        </p>
    
                    
                    </form>
                </div>
            

        
    );    

}

export default Login;