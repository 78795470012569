import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../stores/authContext'
import {useParams} from "react-router-dom";
import { ORDER_BY_LABEL_LENGTH } from '../utils/constants';
import { Link } from "react-router-dom"; 
import Searcher from '../components/Searcher';


function ManageRolesPage (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)

    const [roles, setRoles] = useState([])

    const [newRole, setNewRole] = useState({dbid: -1})

   

    useEffect(() => {
        console.log("ManageRolesPage.useEfferole -- user:", userState)

        let headers

        if(authReady) {
            if(userState) {
                refreshUser()
    
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }
    
            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: "manageroles"
                })
            })


            
            fetch('/.netlify/functions/load-roles', 
            {
                method: 'POST',
                headers: userState && {
                Authorization: 'Bearer ' + userState.token
                },              
                body: JSON.stringify({
                })
            })
            .then(response => {
                if(!response.ok) {
                    throw Error('You must be logged in as an admin to view this content')
                }
        
                console.log("returning response promise")
                return response.json()
            })
            .then(response => {
                response.sort((a,b) => a.dbid - b.dbid)
                setRoles(response)
                
            })
            .catch(err => {
                console.error(err);
            })

        }
       
    }, [authReady, userState])
        


    const onChange = (role) => {
        var newroles = roles.filter(r => r.dbid !== role.dbid)
        console.log("ManageRolesPage.onChange -- new roles without changed one",newroles)

        newroles.push({
            ...role,
            changed: true,
            saveFeedback: ""
        })
        newroles.sort((a,b) => a.dbid - b.dbid)
        console.log("ManageRolesPage.onChange -- new roles list after sort",newroles)
        setRoles(newroles)
    }

    const saveChanges = (dbid) => {
        var updatedRole = roles.find(role => role.dbid === dbid)

        var headers
        if(userState) {
            refreshUser()

             headers = {
                Authorization: 'Bearer ' + userState.token
            }
        }
            
        
        if(userState && dbid !== -1 && !updatedRole.replacewith) {
            
            fetch('/.netlify/functions/update_role', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    ...updatedRole,
                })
            })
            .then(response => {
                if(!response.ok) {
                    throw Error("Error saving: " + response.errorMessage)
                }

                return response.json()

            })
            .then(response => {
                console.log("ManageRolesPage.saveChanges -- update-concepttype response", response)
                var updatedroles = roles.filter(role => role.dbid !== dbid)
                updatedroles.push({
                    ...updatedRole,
                    changed: false,
                    saveFeedback: "Successfully updated"
                })
                updatedroles.sort((a,b) => a.dbid - b.dbid)
                setRoles(updatedroles)
            })
            .catch(err => {
                console.error(err)
                
            })
        }
       
        
        if(userState && dbid !== -1 && updatedRole.replacewith) {
            
            fetch('/.netlify/functions/replace_role', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    dbid: updatedRole.dbid,
                    newdbid: updatedRole.replacewith
                })
            })
            .then(response => {
                if(!response.ok) {
                    throw Error("Error saving: " + response.errorMessage)
                }

                return response.json()

            })
            .then(response => {
                console.log("ManageRolesPage.saveChanges -- update-concepttype response", response)
                var updatedroles = roles.filter(role => role.dbid !== dbid)
                updatedroles.push({
                    ...updatedRole,
                    changed: false,
                    saveFeedback: "Successfully updated"
                })
                updatedroles.sort((a,b) => a.dbid - b.dbid)
                setRoles(updatedroles)
            })
            .catch(err => {
                console.error(err)
                
            })
        }

        if(userState && newRole.label && dbid === -1) {
               
            fetch('/.netlify/functions/create-role', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    ...newRole,
                })
            })
            .then(response => {
                if(!response.ok) {
                    throw Error("Error saving: " + response.errorMessage)
                }

                return response.json()

            })
            .then(response => {
                console.log("ManageRolesPage.saveChanges -- create-role response", response)
                var updatedroles = roles.filter(role => role.dbid !== dbid)
                newRole.saveFeedback = "Successfully created"
                newRole.dbid = response.dbid
                newRole.changed = false
                updatedroles.push(newRole)
                updatedroles.sort((a,b) => a.dbid - b.dbid)
                setRoles(updatedroles)
                setNewRole({})
            })
            .catch(err => {
                console.error(err)
                newRole.saveFeedback = "Creation failed"
            })
        }
    }

    const deleteRole = (dbid) => {
        var headers
        if(userState) {
            refreshUser()

             headers = {
                Authorization: 'Bearer ' + userState.token
            }

            if(userState && dbid !== -1) {
            
                fetch('/.netlify/functions/delete-role', {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        dbid: dbid
                    })
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error("Error deleting: " + response.errorMessage)
                    }
    
                    return response.json()
    
                })
                .then(response => {
                    console.log("ManageRolesPage.saveChanges -- update-concepttype response", response)
                    var updatedroles = roles.filter(role => role.dbid !== dbid)
                    
                    setRoles(updatedroles)
                })
                .catch(err => {
                    console.error(err)
                    
                })
            }
        }
    }

    console.log("ManageRolesPage -- rebuilding rows", roles)
    
    var tableRows = roles.map(role => 
        <tr key={role.dbid}>
            <td  className="columnWidth"><input disabled={true} required type="text" className="mine" value={role.dbid}/></td>
            <td  className="columnWidth"><input disabled={false} required type="text" onChange={(e) => onChange(
                {
                    ...role,
                    label: e.target.value
                })} className="mine" placeholder="Name your role" value={role.label}/></td>
            <td  className="columnWidth"><input disabled={false} required type="text" onChange={(e) => onChange(
                {
                    ...role,
                    reciprocallabel: e.target.value
                })} className="mine" placeholder="Name the reciprocal role" value={role.reciprocallabel}/></td>
            <td  className="columnWidth"><input disabled={false} required type="text" onChange={(e) => onChange(
                {
                    ...role,
                    colour: e.target.value
                })} className="mine" placeholder="Set the arrow colour" value={role.colour}/></td>
            <td  className="columnWidth"><input disabled={false} required type="text" onChange={(e) => onChange(
                {
                    ...role,
                    order: e.target.value
                })} className="mine" placeholder="Set the order" value={role.order}/></td>
            <td  className="columnWidth"><input disabled={false} required type="text" onChange={(e) => onChange(
                {
                    ...role,
                    replacewith: e.target.value
                })} className="mine" placeholder="Replace with" value={role.replacewith}/></td>

            <td  className="columnWidth">{role.changed === true ? (console.log("changed " + role.dbid + role.changed), "x") : ""}{role.saveFeedback}</td>
            
            <td><button disabled={!role.changed} onClick={(e) => saveChanges(role.dbid)}>Save</button></td>
            <td><button onClick={(e) => deleteRole(role.dbid)}>Delete</button></td>

        </tr>
    )
    tableRows.push(
        <tr key={newRole.dbid}>
            <td  className="columnWidth"><input disabled={true} required type="text" className="mine" value={newRole.dbid}/></td>

            <td  className="columnWidth"><input disabled={false} required type="text" onChange={(e) => {
                console.log("ManageRolesPage -- change new role", e)
                setNewRole(
                    {
                        ...newRole,
                        name: e.target.value,
                        changed: true
                    })
            } } className="mine" placeholder="Name your role" value={newRole.label}/></td>
            <td  className="columnWidth"><input disabled={false} required type="text" onChange={(e) => {
                console.log("ManageRolesPage -- change new role", e)
                setNewRole(
                    {
                        ...newRole,
                        reciprocallabel: e.target.value, 
                        changed: true
                    })
            } } className="mine" placeholder="Set the reciprocal role name" value={newRole.label}/></td>
            <td  className="columnWidth"><input disabled={false} required type="text" onChange={(e) => {
                console.log("ManageRolesPage -- change new role", e)
                setNewRole(
                    {
                        ...newRole,
                        colour: e.target.value, 
                        changed: true
                    })
            } } className="mine" placeholder="Set the arrow colour" value={newRole.colour}/></td>
            <td  className="columnWidth"><input disabled={false} required type="text" onChange={(e) => {
                console.log("ManageRolesPage -- change new role", e)
                setNewRole(
                    {
                        ...newRole,
                        order: e.target.value, 
                        changed: true
                    })
            } } className="mine" placeholder="Set the order" value={newRole.order}/></td>
            <td  className="columnWidth"><input disabled={true} required type="text" className="mine" placeholder="Replace with" value={newRole.replacewith}/></td>

            <td  className="columnWidth">{newRole.changed === true ? "x" : ""}{newRole.saveFeedback}</td>
            <td><button disabled={!newRole.changed} onClick={(e) => saveChanges(newRole.dbid)}>Save</button></td>
            <td/>
         </tr>
    )

    return (

        <div className={"standardbodywrapper " + props.screenMode}>            
            <div className={"simplePage " + props.screenMode}>
                <div className={"title " + (props.platform ? props.platform.platform_raw : "")}>MANAGE ROLES</div>

                <div className="simplePageChildren">
                    {!authReady && <p>...</p>}
                    {authReady && userState && userState.userRoles && !userState.userRoles.find(r => r === "admin") 
                        &&  <><br/><p><Link to="/login">Log in</Link> with an administrator account.</p></>}
                    {authReady && !userState 
                    &&  <><br/><p><Link to="/login">Log in</Link> with an administrator account.</p></>}

                    {authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && 
                        <>
                            <br/><br/>
                            <div >
                                <table className="padding-table-columns">
                                    <tbody className="padding-table-columns">

                                        <tr>
                                            <th className="columnWidth">Role id</th>

                                            <th className="columnWidth">Role name</th>
                                            <th className="columnWidth">Reciprocal role name</th>
                                            <th className="columnWidth">Colour</th>
                                            <th className="columnWidth">Order</th>
                                            <th className="columnWidth">Replace with</th>

                                        </tr>
                                        {tableRows}
                                    </tbody>
                                </table>
                            </div>

                        </>
                    }
                </div>
            </div>
        </div>
    )
}


  
export default ManageRolesPage;