import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from '../stores/authContext'
import { Link } from "react-router-dom"; 



/**
 * 
 * @param {id} props 
 * @returns 
 */
function AcceptInvite (props) {
    console.info("AcceptInvite triggered")

    let navigate = useNavigate()


    const {acceptInvite} = useContext(AuthContext)

    const [password, setPassword] = useState("")
    const [feedback, setFeedback] = useState("")
    const [token, setToken] = useState("")
    const [name, setName] = useState("")


    const handleAcceptInvite = (e) => {
        e.preventDefault()

        setFeedback("Signing you up...")

        if(!password) {
            setFeedback("Please provide a new password.")
        }

        else {
            acceptInvite(token, name, password, (result) => {
                if(result === "Success") {
                    setFeedback("Successfully registered. Enjoy your stay.")
                    navigate("/")
                }
                else {
                    setFeedback("Failed to register. Contact admin.")
                }
               
            })
        }
    }

    useEffect(() => {
        const token_invite = window.location.hash.split('invite_token=')[1]
        console.log("AcceptInvite.useEffect -- token_invite",token_invite)
        setToken(token_invite)
    }, [])

    return (
        <>
            <div className={"title " + (props.platform ? props.platform.platform_raw : "")}>ACCEPT INVITE</div>
            <div className="box">
                <form onSubmit={handleAcceptInvite}>
                    <br/>
                    <p>
                        <label>Your name *</label>
                        <input required type="text" className="mine" value={name} onChange={(e) => setName(e.target.value)} />
                    </p>
                    <p>
                        <label>Password *</label>
                        <input required type="password" className="mine" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </p>

                    <p>
                        <button onClick={handleAcceptInvite} disabled={!token || !password || !name}>Accept invite</button>
                    </p>

                    <p>
                        {feedback}
                    </p>
                </form>
            </div>
        </>
        
    );    

}

export default AcceptInvite;