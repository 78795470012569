import React, { useEffect, useContext, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";


import Signup from '../components/Signup'

function SignupPage (props) {

    return (
        
        <div className={"standardbodywrapper " + props.screenMode}>
            <div className={"simplePage " + props.screenMode}>
                <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titlelogin">
                    {props.language === "en" && "REGISTER"}{props.language === "es" && "REGISTRARSE"}

                </div>
            <Signup {...props}/>
            </div>
        </div>
    )
}

export default SignupPage;