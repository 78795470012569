import React from 'react';
import { useNavigate } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';


  

/**
 * Expects props: element, onSelect function
 */
function Comment(props) {

        let navigate = useNavigate();

        console.info("Comment -- props",props);

        const time = new Date(props.comment.time).toLocaleDateString(undefined, { day: 'numeric', year: 'numeric', month: 'short'}) + " " + new Date(props.comment.time).toLocaleTimeString(undefined, {hour: '2-digit', minute:'2-digit'})

        let className = "box newlines" + (props.className ? " " + props.className : "")

        return (
            <div className={className}>
                <br/>
                <b>{props.comment.member}</b> - <i>{props.comment.profile} - {time}</i><br/><br/>

                {props.comment.comment}
                <br/><br/>
                <div>
                    
                </div>
                
            </div>
        );
    
}

export default Comment;