import AsyncSelect, { useAsync } from 'react-select/async';
import AuthContext from '../stores/authContext'
import React, { useState, useEffect, useContext } from 'react';

const ORDER_BY_LABEL_LENGTH = "ORDER_BY_LABEL_LENGTH"

function Searcher (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)
    const [value, setValue] = useState(props.value)

    console.info("value in props in constructor searcher", props.value);

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const loadConceptOptions = (inputValue) => {
 
        console.log("Searcher.loadConceptOptions -- userState",userState)
        console.log("Searcher.loadConceptOptions -- requiresInput", props.requiresInput)
        console.log("Searcher.loadConceptOptions -- inputValue", inputValue)


        if(props.requiresInput && !inputValue) {
            console.log("Searcher.loadConceptOptions -- retuning promise with empty array")
            return new Promise((resolve, reject) => {
                resolve([])
            })
        }

        else if(userState && authReady) {

            refreshUser()
            return fetch('/.netlify/functions/load-concepts', 
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                      
                    body: JSON.stringify({
                        amount: 10,
                        query: inputValue,
                        sortMode: ORDER_BY_LABEL_LENGTH,
                        noDetails: true
                    })
                })
            .then(response => {
                if(!response.ok) {
                    throw Error('You must be logged in to view this content')
                }
        
                console.log("returning response promise")
                return response.json()
            })
            .catch(err => {
                console.error(err);
            })
            
        }
            
        else {
            console.error("Searcher -- no user known so not requesting the data")
        }

    }

    const loadRelationOptions = (inputValue) => {

        
        if(props.requiresInput && !inputValue) {
            console.log("Searcher.loadRelationOptions -- retuning promise with empty array")
            return new Promise((resolve, reject) => {
                resolve([])
            })
        }
        else if(userState && authReady) {

            refreshUser()
    
            return fetch('/.netlify/functions/load-relations', 
              {
                  method: 'POST',
                  headers: userState && {
                  Authorization: 'Bearer ' + userState.token
                  },              
                  body: JSON.stringify({
                      amount: 10,
                      query: inputValue
                  })
              })
            .then(response => {
                if(!response.ok) {
                    throw Error('You must be logged in to view this content')
                }
      
                console.log("returning response promise")
                return response.json()
            })
            .catch(err => {
                console.error(err);
            })
          }
      
          else {
            console.error("Searcher -- no user known so not requesting the data")
        }
    }

    const loadRolesOptions = (inputValue) => {

        
        if(props.requiresInput && !inputValue) {
            console.log("Searcher.loadRelationOptions -- retuning promise with empty array")
            return new Promise((resolve, reject) => {
                resolve([])
            })
        }
        else if (userState && authReady) {

            refreshUser()
            
            return fetch('/.netlify/functions/load-roles', 
              {
                  method: 'POST',
                  headers: userState && {
                  Authorization: 'Bearer ' + userState.token
                  },              
                  body: JSON.stringify({
                      amount: 10,
                      query: inputValue,
                  })
              })
            .then(response => {
                if(!response.ok) {
                    throw Error('You must be logged in to view this content')
                }
      
                console.log("returning response promise")
                return response.json()
            })
            .catch(err => {
                console.error(err);
            })
          }
      
          else {
            console.error("Searcher -- no user known so not requesting the data")
        }
    }



    const loadConceptTypeOptions = (inputValue) => {
        if(props.requiresInput && !inputValue) {
            console.log("Searcher.loadRelationOptions -- retuning promise with empty array")
            return new Promise((resolve, reject) => {
                resolve([])
            })
        }
        else if (userState && authReady) {

            refreshUser()
            
            return fetch('/.netlify/functions/load-concepttypes', 
              {
                  method: 'POST',
                  headers: userState && {
                  Authorization: 'Bearer ' + userState.token
                  },              
                  body: JSON.stringify({
                      amount: 10,
                      query: inputValue,
                  })
              })
            .then(response => {
                if(!response.ok) {
                    throw Error('You must be logged in to view this content')
                }
      
                console.log("returning response promise")
                return response.json()
            })
            .catch(err => {
                console.error(err);
            })
          }
      
          else {
            console.error("Searcher -- no user known so not requesting the data")
        }
    }
    


    const loadConceptTypeShapeOptions = (inputValue) => {
        if(props.requiresInput && !inputValue) {
            console.log("Searcher.loadConceptTypeShapeOptions -- retuning promise with empty array")
            return new Promise((resolve, reject) => {
                resolve([])
            })
        }
        else if (userState && authReady) {

            refreshUser()
            
            return fetch('/.netlify/functions/load-concepttypeshapes', 
              {
                  method: 'POST',
                  headers: userState && {
                  Authorization: 'Bearer ' + userState.token
                  },              
                  body: JSON.stringify({
                      amount: 30,
                      query: inputValue,
                  })
              })
            .then(response => {
                if(!response.ok) {
                    throw Error('You must be logged in to view this content')
                }
      
                console.log("returning response promise -- ", response)
                return response.json()
            })
            .catch(err => {
                console.error(err);
            })
          }
      
          else {
            console.error("Searcher -- no user known so not requesting the data")
        }
    }



    
    const loadArray = (inputValue) => {       
        if(props.requiresInput && !inputValue) {
            console.log("Searcher.loadRelationOptions -- retuning promise with empty array")
            return new Promise((resolve, reject) => {
                resolve([])
            })
        }
        else {
            var filteredArray = props.array.filter(a => a.label.toLowerCase().includes(inputValue ? inputValue.toLowerCase() : ""))
            console.log("Searcher.loadArray -- inputValue", inputValue)
            console.log("Searcher.loadArray -- array", props.array)
            console.log("Searcher.loadArray -- filtered array", filteredArray)
            return new Promise((resolve, reject) => resolve(filteredArray))    
        }
    }
    
    console.info("searcher render value: " + value);
    
    const style = {
    control: (base, state) => ({
        ...base,
        borderColor: "lightgray",
        // This line disable the blue border
        boxShadow: 'none',
        margin: '6px 0px 10px 0px',
        border: state.isFocused || state.isActive || state.isHovered || state.isSelected ? '1px solid #A0CCD4' : "",
        backgroundColor: state.isDisabled ? '#A0CCD4' : ""
    }),
    option: (base, state) => (
        {
        ...base,
        color: state.isSelected || state.isActive || state.isHovered || state.isDisabled ? "black" : "black"

    })
    };
    

    return (

    <>
        {authReady && !userState && <p>You seem to be logged out</p>}
        {authReady && userState && 
        <AsyncSelect
            cacheOptions
            defaultOptions
            value={value}
            getOptionLabel={e => (e.label)}
            getOptionValue={e => e.dbid}
            loadOptions={!props.mode || 
                props.mode === "concept" ? loadConceptOptions : (
                    props.mode === "relation" ? loadRelationOptions : (
                        props.mode === "role" ? loadRolesOptions : (
                            props.mode === "concepttype" ? loadConceptTypeOptions : (
                                props.mode === "conceptshape" ? loadConceptTypeShapeOptions : 
                                    loadArray))))}
            onChange={e => {props.callback(e)}}
            isDisabled={(props.isDisabled ? props.isDisabled : false)}
            styles={style}
            theme={(theme) => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary25: '#A0CCD4',
                primary50: '#A0CCD4',
                primary75: '#A0CCD4',
                primary: '#88A36C',
            },
            })}
        /> }
    </>
    );
          
}

export default Searcher;