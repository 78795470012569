import { Link } from "react-router-dom"; 
import AuthContext from '../stores/authContext'
import React, { useState, useEffect, useContext } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { useNavigate } from "react-router-dom";



/**
 * 
 * @param {*} props 
 * @returns 
 */
function StatsVisitorsPerWeek (props) {
    let navigate = useNavigate()

    console.info("StatsVisitorsPerWeek render triggered");

    const {userState, authReady, refreshUser, recoverUser, acceptInvite, confirmUser} = useContext(AuthContext)
    const [data, setData] = useState({labels: [], datasets: []})
    
    useEffect(() => {

        let headers
        if(userState) {
            refreshUser()

            headers = {
                Authorization: 'Bearer ' + userState.token
            }
        }

        fetch('/.netlify/functions/load-stats-anonymous-visitors-per-week', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({})
        })
        .then(response => {
            return response.json()
        })
        .then(response => {
            console.log("StatsVisitorsPerWeek.useeffect -- response",response)
            let _labels = []
            let _membersData = {
                label: "Members",
                data: [],
                backgroundColor: '#88A36C',
            }

            //let cumulativeTotal = 0
            response.perWeek.forEach(m => {
                _labels.push(m.year + "-" + m.week)
                let visitorsAmount = m.visitorsAmount ? m.visitorsAmount : 0
                //cumulativeTotal += visitorsAmount
                _membersData.data.push(visitorsAmount)
            })

            console.log("StatsVisitorsPerWeek -- _membersData",_membersData)

            setData({labels: _labels, datasets: [_membersData]})
        })
        .catch(error => {
            console.error(error)
        })

    }, [])
   
    ChartJS.register(
        CategoryScale,
        BarElement,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );

      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            //display: true,
            //text: 'How the knowledge of NinaToka grows...',
          },
        },
      };
    
      console.log("StatsAnonymousVisitorsPerWeek -- screenMode", props.screenMode)

    return (
        <>
                 
                {authReady && userState && 
            <>
                    <div className={"chartMaxWidth " + props.screenMode === "mobile" ? "mobile" : "desktop"}>
                        <h2>Number of anonymous visits per week</h2>
                        <Bar options={options} data={data} width="400px" height="400px"/>
                    </div>
                
            </>
            }
        </>
        
    );    

}

export default StatsVisitorsPerWeek;