import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../stores/authContext'
import {useParams} from "react-router-dom";
import { ORDER_BY_LABEL_LENGTH } from '../utils/constants';
import { Link } from "react-router-dom"; 


function AddRolePage (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)
    let { id } = useParams();

    const [roleName, setRoleName] = useState("")
    const [reciprocalRoleName, setReciprocalRoleName] = useState("")
    const [saveFeedback, setSaveFeedback] = useState("")
    const [existingRoles, setExistingRoles] = useState([])
    const [existingReciprocalRoles, setExistingReciprocalRoles] = useState([])


    useEffect(() => {
        console.log("AddRolePage.useEffect -- user:", userState)

        let headers

        if(authReady) {
            if(userState) {
                refreshUser()
    
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }
    
            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: "addrole"
                })
            })
        }
       
    }, [authReady, userState])
        

    const onRoleNameChange = (event) => {
        setRoleName(event.target.value);
        setSaveFeedback("");
        if(event.target.value) {
            if(userState && authReady && userState.userRoles && userState.userRoles.find(r => r === "admin")) {

                refreshUser()
    
                fetch('/.netlify/functions/load-roles', 
                  {
                      method: 'POST',
                      headers: userState && {
                      Authorization: 'Bearer ' + userState.token
                      },              
                      body: JSON.stringify({
                          query: event.target.value,
                          amount: 20,
                          sortMode: ORDER_BY_LABEL_LENGTH
                      })
                  })
                .then(response => {
                    if(!response.ok) {
                        throw Error('You must be logged in to view this content')
                    }
          
                    console.log("returning response promise")
                    return response.json()
                })
                .then(response => {
                    setExistingRoles(response)
                    
                })
                .catch(err => {
                    console.error(err);
                })
              }
            else {
                setSaveFeedback("Not authorized")
            }
          
        }
        else 
            setExistingRoles([]);
    }

    const onReciprocalRoleNameChange = (event) => {
        setReciprocalRoleName(event.target.value);
        setSaveFeedback("");
        if(event.target.value) {
            if(userState && authReady && userState.userRoles && userState.userRoles.find(r => r === "admin")) {

                refreshUser()
    
                fetch('/.netlify/functions/load-roles', 
                  {
                      method: 'POST',
                      headers: userState && {
                      Authorization: 'Bearer ' + userState.token
                      },              
                      body: JSON.stringify({
                          query: event.target.value,
                          amount: 20,
                          sortMode: ORDER_BY_LABEL_LENGTH
                      })
                  })
                .then(response => {
                    if(!response.ok) {
                        throw Error('You must be logged in to view this content')
                    }
          
                    console.log("returning response promise")
                    return response.json()
                })
                .then(response => {
                    setExistingReciprocalRoles(response)
                    
                })
                .catch(err => {
                    console.error(err);
                })
              }
            else {
                setSaveFeedback("Not authorized")
            }
          
        }
        else 
            setExistingRoles([]);
    }


    const handleSubmit = (event) => {
        event.preventDefault();

        if(authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin")) {
            setSaveFeedback("Saving...")

            let headers
            if(userState) {
                refreshUser()
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }

            fetch('/.netlify/functions/create-role', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    roleName: roleName,
                    reciprocalRoleName: reciprocalRoleName
                })
            })
            .then((response) => {
                if(!response.ok) {
                    console.error("AddRolePage.handleSubmit -- failed", response)
                    setSaveFeedback("Save failed")
                }
                else {
                    setSaveFeedback("Roles successfully created")
                }

                return response.json()
            })
            .then((response) => {
                console.log("AddRolePage.handleSubmit -- response", response)
            })
            .catch(err => {
                console.error("AddRolePage.handleSubmit -- error merging", err)
            })
        }
        

        else {
            setSaveFeedback("You are not authorized for this action")
        }
    }

    var existingRolesHtml = existingRoles.map(c => 
        <p key={c.dbid}>{c.label}</p>
        )

    var existingReciprocalRolesHtml = existingReciprocalRoles.map(c => 
        <p key={c.dbid}>{c.label}</p>)

    return (

        <div className={"standardbodywrapper " + props.screenMode}>            

            <div className={"simplePage " + props.screenMode}>
                <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titleaddroles">ADD ROLE</div>
                <div className="simplePageChildren">
                {!authReady && <p>...</p>}
                {authReady && userState && userState.userRoles && !userState.userRoles.find(r => r === "admin") 
                    &&  <><br/><p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link> with an administrator account.</p></>}
                {authReady && !userState 
                &&  <><br/><p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link> with an administrator account.</p></>}

                {authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && 
                    <>
                        <div className="box">
                            <form onSubmit={handleSubmit}>
                                <br />
                                <label>Choose role name</label>
                                <input required type="text" className="mine" onChange={onRoleNameChange} placeholder="Role" value={roleName}/><br/><br/>
                                {existingRoles.length > 0 ? <label>Maybe your role was already added?</label> : ""}
                                <div>{existingRolesHtml}</div>
                                <br/>

                                <label>Choose reciprocal role name</label>
                                <input required type="text" className="mine" onChange={onReciprocalRoleNameChange} placeholder="Reciprocal role" value={reciprocalRoleName}/><br/><br/>
                                {existingReciprocalRoles.length > 0 ? <label>Maybe your role was already added?</label> : ""}
                                <div>{existingReciprocalRolesHtml}</div>
                                <br/>

                                <span  className="toolbox">{saveFeedback}</span>

                                <div className="toolbox"><input type="submit" className="btnsmall" value="Create"/></div>
                            </form>
                        </div>
                    </>
                }
                </div>
            </div>
        </div>
    )
}


  
export default AddRolePage;