import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../stores/authContext'
import {useParams} from "react-router-dom";
import { ORDER_BY_LABEL_LENGTH } from '../utils/constants';
import { Link } from "react-router-dom"; 
import Searcher from '../components/Searcher';


function ManageConceptTypesPage (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)

    const [conceptTypes, setConceptTypes] = useState([])

    const [newConceptType, setNewConceptType] = useState({dbid: -1})

   

    useEffect(() => {
        console.log("AddRolePage.useEffect -- user:", userState)

        let headers

        if(authReady) {
            if(userState) {
                refreshUser()
    
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }
    
            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: "manageconcepttypes"
                })
            })


            
            fetch('/.netlify/functions/load-concepttypes', 
            {
                method: 'POST',
                headers: userState && {
                Authorization: 'Bearer ' + userState.token
                },              
                body: JSON.stringify({
                })
            })
            .then(response => {
                if(!response.ok) {
                    throw Error('You must be logged in as an admin to view this content')
                }
        
                console.log("returning response promise")
                return response.json()
            })
            .then(response => {
                response.sort((a,b) => a.dbid - b.dbid)
                setConceptTypes(response)
                
            })
            .catch(err => {
                console.error(err);
            })

        }
       
    }, [authReady, userState])
        


    const onChange = (conceptType) => {
        console.log("ManageConceptTypesPage.onChange -- conceptTypes", conceptTypes, conceptType)

        var newConceptTypes = conceptTypes.filter(ct => ct.dbid !== conceptType.dbid)
        console.log("ManageConceptTypesPage.onChange -- newConceptTYpes", newConceptTypes, conceptType)
        newConceptTypes.push({
            ...conceptType,
            changed: true,
            saveFeedback: ""
        })
        console.log("ManageConceptTypesPage.onChange -- newConceptTYpes after push", newConceptTypes, conceptType)

        newConceptTypes.sort((a,b) => a.dbid - b.dbid)
        setConceptTypes(newConceptTypes)
    }

    const saveChanges = (dbid) => {
        var updatedConceptType = conceptTypes.find(ct => ct.dbid === dbid)

        var headers
        if(userState) {
            refreshUser()

             headers = {
                Authorization: 'Bearer ' + userState.token
            }
        }
            
        
        if(userState && dbid !== -1) {
            
            fetch('/.netlify/functions/update_concepttype', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    ...updatedConceptType,
                })
            })
            .then(response => {
                if(!response.ok) {
                    throw Error("Error saving: " + response.errorMessage)
                }

                return response.json()

            })
            .then(response => {
                console.log("MergeConceptTypesPage.saveChanges -- update-concepttype response", response)
                var updatedConceptTypes = conceptTypes.filter(ct => ct.dbid !== dbid)
                updatedConceptTypes.push({
                    ...updatedConceptType,
                    changed: false,
                    saveFeedback: "Successfully updated"
                })
                updatedConceptTypes.sort((a,b) => a.dbid - b.dbid)
                setConceptTypes(updatedConceptTypes)
            })
            .catch(err => {
                console.error(err)
                
            })
        }
       
        


        if(userState && newConceptType.label && dbid === -1) {
               
            fetch('/.netlify/functions/create-concepttype', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    ...newConceptType,
                })
            })
            .then(response => {
                if(!response.ok) {
                    throw Error("Error saving: " + response.errorMessage)
                }

                return response.json()

            })
            .then(response => {
                console.log("MergeConceptTypesPage.saveChanges -- create-concepttype response", response)
                var updatedConceptTypes = conceptTypes.filter(ct => ct.dbid !== dbid)
                newConceptType.saveFeedback = "Successfully created"
                newConceptType.dbid = response.dbid
                newConceptType.changed = false
                updatedConceptTypes.push(newConceptType)
                updatedConceptTypes.sort((a,b) => a.dbid - b.dbid)
                setConceptTypes(updatedConceptTypes)
                setNewConceptType({})
            })
            .catch(err => {
                console.error(err)
                newConceptType.saveFeedback = "Creation failed"
            })
        }
    }

    const deleteConceptType = (dbid) => {
        var headers
        if(userState) {
            refreshUser()

             headers = {
                Authorization: 'Bearer ' + userState.token
            }

            if(userState && dbid !== -1) {
            
                fetch('/.netlify/functions/delete-concepttype', {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        dbid: dbid
                    })
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error("Error deleting: " + response.errorMessage)
                    }
    
                    return response.json()
    
                })
                .then(response => {
                    console.log("MergeConceptTypesPage.saveChanges -- update-concepttype response", response)
                    var updatedConceptTypes = conceptTypes.filter(ct => ct.dbid !== dbid)
                    
                    setConceptTypes(updatedConceptTypes)
                })
                .catch(err => {
                    console.error(err)
                    
                })
            }
        }
    }

    console.log("ManageConceptTypesPage -- rebuilding rows", conceptTypes)
    
    var tableRows = conceptTypes.map(ct => 
        <tr key={ct.dbid}>
            <td  className="columnWidth"><input disabled={false} required type="text" onChange={(e) => onChange({label: e.target.value, dbid: ct.dbid, shapeLabel: ct.shapeLabel, shapeID: ct.shapeID})} className="mine" placeholder="Name your concepttype" value={ct.label}/></td>
            <td  className="columnWidth"><Searcher isDisabled={false} requiresInput={false} callback={(e) => onChange({label: ct.label, dbid: ct.dbid, shapeLabel: e.label, shapeID: e.dbid})} mode="conceptshape" value={{label: ct.shapeLabel, dbid: ct.shapeID}}/></td>
            <td  className="columnWidth">{ct.changed === true ? (console.log("changed " + ct.dbid + ct.changed), "x") : ""}{ct.saveFeedback}</td>
            <td><button disabled={!ct.changed} onClick={(e) => saveChanges(ct.dbid)}>Save</button></td>
            <td><button onClick={(e) => deleteConceptType(ct.dbid)}>Delete</button></td>

        </tr>
    )
    tableRows.push(
        <tr key={newConceptType.dbid}>
            <td  className="columnWidth"><input disabled={false} required type="text" onChange={(e) => {
                console.log("ManageConceptTypesPage -- change new concept label", e)
                setNewConceptType({label: e.target.value, dbid: -1, shapeLabel: newConceptType.shapeLabel, shapeID: newConceptType.shapeID, changed: true})
            } } className="mine" placeholder="Name your new concepttype" value={newConceptType.label}/></td>
            <td  className="columnWidth"><Searcher isDisabled={false} requiresInput={false} callback={(e) => setNewConceptType({label: newConceptType.label, dbid: newConceptType.dbid, shapeLabel: e.label, shapeID: e.dbid, changed: true})} mode="conceptshape" value={{label: newConceptType.shapeLabel, dbid: newConceptType.shapeID}}/></td>
            <td  className="columnWidth">{newConceptType.changed === true ? "x" : ""}{newConceptType.saveFeedback}</td>
            <td><button disabled={!newConceptType.changed} onClick={(e) => saveChanges(newConceptType.dbid)}>Save</button></td>
            <td/>
         </tr>
    )

    return (

        <div className={"standardbodywrapper " + props.screenMode}>            
            <div className={"simplePage " + props.screenMode}>
                <div className={"title " + (props.platform ? props.platform.platform_raw : "")}>MANAGE CONCEPT TYPES</div>

                <div className="simplePageChildren">
                    {!authReady && <p>...</p>}
                    {authReady && userState && userState.userRoles && !userState.userRoles.find(r => r === "admin") 
                        &&  <><br/><p><Link to="/login">Log in</Link> with an administrator account.</p></>}
                    {authReady && !userState 
                    &&  <><br/><p><Link to="/login">Log in</Link> with an administrator account.</p></>}

                    {authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && 
                        <>
                            <br/><br/>
                            <div >
                                <table className="padding-table-columns">
                                    <tbody className="padding-table-columns">

                                        <tr>
                                            <th className="columnWidth">Concept name</th>
                                            <th className="columnWidth">Shape</th>
                                            <th className="columnWidth">Status</th>
                                        </tr>
                                        {tableRows}
                                    </tbody>
                                </table>
                            </div>

                        </>
                    }
                </div>
            </div>
        </div>
    )
}


  
export default ManageConceptTypesPage;