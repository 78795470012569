import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from '../stores/authContext';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import TextareaAutosize from 'react-textarea-autosize';


/**
 * Expects props: element, onSelect function
 */
function ExploreTipPane(props) {

    let navigate = useNavigate();

    const {userState, authReady, refreshUser} = useContext(AuthContext)

    const[tip, setTip] = useState("")

    console.info("ExploreTipPane -- props",props);

    const updateTip = (exploreAction) => {

        console.log("ExploreTipPane.updateTip -- triggered", exploreAction)
        
        if(userState && authReady) {

            refreshUser()

            fetch('/.netlify/functions/explore-tip', 
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify({
                        exploreAction: exploreAction,
                    })
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error(response.errorMessage)
                    }
            
                    console.log("ExploreTipPane.getTip -- returning response promise")
                    return response.json()
                })
                .then(response => {
                    console.log("ExploreTipPane.getTip -- returning tip: ", response)
                    props.language === "en" && setTip(response.tip);
                    props.language === "es" && setTip(response.tip_spanish);
                })
                .catch(err => {
                    console.error(err);
                })

        }
            
    }


    useEffect(() => {
        console.log("ExploreTipPane.useEffect -- update tip", props.exploreAction)
        updateTip(props.exploreAction)
    }, [props.exploreAction, props.triggerTip])


    let className = "box overflow exploretippane" + (props.screenMode ? " " + props.screenMode : "");


        return (
            <>

            <div className={className}>
            <h2><i className="glyphicon glyphicon-star"/> Hint</h2> {tip}
            </div>
            </>
        );
    
}

export default ExploreTipPane;