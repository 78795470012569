import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../stores/authContext'
import { Link } from "react-router-dom"; 
import OverviewCard from '../components/OverviewCard';

const {ORDER_BY_CREATIONDATE_DESC, ORDER_BY_LABEL_LENGTH, FILTER_VERIFIED, FILTER_UNVERIFIED, FILTER_ALL } = require("../utils/constants");


function OverviewsPage (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)

    const[overviews, setOverviews] = useState([])
    const[query, setQuery] = useState("")
    const [loading, setLoading] = useState(false)

    
    const loadOverviews = (_query) => {
        console.log("OverviewsPage -- loadOverviews triggered")
        if(authReady) {

            setOverviews([])
            setLoading(true)
            refreshUser()
           
            fetch('/.netlify/functions/load-overviews', 
                {
                    method: 'POST',
                    //headers: userState && {
                    //Authorization: 'Bearer ' + userState.token
                    //},              
                    body: JSON.stringify(
                        {
                            query: query,
                            status: (userState && userState.userRoles && userState.userRoles.find(r => r === "admin") ? undefined : "final"),
                            headersOnly: true,
                            page: 0,
                            amount: 50
                        })
                })
            .then(response => {
                if(!response.ok) {
                    throw Error('OverviewsPage.useEffect -- response NOK',response.errorMessage)
                }

        
                return response.json()
            })
            .then(response => {
                console.log("OverviewsPage.loadOverviews -- response", response)
                

                let _overviews = [].concat(overviews)
                response.forEach(o => {
                    if(!_overviews.find(_o => parseInt(_o.id) === parseInt(o.id))) {
                        _overviews.push(o)
                    }
                })

                console.log("OverviewsPage.loadOverviews -- setting overfviews", _overviews)
                
                setOverviews(_overviews)
                setLoading(false)
            })
            .catch(err => {
                console.error(err);
            })
                
            
        }
    }
  

    useEffect(() => {
        console.log("OverviewsPage.useEffect -- user:", userState)

        if(authReady) {
            let headers
            if(userState) {
                refreshUser()
    
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }
    
            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: "overviews"
                })
            })
        }
       
    }, [authReady, userState])

    useEffect(() => {
        console.log("OverviewsPage.useEffect -- triggered", query)

        if(authReady) {
            loadOverviews(query)
        }

    }, [userState, authReady, query]);



    return(
        <div className={"standardbodywrapper " + props.screenMode}>
           

            <div className={"overviewPage " + props.screenMode}>

                <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titleoverviews">PATHWAYS AND OVERVIEWS</div>

                {!authReady && <p><br/>Authenticating...</p>}

                {true && 
                <>
                    <div className="overviewFilters">
                        <input type="text" className="mine" onChange={(e) => setQuery(e.target.value)} placeholder="Filter" value={query}/>
                    </div>
        
                    
                    <div className={props.screenMode === "mobile" ? "overviewCardList mobile" : "overviewCardList"}>
                        {loading && <p>Loading...</p>}

                        {overviews && overviews.filter(o => (o.name && o.name.toLowerCase().includes(query.toLowerCase())) || (o.description && o.description.toLowerCase().includes(query.toLowerCase()))).map(e => 
                            <OverviewCard 
                            {...props} 
                            
                            key={e.id}
                            triggerLoad={loadOverviews}
                            overview={e} 
                        
                            className="infoLearnPane"/>
                            )}
                    </div>
                </>
                }

            </div>

        </div>
    );
}

export default OverviewsPage;