import React, { useState, useEffect, useContext } from 'react';
import Explorer from '../components/Explorer'
import InfoPane from '../components/InfoPane'
import RelevantElementsPane from '../components/RelevantElementsPane';
import Searcher from '../components/Searcher';
import AuthContext from '../stores/authContext'
import {useParams} from "react-router-dom";
import { Link } from "react-router-dom"; 
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import DataFilterCheckbox from '../components/DataFilterCheckbox';
import ExploreTipPane from '../components/ExploreTipPane';
import {Editor, EditorState, RichUtils, convertToRaw, convertFromRaw} from 'draft-js';
import { useNavigate } from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Login from '../components/Login';
import Subscribe from '../components/Subscribe';

function ExplorePage (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)
    let navigate = useNavigate()



    let { selectedElementType, selectedElementID } = useParams()
    let { overview } = useParams()

    console.log("ExplorePage.overview", overview)

    const newDataState = {
        allConcepts: [],
        allRelations: [],
        relevantConcepts: [],
        selectedElement: undefined,
        nodePositions: [],
        sliderSettings: {min: 0, max: 0, current: 0},
        relationReferencesFilter: []
    }
   
    const [history, setHistory] = useState(() => {
        if(false) {
            return {history: [newDataState], position: 0}

        }
        else {
            const saved = localStorage.getItem("ExplorePage.history")        
            console.log("ExplorePage.useState -- history -- from localstorage")
            const initialValue = saved && saved !== "null" ? JSON.parse(saved) : {history: [newDataState], position: 0}
    
            return initialValue
        }
    })

    const [overviewProps, setOverviewProps] = useState ({name: "", description: "", status: "Draft"})
    const [storeOverviewFeedback, setStoreOverviewFeedback] = useState ("")
    const [overviewName, setOverviewName] = useState("")
    const [filterRelations, setFilterRelations] = useState(false)

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty()
      );


    const [loginDialogOpen, setLoginDialogOpen] = useState(false);
    const [subscribeDialogOpen, setSubscribeDialogOpen] = useState(false);

    const [refreshed, setRefreshed] = useState(false);


    console.log("ExplorePage -- tipContext at load -- ", history.history[history.position].allConcepts && history.history[history.position].allConcepts.length > 0 ? "" : "NotStarted")
    let _noConcepts = history && history.history && history.position && history.history[history.position] && history.history[history.position].allConcepts ? history.history[history.position].allConcepts.length : 0
    let _tipContext = ""
    if(_noConcepts === 0) {
        _tipContext = "NotStarted"
    }
    else if(_noConcepts <= 4) {
        _tipContext = "ExpandSmall"
    }
    else {
        _tipContext = "ExpandBig"
    }
    
    const[tipContext, setTipContext] = useState(_tipContext)
    const[triggerTip, setTriggerTip] = useState(0)

    const loadHistory = () => {
        if(false) {
            return {history: [newDataState], position: 0}
        }
        else {
            const saved = localStorage.getItem("ExplorePage.history")        
            console.log("ExplorePage.loadHistory -- history -- from localstorage: ")
            const initialValue = saved && saved !== "null" ? JSON.parse(saved) : {history: [newDataState], position: 0}
    
            return initialValue
        }

    }

    const getCurrentConfidenceLimit = () => {
        if(props.platform.platform_raw === "ninatoka") {
            return getMinConfidenceLimit()
        }
        else if(history && history.history && typeof(history.position) !== "undefined" && history.history[history.position] && history.history[history.position].sliderSettings && history.history[history.position].sliderSettings.current) {
            return history.history[history.position].sliderSettings.current
        }
        else {
            return 0
        }
    }

    const getMinConfidenceLimit = () => {
        if(history && history.history && typeof(history.position) !== "undefined" && history.history[history.position] && history.history[history.position].sliderSettings && history.history[history.position].sliderSettings.min) {
            return history.history[history.position].sliderSettings.min
        }
        else if(history && history.history && history.position && history.history[history.position]) {
            const sliderSettings = calculateConfidencePointsSlider(history.history[history.position].allConcepts, history.history[history.position].allRelations, 0)
            return sliderSettings.min
        }
        else {
            return 0
        }
    }

    const getMaxConfidenceLimit = () => {
        if(history && history.history && typeof(history.position) !== "undefined" && history.history[history.position] && history.history[history.position].sliderSettings && history.history[history.position].sliderSettings.max) {
            return history.history[history.position].sliderSettings.max
        }
        else if(history && history.history && typeof(history.position) !== "undefined" && history.history[history.position]) {
            const sliderSettings = calculateConfidencePointsSlider(history.history[history.position].allConcepts, history.history[history.position].allRelations, 0)
            return sliderSettings.max
        }
        else {
            return 0
        }
    }


   
    const loadRelation = async (elementId) => {
        console.log("ExplorePage.loadRelation -- relation, triggering load-relations")

        const result = await fetch('/.netlify/functions/load-relations', 
            {
                method: 'POST',
                headers: userState && {
                Authorization: 'Bearer ' + userState.token
                },              
                body: JSON.stringify({
                    dbid: parseInt(elementId)
                })
            })
            .then(response => {
                if(!response.ok) {
                    throw Error('ExplorePage.loadRelation -- error loading', response)
                }
    
                console.log("ExplorePage.loadRelation -- returning response promise")
                return response.json()
            })
            .then(response => {
                let concepts = []
                let relations = []

                console.log("ExplorePage.loadRelation -- response:", JSON.stringify(response))

                response.forEach(r => {
                    concepts.push(r.sourceDetails)
                    concepts.push(r.targetDetails)
                    relations.push(r)
                })

                return {
                    concepts: concepts,
                    relations: relations
                }
            })
            .catch(err => {
                console.error(err);
                throw new Error("ExplorePage.loadRelation -- Could not fetch or process load-relations: " + err)
            })
        
        console.log("ExplorePage.loadRelation -- processed result:", result)

        return new Promise((resolve, reject) => {
            resolve(result)
        })
    }

        /**
     * 
     * @param {*} elementId 
     * @returns Promise
     */
    const loadConceptWithNeighbors = async (elementId) => {

        console.log("ExplorePage.loadConceptWithNeighbors -- ", elementId)


        if(userState && authReady) {

            console.log("ExplorePage.loadConceptWithNeighbors -- refreshing user")

            refreshUser()
            
            const result = await fetch('/.netlify/functions/load-concept-with-neighbors', 
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify({
                        dbid: parseInt(elementId)
                    })
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error('ExplorePage.loadConceptWithNeighbors -- error loading', response)
                    }
        
                    console.log("ExplorePage.loadConceptWithNeighbors -- returning response promise")
                    return response.json()
                })
                
                .catch(err => {
                    console.error(err)
                    throw new Error("ExplorePage.loadConceptWithNeighbors -- Could not fetch or process load-concept-with-neighbors: " + err)
                })
                
                console.log("ExplorePage.loadConceptWithNeighbors -- returning", result)
                return result //returns a promise
            
        }

        else {
            console.log("ExplorePage.loadConceptWithNeighbors -- missing userstate or authready")
        }

    }

    
    const loadConceptWithoutNeighbors = async (elementId) => {
        let headers = {};
        if(userState && authReady) {
            headers = userState && {
                Authorization: 'Bearer ' + userState.token
                }
        }

        console.log("ExplorePage.loadConceptWithoutNeighbors -- refreshing user")

        refreshUser()

        const result = await fetch('/.netlify/functions/load-concepts', 
            {
                method: 'POST',
                headers: headers,              
                body: JSON.stringify({
                    dbid: parseInt(elementId)
                })
            })
            .then(response => {
                if(!response.ok) {
                    throw Error('ExplorePage.loadConceptWithoutNeighbors -- error loading', response)
                }
    
                console.log("ExplorePage.loadConceptWithoutNeighbors -- returning response promise")
                return response.json()
            })
            
            .catch(err => {
                console.error(err)
                throw new Error("ExplorePage.loadConceptWithoutNeighbors -- Could not fetch or process load-concept-with-neighbors: " + err)
            })
        
        console.log("ExplorePage.loadConceptWithoutNeighbors -- response", result)
        return result //returns a promise
            
        


    }



    const processNewConceptsAndRelations = (elementType, elementId, response, highlight = false, select = false) => {
        console.log("ExplorePage.processNewConceptsAndRelations -- triggered", response)
        //when fetched, merge incoming data with current state


        let historyLS = loadHistory()

        
        if(!historyLS.history[historyLS.position].allConcepts || historyLS.history[historyLS.position].allConcepts.length === 0) {
            console.log("ExplorePage.processNewConceptsAndRelations -- update tip context to ", "FirstSearch")
            setTipContext("FirstSearch")
            setTriggerTip(triggerTip+1)
        }
        if(response.concepts && response.concepts.length > 4) {
            console.log("ExplorePage.processNewConceptsAndRelations -- update tip context to ", "ExpandBig")
            setTipContext("ExpandBig")
            setTriggerTip(triggerTip+1)
        }
        else {
            console.log("ExplorePage.processNewConceptsAndRelations -- update tip context to ", "ExpandSmall")
            setTipContext("ExpandSmall")
            setTriggerTip(triggerTip+1)
        }

        let allConcepts = []
        let allRelations = []
        let relationRefFilters = []
        let relationRoleFilters = []
        let relevantConcepts = []

        if(historyLS && historyLS.history && typeof(historyLS.position) !== "undefined") {

            if(elementType === "concept") {
                 
            }
            
            allConcepts = [].concat(historyLS.history[historyLS.position].allConcepts)
            allRelations = [].concat(historyLS.history[historyLS.position].allRelations)
            relevantConcepts = [].concat(historyLS.history[historyLS.position].relevantConcepts)
            relationRefFilters = {...historyLS.history[historyLS.position].relationRefFilters}
            relationRoleFilters = {...historyLS.history[historyLS.position].relationRoleFilters}
        

            let newAllConcepts = [].concat(allConcepts)
            let newAllRelations = [].concat(allRelations)
            let newRelevantConcepts = [].concat(relevantConcepts)
            let newSelectedElement = {...historyLS.history[historyLS.position].selectedElement}

            if(elementType === "concept" && response.concepts.length === 0) {
                newAllConcepts = removeElements(allConcepts, [{dbid: elementId}])
                newRelevantConcepts = removeElements(relevantConcepts, [{dbid: elementId}])
            }
            else if(elementType === "relation" && response.relations.length === 0) {
                newAllRelations = removeElements(allRelations, [{dbid: elementId}])
            }
            else {
                newAllConcepts = response && response.concepts ? mergeElements(allConcepts, response.concepts) : allConcepts
                newAllRelations = response && response.relations ? mergeElements(allRelations, response.relations) : allRelations
            }

            console.log("ExplorePage.processNewConceptsAndRelations -- allConcepts result", newAllConcepts)

            if(select) {
                newSelectedElement = (elementType === "concept" ? newAllConcepts.find(c => parseInt(c.dbid) === parseInt(elementId))
                : newAllRelations.find(r => parseInt(r.dbid) === parseInt(elementId)))
            }
           

            if(highlight) {
                let newRelevantConcept = newAllConcepts.find(c => parseInt(c.dbid) === parseInt(elementId))
                newRelevantConcepts = mergeElements(newRelevantConcepts, [newRelevantConcept])
            }

            console.log("ExplorePage.processNewConceptsAndRelations -- newSelected", newSelectedElement)

            const newSliderSettings = calculateConfidencePointsSlider(newAllConcepts, newAllRelations, newSelectedElement ? Math.min(getCurrentConfidenceLimit(), newSelectedElement.confidencePoints) : getCurrentConfidenceLimit())                                            
            const newRelationRefFilters = calculateRelationRefFilters(newAllRelations, relationRefFilters)
            const newRelationRoleFilters = calculateRelationRoleFilters(newAllRelations, relationRoleFilters)

            let newDataState = {
                ...historyLS.history[historyLS.position],
                allConcepts: newAllConcepts,
                allRelations: newAllRelations,
                relevantConcepts: newRelevantConcepts,
                selectedElement: newSelectedElement,
                sliderSettings: newSliderSettings,
                relationRefFilters: newRelationRefFilters,
                relationRoleFilters: newRelationRoleFilters
            }

            //setDataState(newDataState)

            saveHistory(newDataState)
        }   
    }

    const calculateRelationRefFilters = (relations, relationRefFilters) => {
        console.log("ExplorePage -- calculateRelationRefFilters entered", relations, relationRefFilters)
        let uniqueReferencesArray = [].concat(...relations.map(relation => {
            if (relation.reference && relation.reference.includes("---")) {
                let simplifiedReference = relation.reference.replace(/^-{3,}$/mg, "---");
                return simplifiedReference.split("---").filter(item => item !== "");
            }
            return relation.reference || "";
          })).filter(Boolean);
          
        console.log("ExplorePage -- calculateRelationRefFilters -- UPDATE UPDATE UPDATE")
        
        uniqueReferencesArray = [...new Set(uniqueReferencesArray)]
        uniqueReferencesArray.sort((a, b) => a[0].localeCompare(b[0]))
        let uniqueReferencesMap = {}
        uniqueReferencesArray.forEach(r => {
            uniqueReferencesMap[r] =  relationRefFilters[r] ? true :
            console.log("ExplorePage.calculateRelationRefFilters -- setting value for r",r,relationRefFilters[r])
        })
    
        console.log("ExplorePage -- calculateRelationRefFilters output", uniqueReferencesMap)
        return uniqueReferencesMap
    }

    const calculateRelationRoleFilters = (relations, relationRoleFilters) => {
        console.log("ExplorePage -- calculateRelationRoleFilters entered", relations, relationRoleFilters)
        let uniqueRolesArray = []
        relations.forEach(item => {
            if(item.reference) {
                uniqueRolesArray = [...uniqueRolesArray, ...item.reference.split('\n')]
            }
        })
        uniqueRolesArray = [...new Set(relations.map(item => item.role))]
        let uniqueRolesMap = {}
        uniqueRolesArray.forEach(r => {
            uniqueRolesMap[r] = relationRoleFilters[r] ? true : false
        })
    
        console.log("ExplorePage -- calculateRelationRoleFilters output", uniqueRolesMap)
        return uniqueRolesMap
    }

    const onSelectAndExpand = (elementType, elementId) => {

        console.log("ExplorePage.onSelectAndExpand -- triggered", elementType, elementId)

        console.log("ExplorePage.onSelectAndExpand --- is concept? ", elementType === "concept")


        if(userState && userState.hasPro) {

            if(elementType === "concept") {
                console.log("ExplorePage.onSelectAndExpand --- going into concept logic")
                loadConceptWithNeighbors(elementId)
                .then (response => {
                    console.log("ExplorePage.onSelectAndExpand --- received loadConceptWithNeighbors response", response)
                    loadConceptWithoutNeighbors(elementId)
                    .then(detailResponse => {
                        let concepts = mergeElements(response.concepts, detailResponse)
                        processNewConceptsAndRelations(elementType, elementId, {concepts: concepts, relations: response.relations}, false, true)
                    })
    
                          
                })
            } 
            else if(elementType === "relation") {
                console.log("ExplorePage.onSelectAndExpand --- going into relation logic")
    
                loadRelation(elementId)
                .then (response => {
                    console.log("ExplorePage.onSelectAndExpand --- received loadRelation response", response)
    
                    processNewConceptsAndRelations(elementType, elementId, response, false, true)
                })
            }
    
            logExplore(elementType, elementId)
        }

        else {
            console.log("ExplorePage.onSelectAndExpand -- cancelled since doesnt have pro")
        }
    

    }

    const onSelect = (elementType, elementId) => {

        console.log("ExplorePage.onSelect -- triggered", elementType, elementId)

        let historyLS = loadHistory()

        console.log("ExplorePage.onSelect -- loaded history", historyLS)


        if(elementType === "concept") {
            loadConceptWithoutNeighbors(elementId)
            .then (response => {
                processNewConceptsAndRelations(elementType, elementId, {concepts: response, relations: []}, false, true)
            })
        }
        else if (elementType === "relation") {
            loadRelation(elementId)
            .then (response => {
                console.log("ExplorePage.onSelect -- relation loaded",response)
                processNewConceptsAndRelations(elementType, elementId, response, false, true)
            })
        }

        logExplore(elementType, elementId)
    }


    const onHighlight = (elementId) => {
        let historyLS = loadHistory()

        let newRelevantConcept = historyLS.history[historyLS.position].allConcepts.find(c => parseInt(c.dbid) === parseInt(elementId))

        if(newRelevantConcept) {
            let newRelevantConcepts = mergeElements(historyLS.history[historyLS.position].relevantConcepts, [newRelevantConcept])

            let newDataState = {
                ...historyLS.history[historyLS.position],
                relevantConcepts: newRelevantConcepts
            }
            
            //setDataState(newDataState)
            saveHistory(newDataState)
            logHighlight(elementId)

        }
    }

    const onAddConcept = (elementId) => {
        console.log("ExplorePage.onAddConcept -- triggered", elementId)
               

        loadConceptWithNeighbors(elementId)
        .then (response => {
            console.log("ExplorePage.onAddConcept --- received loadConceptWithNeighbors response", response)
            loadConceptWithoutNeighbors(elementId)
            .then(detailResponse => {
                let concepts = mergeElements(response.concepts, detailResponse)
                processNewConceptsAndRelations("concept", elementId, {concepts: concepts, relations: response.relations}, false, true)
            })

                  
        })

        logHighlight(elementId)
        logExplore("concept", elementId)
    }


    const onToggleHighlight = (elementId) => {
        let historyLS = loadHistory()

        console.log("ExplorePage.onToggleHighlight", elementId)
        let concept = historyLS.history[historyLS.position].relevantConcepts.find(c => parseInt(c.dbid) === parseInt(elementId))
        if(concept) {
            console.log("ExplorePage.onToggleHighlight -- concept already highlighted, removing it from highlights")
            let newRelevantConcepts = removeElements(historyLS.history[historyLS.position].relevantConcepts, [concept])

            let newDataState = {
                ...historyLS.history[historyLS.position],
                relevantConcepts: newRelevantConcepts
            }

            //setDataState(newDataState)
            saveHistory(newDataState)
        }
        else {
            console.log("ExplorePage.onToggleHighlight -- ")
            onHighlight(elementId)
        }

    }

    const onRemoveConcepts = (concepts, neighbor) => {
        let historyLS = loadHistory()

        console.log("ExplorePage.onRemoveConcepts -- triggered", concepts)
        let relationRefFilters = {...historyLS.history[historyLS.position].relationRefFilters}
        let relationRoleFilters = {...historyLS.history[historyLS.position].relationRoleFilters}
        let newAllConcepts = removeElements(historyLS.history[historyLS.position].allConcepts, concepts)
        let newRelevantConcepts = removeElements(historyLS.history[historyLS.position].relevantConcepts, concepts)
  

        let relatedRelations = []

        concepts.forEach(c => {
            let relatedRelationsForC = historyLS.history[historyLS.position].allRelations.filter(r => parseInt(r.sourcedbid) === parseInt(c.dbid) || parseInt(r.targetdbid) === parseInt(c.dbid))
            relatedRelations = relatedRelations.concat(relatedRelationsForC)
        })

        if(neighbor) {
            let neighborConcept = newAllConcepts.find(c => c.dbid === neighbor.dbid)
            neighborConcept.isExpanded = false
        }

        let newAllRelations = removeElements(historyLS.history[historyLS.position].allRelations, relatedRelations)

        let newRelationRefFilters = calculateRelationRefFilters(newAllRelations, relationRefFilters)
        let newRelationRoleFilters = calculateRelationRoleFilters(newAllRelations, relationRoleFilters)

        const newSliderSettings = calculateConfidencePointsSlider(newAllConcepts, newAllRelations, getCurrentConfidenceLimit())

        let newDataState = {
            ...historyLS.history[historyLS.position],
            allConcepts: newAllConcepts,
            relevantConcepts: newRelevantConcepts,
            allRelations: newAllRelations,
            sliderSettings: newSliderSettings,
            relationRefFilters: newRelationRefFilters,
            relationRoleFilters: newRelationRoleFilters
        }

        console.log("ExplorePage.onRemoveConcepts -- newDatastate", newDataState)

        //setDataState(newDataState)

        

        saveHistory(newDataState)
    }


    const onRemoveRelations = (relations) => {
        let historyLS = loadHistory()

        let relationRefFilters = {...historyLS.history[historyLS.position].relationRefFilters}
        let relationRoleFilters = {...historyLS.history[historyLS.position].relationRoleFilters}

        console.log("ExplorePage.onRemoveRelations -- triggered", relations)
        var newAllRelations = removeElements(historyLS.history[historyLS.position].allRelations, relations)

        let newRelationRefFilters = calculateRelationRefFilters(newAllRelations, relationRefFilters)
        let newRelationRoleFilters = calculateRelationRoleFilters(newAllRelations, relationRoleFilters)

        const newSliderSettings = calculateConfidencePointsSlider(historyLS.history[historyLS.position].allConcepts, newAllRelations, getCurrentConfidenceLimit())

        let newDataState = {
            ...historyLS.history[historyLS.position],
            allRelations: newAllRelations,
            sliderSettings: newSliderSettings,
            relationRefFilters: newRelationRefFilters,
            relationRoleFilters: newRelationRoleFilters
        }

        console.log("ExplorePage.onRemoveRelations -- newDatastate", newDataState)

        saveHistory(newDataState)
    }

    /**
     * 
     * @param {*} concepts 
     * @returns merged set of elements
     */
    const mergeElements = (elementsOriginal, elementsNew) => {
        if(elementsOriginal && elementsNew) {
            let elementsMerged = [].concat(elementsOriginal)
            console.log("ExplorePage.mergeConcepts -- state contains", elementsOriginal, elementsNew)

            elementsNew.forEach(e => {
                let existing = elementsMerged.find(m => parseInt(m.dbid) === parseInt(e.dbid))

                if(existing) {
                    elementsMerged.splice(elementsMerged.indexOf(existing), 1)
                }
                elementsMerged.push(e)
                
            })

            console.log("ExplorePage.mergeElements -- returning", elementsMerged)
            return elementsMerged
        }
        else return elementsOriginal
    }


    /**
     * 
     * @param {*} concepts 
     * @returns merged set of elements
     */
     const removeElements = (elementsOriginal, elementsToRemove) => {
        if(elementsOriginal && elementsToRemove) {
            let elementsMerged = [].concat(elementsOriginal)
            console.log("ExplorePage.removeElements -- elementsOriginal vs elementsToRemove", elementsOriginal, elementsToRemove)

            elementsToRemove.forEach(e => {
                let existing = elementsMerged.find(m => parseInt(m.dbid) === parseInt(e.dbid))
                if(existing) {
                    elementsMerged.splice(elementsMerged.indexOf(existing), 1)
                }
            })

            return elementsMerged
        }
        else return elementsOriginal
    }







    const logHighlight = (dbid) => {
        let headers
        if(userState) {

            refreshUser()

            headers = {
                Authorization: 'Bearer ' + userState.token
            }
        }

        fetch('/.netlify/functions/create-highlight', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                nodedbid: dbid
            })
        })
    }

    const logExplore = (type, dbid) => {
        if (type === "concept") {
            let headers
            if(userState) {

                refreshUser()

                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }

            fetch('/.netlify/functions/create-exploration', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    nodedbid: dbid
                })
            })
        }
    }

    const logVisit = () => {
       
        let headers
        if(userState) {

            refreshUser()

            headers = {
                Authorization: 'Bearer ' + userState.token
            }
        }

        console.log("ExplorePage.logVisit -- overviewName", overviewName)

      
        const searchParams = new URLSearchParams(window.location.search);
        const source = searchParams.get("source");
        const sourceRef = searchParams.get("source_ref");
        const adId = searchParams.get('ad_id');
        const fbclid = searchParams.get('fbclid');
        const mmId = searchParams.get('mm_id');
        const instaStoryId = searchParams.get('insta_story_id');
        const linkedInId = searchParams.get('linkedIn_id');
        const metaAdId = searchParams.get('meta_ad_id');
          

        fetch('/.netlify/functions/create-pagevisit', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                visitedPage: window.location.pathname.substring(1),
                comment: overview ? overviewName : "",
                source: source ? source : (adId || metaAdId)  ? "meta ad" : (fbclid ? "fb" : (mmId ? "marketing mail" : (instaStoryId ? "insta story" : (linkedInId ? "linkedIn" : "")))),
                ad_id: adId,
                fbclid: fbclid,
                mm_id: mmId,
                insta_story_id: instaStoryId,
                linkedIn_id: linkedInId,
                sourceRef: sourceRef ? sourceRef : metaAdId ? metaAdId : adId ? adId : "" + mmId ? mmId : "" + instaStoryId ? instaStoryId : "" + fbclid ? fbclid : "" + linkedInId ? linkedInId : "",
                params: window.location.search
            })
        })    

        
    }



    const getRelationsForSelected = () => {
        console.log("ExplorePage.getRelationsForSelected -- entered ")
        if(history.history[history.position] && history.history[history.position].selectedElement && history.history[history.position].allRelations) {
            console.log("ExplorePage.getRelationsForSelected -- selected elements", history.history[history.position].selectedElement);
            const relations = history.history[history.position].allRelations.filter(r => parseInt(r.sourcedbid) === parseInt(history.history[history.position].selectedElement.dbid));
            console.log("ExplorePage.getRelationsForSelected -- found relations",relations);
            return relations;
        }
        else {
            console.error("ExplorePage.getRelationsForSelected -- dataState incomplete ", history.history[history.position])
            return [];
        }
    }
    

    
    const clearState = (loading) => {

        const newSliderSettings = calculateConfidencePointsSlider([], [], 0)

        const newDataState = {
            allConcepts: [],
            allRelations: [],
            relevantConcepts: [],
            selectedElement: undefined,
            sliderSettings: newSliderSettings
        }

        localStorage.setItem("ExplorePage.history", JSON.stringify({
            history: [newDataState],
            position: 0
        }))


        //setDataState(newDataState)
        setHistory({
            history: [newDataState],
            position: 0,
            loading: loading
        })

        setTipContext("NotStarted")

    }

    const calculateConfidencePointsSlider = (allConcepts, allRelations, currentConfidencePoints) => {
        console.log("ExplorePage.calculateConfidencePointsSlider -- triggered")
        let confidencePoints = []
        confidencePoints = confidencePoints.concat(allConcepts.map(c => (c.confidencePoints ? c.confidencePoints : 0)))
        confidencePoints = confidencePoints.concat(allRelations.map(r => (r.confidencePoints ? r.confidencePoints : 0)))

        let minConfidencePoints = Math.min(...confidencePoints)
        let maxConfidencePoints = Number.isFinite(Math.max(...confidencePoints)) ? Math.max(...confidencePoints) : 0
        console.log("ExplorePage.calculateConfidencePointsSlider -- max", maxConfidencePoints, Number.isFinite(maxConfidencePoints))
        let current = Math.max(Math.min(currentConfidencePoints, maxConfidencePoints),minConfidencePoints)

        return {
            min: minConfidencePoints === Infinity ? 0 : minConfidencePoints,
            max: maxConfidencePoints === Infinity ? 0 : maxConfidencePoints,
            current: current === Infinity ? 0 : current
        }

    }

    const saveHistory = (_state) => {
        console.log("ExplorePage.saveHistory -- new state WOEF",_state)
        console.log("ExplorePage.saveHistory -- existing history",history)

        let historyLS = loadHistory()

        /**we don't save more than 10 steps to prevent cache memory issues */
        if(historyLS.history.length > 10 && historyLS.position >= historyLS.history.length - 10) {
            historyLS.position = historyLS.position - (historyLS.history.length - 10)
            historyLS.history = historyLS.history.slice(historyLS.history.length - 10, historyLS.history.length)
        }

        const state = {..._state}
        let newHistory = [].concat(historyLS.history)
        newHistory.splice(historyLS.position +1, 0, state)

        if(newHistory[historyLS.position + 1].selectedElement && newHistory[historyLS.position + 1].selectedElement.type === "concept") {
            newHistory[historyLS.position + 1].selectedElement = newHistory[historyLS.position + 1].allConcepts.find(c => c.dbid === newHistory[historyLS.position + 1].selectedElement.dbid)
        }
        if(newHistory[historyLS.position + 1].selectedElement && newHistory[historyLS.position + 1].selectedElement.type === "relation") {
            newHistory[historyLS.position + 1].selectedElement = newHistory[historyLS.position + 1].allRelations.find(r => r.dbid === newHistory[historyLS.position + 1].selectedElement.dbid)
        }

        console.log("ExplorePage.saveHistory -- new history",newHistory)


        localStorage.setItem("ExplorePage.history", JSON.stringify({
            history: newHistory,
            position: historyLS.position +1,
            loading: false
        }))

        setHistory({
            history: newHistory,
            position: historyLS.position +1,
            loading: false
        })

        console.log("ExplorePage.saveHistory -- latest concepts", newHistory[historyLS.position +1].allConcepts)

        if(storeOverviewFeedback) {
            setStoreOverviewFeedback(undefined)
        }
    }

    const storeOverview = async (mode, name, description, blogText, status, overview, id) => {
        console.log("ExplorePage.storeOverview -- triggered", mode, name, description, blogText, status, overview, id)
        const result = await fetch(mode === "create" ? '/.netlify/functions/create-overview' : '/.netlify/functions/update-overview',
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify({
                        dbid: id,
                        name: name,
                        description: description,
                        blogText: blogText,
                        overview: overview,
                        status: status
                    })
                })
                .then(response => {
                    if(!response.ok) {
                        setStoreOverviewFeedback("Save failed ")
                        throw Error('ExplorePage.storeOverview -- error', response)
                    }

                    return response.json()
                })
                
                .catch(err => {
                    console.error(err)
                    throw new Error("ExplorePage.storeOverview -- Could not store overview: " + err)
                })
    
        setStoreOverviewFeedback(result.message ? result.message : result.errorMessage)
    }


    const undo = () => {
        let historyLS = loadHistory()
        console.log("ExplorePage.undo -- triggered", historyLS)
        if(historyLS && historyLS.history && historyLS.history.length > 0 && historyLS.position > 0 && historyLS.position < historyLS.history.length) {
            console.log("ExplorePage.undo -- existing history position", historyLS.position)
            console.log("ExplorePage.undo -- existing history", historyLS.history)

            let newPosition = historyLS.position - 1
            console.log("ExplorePage.undo -- new history position", newPosition)
             
            let previousState = historyLS.history[newPosition]
            console.log("ExplorePage.undo -- previousState",previousState)

            
            localStorage.setItem("ExplorePage.history", JSON.stringify({
                ...historyLS,
                position: newPosition
            }))
                
            setHistory({
                ...historyLS,
                position: newPosition,
                loading: false
            })
            
        }
        else if(!historyLS || !historyLS.history || historyLS.history.length === 0){
            console.error("ExplorePage.undo -- There is no history?", historyLS)
        }
        else {
            console.log("ExplorePage.undo -- cannot undo - would go out of boundaries")
        }

    }
    
    const redo = () => {
        let historyLS = loadHistory()
        console.log("ExplorePage.redo -- triggered", historyLS)
        if(historyLS && historyLS.history && historyLS.history.length > 0 && historyLS.position >= 0 && historyLS.position < historyLS.history.length-1) {
            console.log("ExplorePage.redo -- existing history position", historyLS.position)
            console.log("ExplorePage.redo -- existing history", historyLS.history)

            let newPosition = historyLS.position +1
            console.log("ExplorePage.redo -- new history position", newPosition)
             
            let previousState = historyLS.history[newPosition]
            console.log("ExplorePage.redo -- previousState",previousState)
            /**
            setDataState({
                ...previousState,
            })
                */ 

            localStorage.setItem("ExplorePage.history", JSON.stringify({
                ...historyLS,
                position: newPosition
            }))

            setHistory({
                ...historyLS,
                position: newPosition,
                loading: false
            })
            
        }
        else if(!historyLS || !historyLS.history || historyLS.history.length === 0){
            console.error("ExplorePage.redo -- There is no history?", historyLS)
        }
        else {
            console.log("ExplorePage.redo -- cannot redo - would go out of boundaries")
        }
    }

    const onConfidenceLimitChange = (newLimit) => {
        let _history = {...history}

        if(_history && _history.history && typeof(_history.position) !== "undefined" && _history.history[_history.position]) {
            
            let currentState = {..._history.history[_history.position]}
            currentState = {
                ...currentState,
                sliderSettings: {
                    min: currentState.sliderSettings && currentState.sliderSettings.min ? currentState.sliderSettings.min : 0,
                    max: currentState.sliderSettings && currentState.sliderSettings.max ? currentState.sliderSettings.max : 0,
                    current: newLimit
                }
            }

            _history.history[_history.position] = currentState
            localStorage.setItem("ExplorePage.history", JSON.stringify(_history))
            
        }

        setHistory(_history)
    }

    const handleStoreOverview = (mode) => {
        console.log("ExplorePage.onStoreOverview -- triggered", mode)

        let historyLS = loadHistory()

        storeOverview(mode, overviewProps.name, overviewProps.description, convertToRaw(editorState.getCurrentContent()), overviewProps.status, JSON.stringify(historyLS.history[historyLS.position]), overview)
       
    }

    const loadOverview = (overviewId) => {

        console.log("ExplorePage.loadOverview -- triggered", overviewId)

        if((userState || overview) && authReady) {
            refreshUser()
            clearState(true)

            const result = fetch('/.netlify/functions/load-overviews', 
            {
                method: 'POST',
                //headers: userState && {
                //Authorization: 'Bearer ' + userState.token
                //},              
                body: JSON.stringify({
                    dbid: parseInt(overviewId)
                })
            })
            .then(response => {
                if(!response.ok) {
                    throw Error('ExplorePage.loadOverview -- error loading', response)
                }
    
                console.log("ExplorePage.loadOverview -- returning response promise")
                return response.json()
            })
            .then(response => {
                console.log("ExplorePage.loadOverview result", response)
                if(response && response.length === 1 && response[0].overview) {
                    let overviewJSON = JSON.parse(response[0].overview) 

                    setOverviewName(response[0].name)
                    setOverviewProps({
                        ...overviewProps,
                        id: overviewId,
                        name: response[0].name,
                        description: response[0].description,
                        status: response[0].status   
                    }) 

                    response[0].blogText && setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(response[0].blogText)))) 

                    if (!userState || !userState.hasPro) {
                        saveHistory(overviewJSON); //if we are not logged in, we just take the overview as is
                    } 

                    else if (userState && userState.hasPro) {
                        refreshElements(overviewJSON.allConcepts, overviewJSON.allRelations) //if we are logged in, we do a refresh first
                        .then(refreshResponse => {
                            console.log("ExplorePage.loadOverview -- saving in history", refreshResponse)
                            let updatedOverview = {
                                ...overviewJSON,
                                allConcepts: [].concat(refreshResponse.concepts),
                                allRelations: [].concat(refreshResponse.relations)
                            }
    
                            saveHistory(updatedOverview)       
    
                            if(overviewJSON.selectedElement) {
                                onSelect(overviewJSON.selectedElement.type, overviewJSON.selectedElement.dbid)
                            }
                            
                        })
    
                    }



                }
                else {
                    console.log("ExplorePage.loadOverview -- not just one result")
                }
            })
            
            .catch(err => {
                console.error(err)
                throw new Error("ExplorePage.loadOverview -- Could not fetch or process: " + err)
            })
        
        }

        else {
            console.log("ExplorePage.loadOverview -- missing userstate or authready")
        }

    }

    
    const refreshElements = async (concepts, relations) => {
        console.log("ExplorePage.refreshElements -- triggered", concepts, relations)
        var conceptsToRefresh = concepts.map(c => c.dbid)
        var relationsToRefresh = relations.map(c => c.dbid)


        if(userState && authReady) {
    
            console.log("ExplorePage.refreshElements -- refreshing user")

            refreshUser()

            let allConcepts = await fetch('/.netlify/functions/load-concepts', 
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify({
                        dbidarray: conceptsToRefresh,
                        noDetails: true
                    })
                })
                .then(responseConcepts => {
                    if(!responseConcepts.ok) {
                        throw Error('ExplorePage.refreshElements -- error loading', responseConcepts)
                    }
        
                    console.log("ExplorePage.refreshElements -- returning response promise")
                    return responseConcepts.json()
                })
                .then(responseConcepts => {
                    console.log("ExplorePage.refreshElements -- returning all concepts", responseConcepts)
                    return responseConcepts
                })
                .catch(err => {
                    console.error(err)
                    throw new Error("ExplorePage.refreshElements -- Could not fetch or process load-concepts " + err)
                })


            let allRelations = await fetch('/.netlify/functions/load-relations', {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify({
                        dbidarray: relationsToRefresh
                    })
                })
                .then(responseRelations => {
                    if(!responseRelations.ok) {
                        throw Error('ExplorePage.refreshElements -- error loading', responseRelations)
                    }

                    console.log("ExplorePage.refreshElements -- returning response promise")
                    return responseRelations.json()
                })
                .then(responseRelations => {
                    console.log("ExplorePage.refreshElements -- returning all relations", responseRelations)

                    return responseRelations.filter(r => allConcepts.find(c => c.dbid === r.sourcedbid) 
                                                                && allConcepts.find(c => c.dbid === r.targetdbid))
                    
                })
                .catch(err => {
                    console.error(err)
                    throw new Error("ExplorePage.refreshElements -- Could not fetch or process load-relations" + err)
                })

            return ({concepts: allConcepts, relations: allRelations})
                            
        }
    }
    
    const handleRefresh = () => {
        console.log("ExplorePage.handleRefresh -- triggered")

        let historyLS = loadHistory()
        let _currentState = historyLS.history[historyLS.position]

        console.log("ExplorePage.handleRefresh -- historyLS",historyLS)
        console.log("ExplorePage.handleRefresh -- _currentState", _currentState)

        clearState(true)


        refreshElements(_currentState.allConcepts,
            _currentState.allRelations
            )
            .then(response => {
                console.log("ExplorePage.handleRefresh -- received response and saving as new history", response)

                const newSliderSettings = calculateConfidencePointsSlider(response.concepts, response.relations, _currentState.sliderSettings.current)                                            

                _currentState = {
                    ..._currentState,
                    allConcepts: [].concat(response.concepts),
                    allRelations: [].concat(response.relations),
                    relevantConcepts: response.concepts.filter(c => _currentState.relevantConcepts.find(rc => rc.dbid === c.dbid)),
                    nodePositions: _currentState.nodePositions ? _currentState.nodePositions.filter(p => response.concepts.find(c => p.nodeId === "N"+c.dbid)) : [],
                    sliderSettings: newSliderSettings,
                    selectedElement: _currentState.selectedElement && response.concepts.find(c => c.dbid === _currentState.selectedElement.dbid) ? response.concepts.find(c => c.dbid === _currentState.selectedElement.dbid) : undefined
                }


                saveHistory(_currentState)
            })
    }

    useEffect(() => {
        console.log("ExplorePage.useEffect -- overview triggered", overview)
        
        if(overview && authReady) {
            console.log("ExplorePage.useEffect -- overview triggered and going through")
            
            loadOverview(overview)
        }
        else {
            setOverviewName("");
            //setOverviewProps({name: "", description: "", status: "Draft"})
        }
    }, [overview, authReady, userState])


    useEffect(() => {
        if(authReady) {
            setLoginDialogOpen(!userState);
        }
        
        if(authReady && userState) {
            setSubscribeDialogOpen(!userState.hasPro);
        }
    }, [authReady, userState])

    useEffect(() => {
        if(authReady && userState && history && !refreshed && !overview) {
            setRefreshed(true);
            handleRefresh();
        }
    }, [authReady, userState, history])


    useEffect(() => {
        console.log("ExplorePage.useEffect -- [selectedElementType, selectedElementID]", selectedElementType, selectedElementID)
        if(selectedElementType && selectedElementID && authReady && userState) {
            console.log("ExplorePage.useEffect -- [selectedElementType, selectedElementID] -- triggering onSelect")
            onSelectAndExpand(selectedElementType, selectedElementID)
        }
    }, [selectedElementType, selectedElementID, authReady, userState])



    useEffect(() => {
        console.log("ExplorePage.useEffect -- user:", userState)

        if(authReady && (!userState || (!overview || overviewName)) ) {
            logVisit()
        }

    }, [authReady, userState, overviewName])

    
    var sourceOfSelectedEdge
    var targetOfSelectedEdge
    var relationsForSelected
    
    if(history.history[history.position].selectedElement) {
        if(history.history[history.position].selectedElement.type === "relation") {
            sourceOfSelectedEdge = history.history[history.position].allConcepts.find(n => parseInt(n.dbid) === parseInt(history.history[history.position].selectedElement.sourcedbid))
            targetOfSelectedEdge = history.history[history.position].allConcepts.find(n => parseInt(n.dbid) === parseInt(history.history[history.position].selectedElement.targetdbid))
        }
        else {
            relationsForSelected = getRelationsForSelected()
        }
    }

    var infoPaneInput = history.history[history.position].selectedElement ? {
        ...history.history[history.position].selectedElement,
        relations: relationsForSelected,
        sourceDetails: sourceOfSelectedEdge,
        targetDetails: targetOfSelectedEdge
    } : undefined;

    var infoPane;
    if(infoPaneInput) {
        infoPane = <div className={"info " + props.screenMode}><InfoPane 
            {...props} 
            key={infoPaneInput ? infoPaneInput.dbid : ""} 
            element={infoPaneInput}
            onSelect={onSelect} onSelectAndExpand={onSelectAndExpand} onHighlight={onHighlight} showLinks={true}  showDiscussButton={true} className="infoPane" /></div>;
    }

    var searcher = <div className={"searcher " + props.screenMode}>
        <div className="box">
            <h6>
                {!overviewName && props.language === "en" && "Search for a physical condition, organ, process..."}
                {!overviewName && props.language === "es" && "Buscar una condición física, órgano, proceso..."}

                {overviewName && props.language === "en" && "Find more links..."}
                {overviewName && props.language === "es" && "Encontrar más enlaces..."}
            </h6>
            <Searcher isDisabled={!userState || !userState.hasPro} mode="concept" requiresInput={true} callback={(selected) => {
                    console.info("ExplorePage -- searcher callback called", selected);
                    onAddConcept(selected.dbid)
                }} />
         {!userState || !userState.hasPro && <><p><a onClick={() => setSubscribeDialogOpen(true)}>Pro license required</a></p></>}        
        </div>
    </div>;

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        console.log("editor key event", command, editorState);

        if (newState) {
        setEditorState(newState);
        return 'handled';
        }

        return 'not-handled';
    }
    var blogText = <div>
        
        <Editor editorState={editorState} onChange={setEditorState} handleKeyCommand={handleKeyCommand} 
            readOnly={!(authReady && userState && userState.userRoles && (userState.userRoles.find(r => r === "admin") || userState.userRoles.find(r => r === "contributor")))}/>
        
    </div>

    var explorer;
    try {
        explorer =  <div className={"explorer " + props.screenMode}>
        <Explorer 
            loading={history.loading}
            removeConcepts={onRemoveConcepts} 
            removeRelations={onRemoveRelations} 
            select={onSelect} 
            selectAndExpand={onSelectAndExpand}
            toggleRelevant={onToggleHighlight} 
            concepts={history.history[history.position].allConcepts} 
            relations={history.history[history.position].allRelations && history.history[history.position].hiddenRelations ? 
                history.history[history.position].allRelations.filter(item => !history.history[history.position].hiddenRelations.some(i => i.dbid === item.dbid))
                                                : history.history[history.position].allRelations} 
            relevantConcepts={history.history[history.position].relevantConcepts} 
            selected={history.history[history.position].selectedElement}
            confidencePointsLimit={getCurrentConfidenceLimit()} 
            token={userState && authReady && userState.token} 
            overview={overviewProps && overviewProps.id}
            />
        </div>
    } catch (err) {
        console.err("Explorepage", err);
        explorer =  <div className={"explorer " + props.screenMode}>
                        Error - please clear the explorer (click the trash bin button)
                    </div>
    }
    

    var highlights = <div className={"highlights " + props.screenMode}><RelevantElementsPane relevantElements={history.history[history.position].relevantConcepts} select={onSelect} toggleRelevant={onToggleHighlight}/></div>;

    var toolbar =  <div className="exploreToolbar">
            <button className="btnsmall" onClick={() => {clearState(false)}} title="Clear all from my exploration" disabled={overview}><i className="glyphicon glyphicon-trash trash"></i></button>
            <button className="btnsmall" onClick={undo} title="Go back" disabled={history.position === 0}><i className="glyphicon glyphicon-step-backward undo"></i></button>
            <button className="btnsmall" onClick={redo} title="Go forward" disabled={history.position === history.history.length -1}><i className="glyphicon glyphicon-step-forward redo"></i></button>
            <button className="btnsmall" title="Save new consult [not available yet]" disabled={true}><i className="glyphicon glyphicon-save save"></i></button>
            {authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && 
                <button className="btnsmall" onClick={handleRefresh} title="Renew all data" ><i className="glyphicon glyphicon-refresh"></i></button>
            }

            {userState && userState.userRoles && (userState.userRoles.find(r => r === "admin") || userState.userRoles.find(r => r === "contributor")) && <>
                <button className="btnsmall" title={"Add knowledge to the database"} onClick={(e) => {
                    window.open("/contribute-dialog", 'popUpWindow','height=875,width=600,left=10,top=10,,scrollbars=yes,menubar=no')
                }}><i className="glyphicon glyphicon-plus add" id="add" /></button>
            </>}

        </div>

    var slider = <Slider
                    range
                    dots={true}
                    step={1}
                    value={getCurrentConfidenceLimit()}
                    min={getMinConfidenceLimit()}
                    max={getMaxConfidenceLimit()}
                    onChange={onConfidenceLimitChange}
                />

    var storeOverviewBox = <div className={"overviewBox " + props.screenMode}>
        <div className="box">
            <h2>Store overview</h2>
                    <form>
                        <input required type="text" className="mine" placeholder="Name" value={overviewProps.name} onChange={(e) => setOverviewProps({...overviewProps, name: e.target.value})}/>
                        <input required type="text" className="mine"  placeholder="Description" value={overviewProps.description} onChange={(e) => setOverviewProps({...overviewProps, description: e.target.value})}/>

                        <div>
                            <input type="radio" name="status" value="Draft" onChange={(e) => setOverviewProps({...overviewProps, status: e.target.value})} checked={overviewProps.status === "Draft"}/>
                                <span>&#160;Draft</span>
                        </div>
                        <div>
                            <input type="radio" name="status" value="Final" onChange={(e) => setOverviewProps({...overviewProps, status: e.target.value})}  checked={overviewProps.status === "Final"}/>
                            <span>&#160;Final</span>
                        </div>
                        <br/>
                        <div className="toolbox">
                            <input type="submit" name="createOverview" className="btnsmall" value="Create" onClick={(e) => {e.preventDefault();handleStoreOverview("create")}}/>
                            <input disabled={!overview} type="submit" name="updateOverview" className="btnsmall" value="Update" onClick={(e) => {e.preventDefault();handleStoreOverview("update")}}/>
                        </div>
                        
                    </form>
                    {storeOverviewFeedback}
                </div>
        </div>

    var tipsPane = <ExploreTipPane {...props} exploreAction={tipContext} triggerTip={triggerTip} />
          

    const applyRelationRefsFilter = (filter) => {
        console.log("ExplorePage.applyRelationRefsFilter -- filter",filter, Object.entries(filter))
        let hiddenRelations = []
    
        if(Object.values(filter).some(v => v === true)) {
            //only continue when there's at least one filter checked
            console.log("ExplorePage.applyRelationRefsFilter -- at least one filter is checked")
            history.history[history.position].allRelations.forEach(r => {

                console.log("ExplorePage.applyRelationRefsFilter -- applicable filters", r.dbid, r.reference, 
                Object.keys(filter).some(f => r.reference && r.reference.includes(f) && filter[f]))

                if(!r.reference || !Object.keys(filter).some(f => r.reference && r.reference.includes(f) && filter[f])) {
                    hiddenRelations.push(r);
                }
            })
        }
    
        let newDataState = {
            ...history.history[history.position],
            hiddenRelations: hiddenRelations,
            relationRefFilters: filter
        }
    
        saveHistory(newDataState)     
    }

    const applyRelationRoleFilter = (filter) => {
        console.log("ExplorePage.applyRelationRoleFilter -- filter",filter, Object.entries(filter))
        let hiddenRelations = []
    
        if(Object.values(filter).some(v => v === true)) {
            //only continue when there's at least one filter checked
            console.log("ExplorePage.applyRelationRoleFilter -- at least one filter is checked")
            history.history[history.position].allRelations.forEach(r => {

                if(!filter[r.role]) hiddenRelations.push(r)
               
            })
        }
    
        let newDataState = {
            ...history.history[history.position],
            hiddenRelations: hiddenRelations,
            relationRoleFilters: filter
        }
    
        saveHistory(newDataState)     
    }

    


    console.log("ExplorePage -- basicSubscription?", userState)

    return (
        <div className={"standardbodywrapper " + props.screenMode}>            



            <Popup className={"popup" + props.screenMode} modal open={loginDialogOpen} onClose={() => {setLoginDialogOpen(false)}}>

                <div>
                    <a className="close" onClick={() => {setLoginDialogOpen(false)}}>
                        &times;
                    </a>
                    
                    <div className="dialogTitle">
                        {props.language === "en" && "Sign in to your account"}
                        {props.language === "es" && "Inicia sesión en tu cuenta de Ninatoka"}
                    </div>
                    <br/>
                    <div className="dialogWrapper">
                    <Login {...props} navigate={window.location.pathname}/>            
                    </div>
                    
                

                </div>
            </Popup>

            {overview && 
            <Popup className={"popup" + props.screenMode} modal open={subscribeDialogOpen} onClose={() => {setSubscribeDialogOpen(false)}}>

                <div>
                    <a className="close" onClick={() => {setSubscribeDialogOpen(false)}}>
                        &times;
                    </a>
                    
                    <div className="dialogTitle">
                        {props.language === "en" && "Subscribe"}
                        {props.language === "es" && "Subscribir"}
                    </div>
                    <br/>
                    <div className="dialogWrapper">
                        <Subscribe {...props} navigate={window.location.pathname} direction={props.screenMode === "mobile" ? "vertical" : "horizontal"}/>            
                    </div>
                    


                </div>
                </Popup>
            }

            
            {!authReady && <p><br/><br/>...</p>}

            
            {authReady && props.screenMode === "mobile" && 
            <>
                <div className="explorePage mobile">

                    {overviewName || (history && history.loading) ? 
                    <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titleexplorer" >

                    {(history && !overviewName ? "... " : overviewName)}
                    </div> : 
                    
                    <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titleexplorer">
                        {props.language === "en" && "EXPLORE"}
                        {props.language === "es" && "EXPLORAR"}
                    </div>
                    }

                    {!overview && <Subscribe {...props} direction={props.screenMode === "mobile" ? "vertical" : "horizontal"}/>}


                    {overviewName ? 
                    <div className="blogText">
                        {blogText}
                    </div> : <></>
                    }
                    {userState && userState.hasPro && toolbar}
                    {userState && searcher}
                    {((userState && userState.hasPro) || overview) && infoPane}
                    
                    {userState && userState.hasPro && tipsPane}
                    {((userState && userState.hasPro) || overview) && explorer}


                    
                    {props.platform.platform_raw !== "ninatoka" && <>
                        <div className={"slider " + props.screenMode}><div className="sliderdiv box">
                            <div className="slidertext">Minimum confidence level {getCurrentConfidenceLimit()}</div>
                            <div className="minSliderValue">{getMinConfidenceLimit()}</div>
                            <div className="slider">{slider}</div>
                            <div className="maxSliderValue">{getMaxConfidenceLimit()}</div>
                        </div></div>
                        <div className={"relationRefFilter " + props.screenMode}>
                            <DataFilterCheckbox filterSet={
                                {...history.history[history.position].relationRefFilters }} title="Relation References Filter" onChange={(filter) => {console.log("DATAFILTERCHECKBOX ONCHANGE"); applyRelationRefsFilter(filter)}} />
                            <DataFilterCheckbox filterSet={
                                {...history.history[history.position].relationRoleFilters }} title="Relation Roles Filter" onChange={(filter) => {console.log("DATAFILTERCHECKBOX ONCHANGE"); applyRelationRoleFilter(filter)}} />
                        
                        </div>
                    </>}


                    

                    {authReady && userState && userState.userRoles && (userState.userRoles.find(r => r === "admin") || userState.userRoles.find(r => r === "contributor")) && 
                            storeOverviewBox
                        }
            
                </div>

            </>
            }

          

           
            
            {authReady && props.screenMode !== "mobile" && 
            <div className="explorePageWrapper desktop">

                {overviewName || (history && history.loading) ? 
                    <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titleexplorer" >

                        
                        {(history && !overviewName ? "... " : overviewName)}
                    </div> : 
                    
                    <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titleexplorer">
                        {props.language === "en" && "EXPLORE"}
                        {props.language === "es" && "EXPLORAR"}
                    </div>
                }

                {!overview && <Subscribe {...props} direction={props.screenMode === "mobile" ? "vertical" : "horizontal"}/>}


                <div className="explorePage desktop">
                   
                    {overviewName ? 
                    <div className="blogText">
                        {blogText}
                    </div> : <></>
                    }

                    <div className="paneLeft">

                        {((userState && userState.hasPro) || overview) && explorer}

                    </div>
                    <div className="paneRight">
                        {userState && userState.hasPro && toolbar}     
                        {userState && userState.hasPro && tipsPane}
                        {userState && searcher}
                        {((userState && userState.hasPro) || overview) && infoPane}

                        {props.platform.platform_raw !== "ninatoka" && <>
                            <div className={"relationRefFilter " + props.screenMode}>
                                <DataFilterCheckbox filterSet={
                                    {...history.history[history.position].relationRefFilters }} title="Relation References Filter" onChange={(filter) => {console.log("DATAFILTERCHECKBOX ONCHANGE"); applyRelationRefsFilter(filter)}} />
                                <DataFilterCheckbox filterSet={
                                    {...history.history[history.position].relationRoleFilters }} title="Relation Roles Filter" onChange={(filter) => {console.log("DATAFILTERCHECKBOX ONCHANGE"); applyRelationRoleFilter(filter)}} />
                            
                            </div>         

                             <div className={"slider " + props.screenMode}>
                                <div className="sliderdiv box ">
                                    <div className="slidertext">Minimum confidence level {getCurrentConfidenceLimit()}</div>
                                    <div className="minSliderValue">{getMinConfidenceLimit()}</div>
                                    <div className="slider">{slider}</div>
                                    <div className="maxSliderValue">{getMaxConfidenceLimit()}</div>
                                </div>
                            </div>              
                        </>}

                      
                       

                        {authReady && userState && userState.userRoles && (userState.userRoles.find(r => r === "admin") || userState.userRoles.find(r => r === "contributor")) && 
                            storeOverviewBox
                        }
            
                    </div>
                </div>
            </div>
            }




        

            

             
        </div>            

    )
    
}



export default ExplorePage;