import React, { useEffect, useContext, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";

import AuthContext from '../stores/authContext'
import InfoPane from '../components/InfoPane';
import Comment from '../components/Comment';
import TextareaAutosize from 'react-textarea-autosize';
import { ORDER_BY_CREATIONDATE_DESC, ORDER_BY_UPDATEDATE_DESC } from '../utils/constants';


function ShowRatingsPage (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)
    let { id, type } = useParams();

    const [subject, setSubject] = useState({})
    const [ratings, setRatings] = useState([])

    console.info("ShowRatingsPage", props, type, id);


    const loadConcept = async (id) => {
        console.log("ShowRatingsPage.loadConcept -- triggered", userState)


        let headers
        if(userState) {
            refreshUser()
            headers = {
                Authorization: 'Bearer ' + userState.token
            }

            const result = await fetch('/.netlify/functions/load-concepts', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    dbid: id
                })
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error('ShowRatingsPage.loadConcept -- error loading', response)
                    }
    
                    console.log("ShowRatingsPage.loadConcept -- returning response promise")
                    return response.json()
                })
                .catch(err => {
                    console.error(err)
                    throw new Error("ShowRatingsPage.loadConcept -- Could not fetch or process load-concepts: " + err)
                })
    

            console.log("ShowRatingsPage.loadConcept -- result",result)
            if(result && result.length === 1) {
                console.log("ShowRatingsPage.loadConcept -- only result",result[0])
                setSubject(result[0])
            }

        }

        else {
            console.log("ShowRatingsPage.loadConcept -- No user known")
        }
        
    }

    const loadRelation = async (id) => {
        console.log("ShowRatingsPage.loadRelation -- triggered")

        let headers
        if(userState) {
            refreshUser()
            headers = {
                Authorization: 'Bearer ' + userState.token
            }

            const result = await fetch('/.netlify/functions/load-relations', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    dbid: id
                })
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error('ShowRatingsPage.loadRelation -- error loading', response)
                    }
    
                    console.log("ShowRatingsPage.loadRelation -- returning response promise")
                    return response.json()
                })
                .catch(err => {
                    console.error(err)
                    throw new Error("ShowRatingsPage.loadRelation -- Could not fetch or process load-concepts: " + err)
                })
    
                console.log("ShowRatingsPage.loadRelation -- result",result)
                if(result && result.length === 1) {
                    console.log("ShowRatingsPage.loadRelation -- only result",result[0])
                    setSubject(result[0])
                }
        }

        else {
            console.log("ShowRatingsPage.loadRelation -- No user known")
        }
        
    }

    
    const loadRatings = async (type, id) => {
        console.log("ShowRatingsPage.loadRatings -- triggered")

        let headers
        if(userState) {
            refreshUser()
            headers = {
                Authorization: 'Bearer ' + userState.token
            }

            let body
            if(type === "concept") {
                body = {
                    concept: id,
                    sortMode: ORDER_BY_UPDATEDATE_DESC
                }
            }
            else if(type === "relation") {
                body = {
                    relation: id,
                    sortMode: ORDER_BY_UPDATEDATE_DESC
                }
            }

            const result = await fetch('/.netlify/functions/load-ratings', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error('ShowRatingsPage.loadRatings -- error loading', response)
                    }
    
                    console.log("ShowRatingsPage.loadRatings -- returning response promise")
                    return response.json()
                })
                .catch(err => {
                    console.error(err)
                    throw new Error("ShowRatingsPage.loadRatings -- Could not fetch or process load-ratings: " + err)
                })
                
            console.log("ShowRatingsPage.loadRatings -- result", result)
            setRatings(result)
        }
    }

    const loadData = () => {
        if(userState && type && id) {
            if(type === "concept") {
                loadConcept(id)
            }
            else if(type === "relation") {
                loadRelation(id)
            }

            loadRatings(type, id)
        }
    }

    useEffect(() => {
        console.log("ShowRatingsPage.useEffect -- user:", userState)

        if(authReady && userState) {
            loadData()
        }

      
    }, [authReady, userState, type, id])
   
    useEffect(() => {
        console.log("ShowRatingsPage.useEffect -- user:", userState)


        if(authReady && (!userState || (subject && subject.label))) {
            let headers

            if(userState) {
                refreshUser()
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }

            }
    
            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: ("ratings/" + type + "/" + id),
                    comment: subject ? subject.label : ""
                })
            })
    
        }

      
    }, [authReady, userState, type, id, subject])





    return (
        
        <>
            <div className={"title " + (props.platform ? props.platform.platform_raw : "")}>RATINGS</div>
            <div className="ShowRatingsPage">
                {!authReady && <p><br/>...</p>}
                {authReady && !userState && 
                    <>
                        <p><br/><br/>You must be logged in<br/><br/></p>
                        <p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link></p>
                    </>}
                {authReady && userState && 
                <>
                    <h2>Subject</h2>
                    <InfoPane 
                        {...props} 
                        element={subject}
                        showLinks={false} 
                        showExploreButton={true} 
                        showDiscussButton={true} 
                        onRate={loadData}
                        className="infoDiscussPane"/>
                </>
                        

                }

                <br/>
                <h2>Ratings</h2>
                
                <div className="box">
                    <table className="padding-table-columns">
                        <tbody>
                            <tr>
                                <th>Username</th>
                                <th>Points</th>
                                <th>Profile</th>
                                <th>Time original rating</th>
                                <th>Time last update</th>
                            </tr>

                            {authReady && userState && ratings.map(rating => 
                            <tr key={"rating-" + rating.username + rating.label + rating.time}>
                                <td>{rating.username}</td>
                                <td>{rating.contributionPoints}</td>
                                <td>{rating.profile}</td>
                                <td>{rating.time ? new Date(rating.time).toLocaleDateString(undefined, {month: 'short', year: 'numeric', day: 'numeric'}) + " " + new Date(rating.time).toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'}) : "?"}</td>
                                <td>{rating.updateTime ? new Date(rating.updateTime).toLocaleDateString(undefined, {month: 'short', year: 'numeric', day: 'numeric'}) + " " + new Date(rating.updateTime).toLocaleTimeString(undefined, {hour: 'numeric', minute: 'numeric'}) : "?"}</td>
                            </tr>
                        )
                        }
                        </tbody>
                    
                    
                    </table> 
                </div>
                
                
            </div>
            <br/><br/><br/><br/><br/><br/><br/><br/>

        </>
        
    )
}

export default ShowRatingsPage;