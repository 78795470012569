import React from 'react';
import CytoscapeComponent from 'react-cytoscapejs';
import cytoscape from 'cytoscape';
import CytoscapeViewUtilities from 'cytoscape-view-utilities'
import layoutUtilities from 'cytoscape-layout-utilities';
import cxtmenu from 'cytoscape-cxtmenu';
import fcose from 'cytoscape-fcose';

const menubackgroundcolor = "#88A36C"
const menubackgroundselectedcolor = "#78191A"        
const conceptcolor = "#A0CCD4"
const conceptbordercolor = "#88A36C"
const highlightedconceptcolor = "#88A36C"
const highlightedconceptbordercolor = "#88A36C"
const selectedconceptbordercolor = "#78191A"
const selectedconceptcolor = "#78191A"
const edgecolor = "#DEDDDD"
const selectededgecolor = "#78191A"


/**
import * as wjInput from '@grapecity/wijmo.react.input';
import '@grapecity/wijmo.styles/wijmo.css';
 */

class Explorer extends React.Component {
    constructor(props) {
        super(props);



        this.hideLoader = props.hideLoader;

//        cytoscape.use(CytoscapeCoseBilkent);
        cytoscape.use(CytoscapeViewUtilities);
        cytoscape.use(layoutUtilities); // register extension
        cytoscape.use( cxtmenu );
        cytoscape.use( fcose );


        this.load = this.load.bind(this)
        this.selectAndExpand = this.selectAndExpand.bind(this)
        this.onSelect = this.onSelect.bind(this)
        this.toggleRelevantElement = this.toggleRelevantElement.bind(this)
        this.selectNeighbors = this.selectNeighbors.bind(this)
        this.hideNeighbors = this.hideNeighbors.bind(this)
        this.visualizeRelevantNodes = this.visualizeRelevantNodes.bind(this)
        this.initialize = this.initialize.bind(this)
        this.hideBelowConfidenceLevel = this.hideBelowConfidenceLevel.bind(this)
        this.savePositions = this.savePositions.bind(this)
        this.loadShapes = this.loadShapes.bind(this)

        this.hideElement = this.hideElement.bind(this)
        this.setshowAllAgain = this.setshowAllAgain.bind(this)
        this.name = "Explorer"
        this.isInitialized = false
        this.doRecenter = false
        this.stylesheetLoaded = false

        this.stylesheet = [
            // the stylesheet for the graph
            {
                selector: "node", 
                style: {
                    "fontSize": 20,
                    "background-color": conceptcolor,
                    label: "data(label)",
                    "min-zoomed-font-size": 6,

                    "border-color": conceptbordercolor,
                    "border-width": 1
                }
            },

            {
                selector: "edge",
                style: {
                    width: 3,
                    label: "data(role)", // maps to data.role
                    "fontSize": 4,
                    //"text-margin-x": "3px",
                    //"text-margin-y": "3px",
                    "edge-text-rotation": "autorotate",
                    "line-color": edgecolor,
                    "target-arrow-color": edgecolor,
                    "target-arrow-shape": "triangle",
                    "curve-style": "unbundled-bezier",
                    "min-zoomed-font-size": 6
                }
            },
            {
        
                selector: 'node[shape="ellipse"]', 
                style: {
                    'shape': 'ellipse'
                }
            },
            {
        
                selector: 'node[shape="triangle"]', 
                style: {
                    'shape': 'triangle'
                }
            },
            {
        
                selector: 'node[shape="round-triangle"]', 
                style: {
                    'shape': 'round-triangle'
                }
            },
            {
        
                selector: 'node[shape="rectangle"]', 
                style: {
                    'shape': 'rectangle'
                }
            },
            {
        
                selector: 'node[shape="round-rectangle"]', 
                style: {
                    'shape': 'round-rectangle'
                }
            },
            {
        
                selector: 'node[shape="bottom-round-rectangle"]', 
                style: {
                    'shape': 'bottom-round-rectangle'
                }
            },
            {
        
                selector: 'node[shape="cut-rectangle"]', 
                style: {
                    'shape': 'cut-rectangle'
                }
            },
            {
        
                selector: 'node[shape="barrel"]', 
                style: {
                    'shape': 'barrel'
                }
            },
            {
        
                selector: 'node[shape="rhomboid"]', 
                style: {
                    'shape': 'rhomboid'
                }
            },
            {
        
                selector: 'node[shape="diamond"]', 
                style: {
                    'shape': 'diamond'
                }
            },
            {
        
                selector: 'node[shape="round-diamond"]', 
                style: {
                    'shape': 'round-diamond'
                }
            },
            {
        
                selector: 'node[shape="pentagon"]', 
                style: {
                    'shape': 'pentagon'
                }
            },
            {
        
                selector: 'node[shape="round-pentagon"]', 
                style: {
                    'shape': 'round-pentagon'
                }
            },
            {
        
                selector: 'node[shape="hexagon"]', 
                style: {
                    'shape': 'hexagon'
                }
            },
            {
        
                selector: 'node[shape="round-hexagon"]', 
                style: {
                    'shape': 'round-hexagon'
                }
            },
            {
        
                selector: 'node[shape="concave-hexagon"]', 
                style: {
                    'shape': 'concave-hexagon'
                }
            },
            {
        
                selector: 'node[shape="heptagon"]', 
                style: {
                    'shape': 'heptagon'
                }
            },
            {
        
                selector: 'node[shape="round-heptagon"]', 
                style: {
                    'shape': 'round-heptagon'
                }
            },
            {
        
                selector: 'node[shape="octagon"]', 
                style: {
                    'shape': 'octagon'
                }
            },
            {
        
                selector: 'node[shape="round-octagon"]', 
                style: {
                    'shape': 'round-octagon'
                }
            },
            {
        
                selector: 'node[shape="star"]', 
                style: {
                    'shape': 'star'
                }
            },
            {
        
                selector: 'node[shape="tag"]', 
                style: {
                    'shape': 'tag'
                }
            },
            {
        
                selector: 'node[shape="round-tag"]', 
                style: {
                    'shape': 'round-tag'
                }
            },
            {
        
                selector: 'node[shape="vee"]', 
                style: {
                    'shape': 'vee'
                }
            },
        ]


        this.state = {
            viewUtilityOptions: {
                highlightStyles: [
                    {//highlighted only  "#ce7c2d","background-color":  "#6f867f", "border-width": 3,  "line-color": "#6f867f",
                        node: { 
                            "border-color": highlightedconceptbordercolor,"background-color":  highlightedconceptcolor, "border-width": 1,
                        },
                        edge: {
                            "line-color": edgecolor,
                            "source-arrow-color": edgecolor,
                            "target-arrow-color": edgecolor,
                            
                            width: 1
                        }
                    },
                    {//highlighted and selected  "border-color": "#ce7c2d","background-color":  "#6f867f", "border-width": 3,
                        node: { 
                            "border-color": selectedconceptbordercolor,"background-color":  selectedconceptcolor, "border-width": 20, "fontSize": 30
                        },
                        edge: {
                            "line-color": selectededgecolor,
                            "source-arrow-color": selectededgecolor,
                            "target-arrow-color": selectededgecolor,
                            
                            width: 1
                        }
                    }
                ],
                selectStyles: {
                    node: {
                        "border-color": selectedconceptbordercolor,
                        "border-width": 3,
                        "background-color": selectedconceptcolor,
                        "overlay-color": selectedconceptcolor,
                    },
                    edge: {
                        "line-color": selectededgecolor,
                        "source-arrow-color": selectededgecolor,
                        "target-arrow-color": selectededgecolor,
                        width: 3
                    }
                },
                setVisibilityOnHide: false, // whether to set visibility on hide/show
                setDisplayOnHide: true, // whether to set display on hide/show
                zoomAnimationDuration: 1500, // default duration for zoom animation speed
                neighbor: function (ele) {
                    return ele.closedNeighborhood();
                },
                neighborSelectTime: 500,
                lassoStyle: { lineColor: "#d67614", lineWidth: 3 }, // default lasso line color, dark orange, and default line width
                htmlElem4marqueeZoom: "", // should be string like `#cy` or `.cy`. `#cy` means get element with the ID 'cy'. `.cy` means the element with class 'cy'
                marqueeZoomCursor: "se-resize", // the cursor that should be used when marquee zoom is enabled. It can also be an image if a URL to an image is given
                isShowEdgesBetweenVisibleNodes: true // When showing elements, show edges if both source and target nodes become visible
            },
            showAll:true
        };

        console.log("Explorer -- props.token",props.token)
        
    }

    layoutProperties = {
        name: "fcose", 
        idealEdgeLength: edge => 50, 
        avoidOverlap: true,
        nodeDimensionsIncludeLabels: true,
    }

    async loadShapes() {
        console.log("Explorer -- loading shapes")
        if(this.props.token) {

                
            await fetch('/.netlify/functions/load-concepttypes', 
                {
                    method: 'POST',
                    headers: this.props.token && {
                    Authorization: 'Bearer ' + this.props.token
                    },              
                    body: JSON.stringify({})
                })
            .then(response => {
                if(!response.ok) {
                    throw Error('You must be logged in to view this content')
                }
        
                console.log("returning response promise")
                return response.json()
            })
            .then(response => {
                if(response) {
                    console.log("load-concepttypes response --", response)

                    var jsonstyle = this.cy.style().json()


                    response.forEach(r => {
                        console.log("Explorer -- loading shapes -- push ", r)
                        jsonstyle.push(
                            {
                                selector: 'node[nodeType="' + r.label + '"]', 
                                style: {
                                    'shape': r.shapeLabel
                                }
                            }
                        )
                    })

                    // update the "json" object

                    this.cy.style().clear().fromJson(jsonstyle).update()

                    console.log("Explorer -- stylesheet",this.cy.style())
                }
            })
            .catch(err => {
                console.error(err);
            })

        }

    }

    setshowAllAgain() {
        console.info("setShowAllAgain triggered");
        this.setState({showAll: true});
    }


    toggleRelevantElement(clickedElement) {
        console.info("toggleRelevantElement called ", clickedElement);

        //this.props.controller.setSelectedElement(clickedElement.data().id);
        if(clickedElement.data().type === "concept") {
            this.props.toggleRelevant(clickedElement.data().dbid);
        }
    }

    selectAndExpand(clickedElement) {
        console.info("CLICKED", clickedElement.data().label, clickedElement.data().type, clickedElement.data().dbid, clickedElement.position(), clickedElement.data().nodeType, clickedElement.data().shape)

        //get the biggest x position to move the concept away befor expanding
        /**
        if(clickedElement.data().type === "concept" && !clickedElement.data().isExpanded) {
            let allNodes = this.cy.nodes()
            allNodes = allNodes.not(clickedElement)
            var allXPositions = allNodes.map(c => c.position().x)
            console.log("Explorer.handleOneClick -- all X positions", allXPositions)
            let maxXPos = Math.max(...allXPositions)

            console.log("Explorer.handleOneClick -- max X position", maxXPos)
        }
         */
       
        clickedElement.data().type === "relation" ? 
            this.props.select(clickedElement.data().type, clickedElement.data().dbid) : 
            this.props.selectAndExpand(clickedElement.data().type, clickedElement.data().dbid)
        
    }

    onSelect(clickedElement) {
        console.info("Explorer.onSelect -- triggered", clickedElement.data().label, clickedElement.data().type, clickedElement.data().dbid, clickedElement.position())

        this.props.select(clickedElement.data().type, clickedElement.data().dbid)
    }


    selectNeighbors(clickedNode) {
        clickedNode.unselect()
        clickedNode.connectedEdges().connectedNodes().not(clickedNode).select();
    }

    hideNeighbors(clickedNode) {

        var nodesToHide = clickedNode.connectedEdges().connectedNodes()
        nodesToHide = nodesToHide.not(clickedNode)
        console.log("Explorer.hideNeighbors -- neighborhood", nodesToHide.map((n) => n.data()))
        
        this.props.relevantConcepts.forEach(rc => {
            let relevantConcept = nodesToHide.find(n => parseInt(n.data().dbid) === parseInt(rc.dbid))
            nodesToHide = nodesToHide.not(relevantConcept)
        })

        var connectedNodes = clickedNode.connectedEdges().connectedNodes()

        connectedNodes.forEach(cn => {
            var connectedSecondaryEdges = cn.connectedEdges()
            var secondaryEdge = connectedSecondaryEdges.find(e => e.source() !== clickedNode && e.target() !== clickedNode)
            if(secondaryEdge) {
                nodesToHide = nodesToHide.not(cn)
            }
        })

        let conceptsToRemove = []
        nodesToHide.forEach(nth => {
            conceptsToRemove.push(nth.data())
        })

        console.log("Explorer.hideNeighbors -- conceptsToRemove", conceptsToRemove)
        this.props.removeConcepts(conceptsToRemove, clickedNode.data())

   
    }

    hideElement(clickedElement) {
        if(clickedElement.data().type === "concept") {
            this.props.removeConcepts([clickedElement.data()])
        }
        else {
            this.props.removeRelations([clickedElement.data()])
        }
    }


    visualizeRelevantNodes() {
        this.api.removeHighlights(this.cy.nodes());
        var relevantElementsArray = this.props.relevantConcepts;
        var allElements = this.cy.nodes();
        var relevantElements = this.cy.collection();
        for (let r of relevantElementsArray) {
            console.info("relevant element: " + r.label);
            var match = allElements.find(a => a.data().dbid == r.dbid)
            console.info("match: " + match);
            if(match) {
                relevantElements = relevantElements.add(match);
                match.data().relevant = true;
            }
        }
        console.info("RELEVANT ITEMS FOUND: " + relevantElements.length);
        this.api.highlight(relevantElements);

        
    }

    initialize() {
        if(!this.isInitialized) {
            console.log("Explorer.initialize -- GOING TO INITIALIZE");
            // the default values of each option are outlined below:
 
            this.isInitialized = true
        }
        
            
    }

    savePositions(cy) {
        if(cy && cy.nodes()) {
            let newFixedConstraintArray = []
            
            cy.nodes().forEach(n => {
                newFixedConstraintArray.push({nodeId: n.id(), position: n.position()});
                
            })

            //localStorage.setItem("ExplorePage.nodePositions", JSON.stringify(newFixedConstraintArray))
    
            let historyLS = localStorage.getItem("ExplorePage.history")
    
            if(historyLS) {
                let history = JSON.parse(historyLS)
                console.log("Explore.savePositions -- storing new fixed positions for position " + history.position, newFixedConstraintArray)

                if(history.history && history.position) {
                    let currentHistory = history.history[history.position]
                    currentHistory = {
                        ...currentHistory,
                        nodePositions: newFixedConstraintArray
                    }
                    history.history[history.position] = currentHistory
                    localStorage.setItem("ExplorePage.history", JSON.stringify(history))
                }
            }
        }


    }

    getPositions() {
        let historyLS = localStorage.getItem("ExplorePage.history")
        //console.log("Explorer.getPositions -- historyLS", historyLS)
        if(historyLS) {
            let history = JSON.parse(historyLS)
            if(history && history.history && history.position) {
                console.log("Explorer.getPositions -- current history position: " + history.position)
                console.log("Explorer.getPositions -- current history nodepositions", history.history[history.position].nodePositions)
                let currentHistory = history.history[history.position]
                if(currentHistory.nodePositions) {
                    let fixedNodesLocalStorage = currentHistory.nodePositions
                    let fixedConstraintArray = []

                    if(this.cy && this.cy.nodes()) {
            
                        this.cy.nodes().forEach(n => {
                            let positionInLocalStorage = fixedNodesLocalStorage.find(nls => nls.nodeId === n.id())
                            if(positionInLocalStorage && !(positionInLocalStorage.position.x === 0 && positionInLocalStorage.position.y === 0)) {
                                fixedConstraintArray.push({
                                    ...positionInLocalStorage,
                                    position: {
                                        x: positionInLocalStorage.position.x,
                                        y: positionInLocalStorage.position.y - 12 //for some reason with every relayout, the nodes shift 12 pixels down, so we correct for it here
                                    }
                                })
                            }
                            
                        });

                        console.log("Explorer.getPositions -- returning:", fixedConstraintArray)

                        this.cy.resize() //the correction makes cy not know its position

                        return fixedConstraintArray
                    }
                }
            }
        }

        return []
    }

    hideBelowConfidenceLevel() {
        var elementsToHide = this.cy.elements().filter(e => (e.data().confidencePoints ? e.data().confidencePoints : 0) < this.props.confidencePointsLimit)
        this.api.show(this.cy.elements())
        this.api.hide(elementsToHide)
    }

    load(cy) {
        console.log("Explorer.load -- cy updated?",this.cy !== cy, this.cy, cy);
        console.log("Explorer.load -- elements",this.cy ? this.cy.elements() : null);
        console.log("Explorer.load -- initialized? ", this.isInitialized)
        this.cy = cy;

        let firstLoad = false
        if(!this.isInitialized || this.cy.elements().length === 0) {
            firstLoad = true
            this.doRecenter = true
            this.initialize();

        }


        console.log("Explorer.load -- this.cy elements on start of load", this.cy.nodes(), this.cy.edges())

        this.api = this.cy.viewUtilities(this.state.viewUtilityOptions)
        this.hideBelowConfidenceLevel()


        let fixedConstraintArray = this.getPositions()
        
        console.log("Explorer.load -- OUTER LAYOUT START")
        this.cy.layout(
            {
                ...this.layoutProperties,
                fixedNodeConstraint: fixedConstraintArray,
                fit: this.doRecenter, 

                stop: () => {
                    console.log("Explorer.load -- first layout stopped")
                    
                    if(firstLoad && !this.props.overview) { 
                        //for some reason, first time the fixedNodeConstraint is not taken into account, so we need to run the layout again
                        //for some reason it IS taken into account when it is an overview... so not redoing it then because for another bizar reason it then messes up hte layout
                        console.log("Explorer.load -- firstLoad so rerunning layout")
                        fixedConstraintArray = this.getPositions()

                        console.log("Explorer.load -- INNER LAYOUT START")

                        try {
                            this.cy.layout(
                                {
                                    ...this.layoutProperties,
                                    fixedNodeConstraint: fixedConstraintArray,
                                    fit: true,
                                    
                                    stop: () => {
                                        console.log("Explorer.load -- second layout stopped")
                                        this.savePositions(this.cy)
                                  }
                                }
                                ).run()
                        } catch (e) {
                            console.log("Explorer.load -- error when layouting", e)
                        }

                    }

                    else {
 
                        this.savePositions(this.cy)
                    }
                                   
                }
            }).run();

        

        //this.layout.run();

        this.cy.removeAllListeners();
        this.cy.elements().removeAllListeners();

        this.cy.elements().on("oneclick, onetap", (e) => {
            var clickedNode = e.target
            this.onSelect(clickedNode)
            //this.handleOneClick(clickedNode)
            console.info("------------oneclick called",e);
        });

        this.cy.elements().on("dblclick, dbltap", (e) => {
            var clickedNode = e.target;
            this.selectAndExpand(clickedNode)
           // this.toggleRelevantElement(clickedNode);
            console.info("dblclick called",e);
        });

        this.cy.elements().on('drag', (e) => {
            this.savePositions(this.cy)
        })

        //this.cy.elements().on("mouseover", (e) => console.info("Mouse Over"));
    

       
     
        if(this.props.selected) {
            console.info("Explorer.load -- Resetting selection of cytoscape");
            this.cy.elements().unselect();
            var selectedElements = this.cy.collection();

            console.info("Explorer.load -- selected element: ", this.props.selected);
            let selected = this.cy.elements().find(ce => parseInt(ce.data().dbid) === parseInt(this.props.selected.dbid));
            if(selected) {
                selectedElements = selectedElements.add(selected);
                
            }
            

            console.info("Explorer.load -- SELECTED ELEMENTS: " + selectedElements.length);
            //this.api.show(selectedElements);
            //this.api.showHiddenNeighbors(selectedElements);
            selectedElements.select();
        }

        this.visualizeRelevantNodes();

        console.log("Explorer.load -- edges in graph", this.cy.edges())

        if(this.menu) this.menu.destroy();
        let defaults = {
            menuRadius: function(ele){ return 100; }, // the outer radius (node center to the end of the menu) in pixels. It is added to the rendered size of the node. Can either be a number or function as in the example.
            selector: 'node, edge', // elements matching this Cytoscape.js selector will trigger cxtmenus
            commands: [ // an array of commands to list in the menu or a function that returns the array
            
            { // example command
                //fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
                content: 'highlight', // html/text content to be displayed in the menu
                contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                select: this.toggleRelevantElement,
                enabled: true // whether the command is selectable
            },
            { // example command
                //fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
                content: 'select', // html/text content to be displayed in the menu
                contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                select: this.onSelect,
                enabled: true // whether the command is selectable
            },
            /**
            { 
                //fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
                content: 'select neighbors', // html/text content to be displayed in the menu
                contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                select: this.selectNeighbors,
                enabled: true // whether the command is selectable
            },
             */
            { 
                //fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
                content: 'collapse', // html/text content to be displayed in the menu
                contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                select: this.hideNeighbors,
                enabled: true // whether the command is selectable
            },
            { 
                //fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
                content: 'expand', // html/text content to be displayed in the menu
                contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                select: this.selectAndExpand,
                enabled: true // whether the command is selectable
            },
            { 
                //fillColor: 'rgba(200, 200, 200, 0.75)', // optional: custom background color for item
                content: 'hide', // html/text content to be displayed in the menu
                contentStyle: {}, // css key:value pairs to set the command's css in js if you want
                select: this.hideElement,
                enabled: true // whether the command is selectable
            }
            
            ], // function( ele ){ return [ /*...*/ ] }, // a function that returns commands or a promise of commands
            fillColor: menubackgroundcolor, // the background colour of the menu
            transparency: 0.75,
            activeFillColor: menubackgroundselectedcolor, // the colour used to indicate the selected command
            activePadding: 20, // additional size in pixels for the active command
            indicatorSize: 24, // the size in pixels of the pointer to the active command, will default to the node size if the node size is smaller than the indicator size, 
            separatorWidth: 3, // the empty spacing in pixels between successive commands
            spotlightPadding: 4, // extra spacing in pixels between the element and the spotlight
            adaptativeNodeSpotlightRadius: false, // specify whether the spotlight radius should adapt to the node size
            minSpotlightRadius: 24, // the minimum radius in pixels of the spotlight (ignored for the node if adaptativeNodeSpotlightRadius is enabled but still used for the edge & background)
            maxSpotlightRadius: 38, // the maximum radius in pixels of the spotlight (ignored for the node if adaptativeNodeSpotlightRadius is enabled but still used for the edge & background)
            openMenuEvents: 'cxttapstart taphold', // space-separated cytoscape events that will open the menu; only `cxttapstart` and/or `taphold` work here
            itemColor: 'white', // the colour of text in the command's content
            itemTextShadowColor: 'transparent', // the text shadow colour of the command's content
            zIndex: 9999, // the z-index of the ui div
            atMouse: false, // draw menu at mouse position
            outsideMenuCancel: false // if set to a number, this will cancel the command if the pointer is released outside of the spotlight, padded by the number given 
        }

        this.menu = this.cy.cxtmenu( defaults )
    }

    componentDidMount() {
        console.info("component mounted");

        if(this.cy) {
            this.cy.resize()
            //this.cy.center()
            this.cy.fit()

            this.savePositions(this.cy)
        }

        /**
        window.addEventListener('resize', () => {
            console.log("Explorer.componentDidMount -- resize event listener triggered")    
            this.cy.resize()
        })
         */


         
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      }


    componentDidUpdate() {
        console.info("Explorer.componentDidUpdate -- triggered")

        if(this.cy) {

            if(this.doRecenter) {
                console.log("Explorer.componentDidUpdate -- dorecenter")
                //this.cy.center()
                this.cy.fit() 
                
                this.doRecenter = this.cy.elements().length === 0 //recenter again next time if no elements yet
            }

            this.delay(1000).then(() => {
                console.info("Explorer.componentDidUpdate -- resizing")
                this.cy.resize()
            } )
            

            this.savePositions(this.cy)

        }

    }

    render() {

        try {

            console.info("entering render of explorer");


            console.log("Explorer.render -- elements", this.props.concepts, this.props.relations)
    
            var allElements = this.props.concepts.map((data) => ({group: "nodes", data: {...data, id: "N" + data.dbid}}));
            allElements = allElements.concat(this.props.relations.map((data) => ({group: "edges", data: {...data, id: "E" + data.dbid}})));
    
            console.log("Explorer.render -- allElements passed to cy", allElements)
    
            //if(this.cy) console.log("Explorer.render -- this.cy.elements", this.cy.elements())
    
            //let fixedConstraintArray = this.getPositions()
            const layout = {
                ...this.layoutProperties,
                //fixedNodeConstraint: fixedConstraintArray
            }
    
            console.info("is initialized? ", this.isInitialized)
        
    
            
            console.log("Explorer.render -- stylesheet", this.stylesheet)
    
    
            var cytoscape = <CytoscapeComponent 
    
            elements={allElements}
            layout={layout}
            stylesheet={this.stylesheet}
            style={{ width: '100%', height: '600px', display: "block" }}
            zoom={1}
            pan={{ x: 0, y: 0 }}
            zoomingEnabled={true}
            userZoomingEnabled={true}
            panningEnabled={true}
            userPanningEnabled={true}
            boxSelectionEnabled={true}
            selectionType="single"
            touchTapThreshold={8}
            desktopTapThreshold={4}
            autolock={false}
            autoungrabify={false}
            autounselectify={false}
            multiClickDebounceTime={false}
            //headless={false}
            styleEnabled={true}
            hideEdgesOnViewport={false}
            textureOnViewport={false}
            motionBlur={false}
            motionBlurOpacity={0.2}
            pixelRatio="auto"
            cy={this.load}
            />
                
            
            /**
            this.stylesheet.push(
                {
                    selector: 'node[nodeType="' + "Symptom" + '"]', 
                    style: {
                        'shape': 'diamond'
                    }
                }
            )
             */

            return (
                    
                <div className="box" onClick={() => {
                        if(this.cy) {
                            console.log("Explorer.return -- clicked in box")
                            this.cy.resize()
                        }
                            
                    }} onTouchStart={() => {
                        if(this.cy) {
                            console.log("Explorer.return -- touchstart in box")
                            this.cy.resize()
                        }
                        }}>                
                    
                    
                     <div className="toolbox">
                        <button className="btnsmall" onClick={() => {
                            this.cy.fit()
                            this.cy.center()
                            //this.savePositions()
                            }} title="Center"><i className="glyphicon glyphicon-screenshot"/></button>    
                        {this.props.loading ? <p>Loading...</p> : <></>}
                    </div>
                    
                    {cytoscape}
                </div>
    
            
     
            );
    
        } catch (e) {
            console.log("Explorer.render --- error", e)

            return (
                <p>Apologies, your graph no longer seems valid. Better hit the trash button and start over.</p>
            )
        }


        
       
    }
}

export default Explorer;