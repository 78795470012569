import React, { useState, useEffect, useContext } from 'react';


/**
 * 
 * @param {id} props 
 * @returns 
 */
function DataFilterCheckbox (props) {

    console.info("DataFilterCheckbox render triggered", props);

    let filterSet = props.filterSet
    let onChange = props.onChange

    Object.entries(filterSet).forEach(([filter, value]) => {
        console.log("DataFilterCheckbox", filter, value)
    })


    return (
        
        <div className={"box"}>
           <h2>{props.title}</h2>
           <br/>
           {Object.entries(filterSet).map(([filter, value]) => {
                return (
                    <div key = {"checkbox."+filter}>
                        <input
                            key = {"checkbox."+filter}
                            checked = {value}
                            type = "checkbox"
                            onChange = {(e) => {
                                filterSet[filter] = e.target.checked
                                onChange(filterSet)
                            }}
                        />
                        {" "} {filter}
                        <br/>
                    </div>
                )
            
            
            
        })}

        </div>
        
    );    

}

export default DataFilterCheckbox;