import { Link } from "react-router-dom"; 
import AuthContext from '../stores/authContext'
import React, { useState, useEffect, useContext } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Signup from "../components/Signup";
import Subscribe from "../components/Subscribe";

const Home = (props) => {

    let navigate = useNavigate()

    const {userState, authReady, refreshUser, recoverUser, acceptInvite, confirmUser} = useContext(AuthContext)
    const [data, setData] = useState({labels: [], datasets: []})
    const [landingPageImg, setLandingPageImg] = useState();
    const [homeMessage, setHomeMessage] = useState("");
    const [homePlatform, setHomePlatform] = useState("");
    const [statusMessage, setStatusMessage] = useState("");

    useEffect(() => {
        if(props.platform && props.platform.platform) {
            let landingpageimages = props.platform ? props.platform.landingpageimgs : []
            console.log("Home -- landingpageimges", props.platform, landingpageimages)
            let randomIndex = landingpageimages ? Math.floor(Math.random() * landingpageimages.length) : -1
            let randomImage = randomIndex >= 0 ? landingpageimages[randomIndex] : "integrativemedicinebubbles.jpg"
            setLandingPageImg(randomImage)
    
            console.log("homemsg", )
            const homemsg = props.platform && props.platform.home_message && props.platform.home_message[props.language] ? props.platform.home_message[props.language].split('\n').map((line, index) => {
                return <React.Fragment key={index}>{line}<br/><br/></React.Fragment>
              }) : ""
        
            setHomeMessage(homemsg)
            setHomePlatform(props.platform.platform)
        }

    }, [props.platform, props.language])


    useEffect(() => {

        if(window.location.hash) {
            const token_recovery = window.location.hash.split('recovery_token=')[1]
            if(token_recovery) {
                console.log('Home.useEffect -- recover_token', token_recovery)
                navigate("/password-reset")
                recoverUser(token_recovery, () => {
                    //navigate("/password-reset")
                })
            }


            const token_confirmation = window.location.hash.split('confirmation_token=')[1]
            if(token_confirmation) {
                console.log('Home.useEffect -- token_confirmation', token_confirmation);
                props.language === "en" && setStatusMessage("Confirming your email address...");
                props.language === "es" && setStatusMessage("Confirmando tu dirección de correo electrónico...");
                confirmUser(token_confirmation)
                navigate("/")
            }

            const token_invite = window.location.hash.split('invite_token=')[1]
            if(token_invite) {
                console.log('Home.useEffect -- token_invite', token_invite)
                navigate("/accept-invite/#invite_token=" + token_invite)
            }
        }


        
        fetch('/.netlify/functions/load-stats', {
            method: 'POST',
            body: JSON.stringify({})
        })
        .then(response => {
            return response.json()
        })
        .then(response => {
            console.log("home.useeffect -- response",response)
            let totalConcepts = response.totalConceptsBefore ? response.totalConceptsBefore : 0
            let totalRelations = response.totalRelationsBefore ? response.totalRelationsBefore : 0
            let _labels = []
            let _conceptsData = {
                label: "Concepts",
                data: [],
                backgroundColor: '#88A36C',
            }
            let _relationsData = {
                label: "Relations",
                data: [],
                backgroundColor: '#4B7C8F   ',
            }

            response.perWeek.forEach(w => {
                _labels.push(w.year + "-" + w.week)
                totalConcepts = w.conceptsAmount ? w.conceptsAmount + totalConcepts : totalConcepts
                totalRelations = w.relationsAmount ? w.relationsAmount + totalRelations : totalRelations
                _conceptsData.data.push(totalConcepts)
                _relationsData.data.push(totalRelations)

            })

            console.log("Home -- relationsdata",_relationsData)

            setData({labels: _labels, datasets: [_conceptsData, _relationsData]})
        })
        .catch(error => {
            console.error(error)
        })
 
    }, [])



    useEffect(() => {
        console.log("Home.useEffect -- user:", userState)

        if(authReady) {

            let headers = {}
            if(userState && userState.token) {
                refreshUser()
                setStatusMessage("")
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }

            const searchParams = new URLSearchParams(window.location.search);
            const source = searchParams.get("source");
            const sourceRef = searchParams.get("source_ref");
            const adId = searchParams.get('ad_id');
            const fbclid = searchParams.get('fbclid');
            const mmId = searchParams.get('mm_id');
            const instaStoryId = searchParams.get('insta_story_id');
            const linkedInId = searchParams.get('linkedIn_id');
            const metaAdId = searchParams.get('meta_ad_id');
    
            console.log("Home.useEffect  window.location.search ", window.location.search, source);

            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: "home",
                    comment: "",
                    source: source ? source : (adId || metaAdId)  ? "meta_ad" : (fbclid ? "fb" : (mmId ? "marketingmail" : (instaStoryId ? "insta_story" : (linkedInId ? "linkedIn" : "")))),
                    ad_id: adId,
                    fbclid: fbclid,
                    mm_id: mmId,
                    insta_story_id: instaStoryId,
                    linkedIn_id: linkedInId,
                    sourceRef: sourceRef ? sourceRef : metaAdId ? metaAdId : adId ? adId : "" + mmId ? mmId : "" + instaStoryId ? instaStoryId : "" + fbclid ? fbclid : "" + linkedInId ? linkedInId : "",
                    params: window.location.search
                })
            })    


        }
    }, [authReady, userState, window.location])

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );

      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            //display: true,
            //text: 'How the knowledge of NinaToka grows...',
          },
        },
      };

      

    

    return (
    <div className={"homebodywrapper " + props.screenMode}>

       
            <div className={"homePage " +  (props.screenMode)}>

                <img src={"/images/" + landingPageImg} id={"human" +  (props.screenMode)}/>

                <div id={"hometextpagepart" +  (props.screenMode)}>


                {authReady && 
                <div id={"hometext" +  (props.screenMode)}>
                    
                    <div className={"platformhome " +  (props.platform ? props.platform.platform_raw : "")}>{homePlatform}</div>

                    {userState && props.language === "en" && <><p>Welcome {userState.username},</p><br/></>}
                    {userState && props.language === "es" && <><p>Bienvenida, {userState.username},</p><br/></>}
                    {statusMessage && <p>{statusMessage}<br/><br/></p>}
                    <p>{homeMessage}</p>
                    
                    
                
                    <br/>

                    {!authReady && <p>...</p>}
                    {authReady && userState && 
                    <>
                        {props.language === "en" && <>
                            
                            <p>Go to <Link to="explore" onClick={(e) => {props.setActive("explore")}}>Explore</Link> to start your discovery!</p>

                            <p>Go to <Link to="overviews" onClick={(e) => {props.setActive("overviews")}}>Pathways & Overviews</Link> to discover preprepared and narrated overviews.</p>

                            <p>You can rate content up or down and add comments if you agree or disagree.</p>

                            <br/><br/><p>The team of {props.platform ? props.platform.platform : ""} wishes you lots of successful treatments and healthy clients!</p>
                            </>
                        }
                        {props.language === "es" && <>
                            <p>Ve a <Link to="explore" onClick={(e) => {props.setActive("explore")}}>Explorar</Link> para comenzar tu descubrimiento!</p>

                            <p>Ve a <Link to="overviews" onClick={(e) => {props.setActive("overviews")}}>Rutas y perspectivas </Link> para descubrir resúmenes prepreparados y narrados.</p>

                            <p>Puedes calificar el contenido positiva o negativamente y agregar comentarios si estás de acuerdo o en desacuerdo.</p>

                            <br/><p>¡El equipo de {props.platform ? props.platform.platform : ""} te desea muchos tratamientos exitosos y clientes saludables!</p>
                            </>
                        }
                    </>
                
                }
                <div id="authbuttonshome">
                    {authReady && !userState && <p><button className="homebutton" onClick={(e) => {
                        navigate("/login")
                    }}>{props.language === "en" && "Sign in"}{props.language === "es" && "Iniciar sesión"}</button></p>}
                    {authReady && !userState && <p><button className="homebutton" onClick={(e) => {
                        navigate("/signup")
                    }}>{props.language === "en" && "Register"}{props.language === "es" && "Registrarse"}</button></p>}

                </div>

                
                </div>}
                                
            </div>


            </div>

      
        
            
        



        {authReady && (!userState || !userState.hasPro) && 
        <div className={"subscribePage " + props.screenMode}>
            <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titlegetstarted">
            {props.language === "en" && "GET STARTED"}{props.language === "es" && "COMENZAR"}
           
            </div>

            <Subscribe {...props} direction={props.screenMode === "mobile" ? "vertical" : "horizontal"}/>

            
        </div>}



    
            
                    
        {authReady && userState  && 
        <div className={"getstartedpage " + props.screenMode}>

            <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titlegetstarted">
                {props.language === "en" && "GET INTRODUCED"}
                {props.language === "es" && "PRESENTARSE"}
            </div>
            <div className="box">
                <div className={"video " + props.screenMode}>
                    <br/>
                    <div className="video-responsive">
                        
                        <iframe src="https://player.vimeo.com/video/733756577?h=fede5a79b1" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    </div>
                </div>
            </div>


            
        </div>}


        {authReady && userState  && 
            <div className={"knowledgestatuspage " + props.screenMode}>

                <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titleknowledgestatus">
                {props.language === "en" && "HOW OUR KNOWLEDGE EXPANDS"}
                {props.language === "es" && "LA EXPANSIÓN DE NUESTRO CONOCIMIENTO"}

                </div>

                <div className="box">
                    <div className={"knowledgestatuspagecontent " + props.screenMode}>
                        <div id={"knowledgestatustext" + props.screenMode}>
                            {props.language === "en" && "We are constantly updating the database with new knowledge to stay up to date with the latest scientific data, ensuring that you can always work with the most recent insights in integrative medicine."}
                            {props.language === "es" && "Estamos continuamente agregando conocimiento a la base de datos para mantenernos actualizados con los últimos datos científicos, de modo que siempre puedas trabajar con las últimas ideas en medicina integrativa."}
                        </div>

                        <div className={"chart " + props.screenMode}>
                            <Bar options={options} data={data} />
                        </div>
                    </div>
                </div>



                
        </div>}



              
        
    </div>
  )};
  
  export default Home;