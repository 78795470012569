import { useEffect, useState } from 'react'
import {createContext} from 'react'
import GoTrue from 'gotrue-js'

const AuthContext = createContext({
    authReady: false,
    refreshUser: () => {},
    userState: null,
    loginUser: () => {},
    signupUser: () => {},
    forgotPassword: () => {},
    logoutUser: () => {},
    confirmUser: () => {},
    recoverUser: () => {},
    updatePassword: () => {},
    recoverFeedback: "NA"
 })

export const AuthContextProvider = ({children}) => {
    const [authReady, setAuthReady] = useState(false)
    const [userState, setUserState] = useState(null)
    const [recoverFeedback, setRecoverFeedback] = useState(null)

    var url = window.location
    var baseUrl = url.protocol + "//" + url.host
    console.log("AuthContextProvider -- URL", baseUrl)


    const auth = new GoTrue({
        APIUrl: baseUrl + '/.netlify/identity',
        setCookie: true,
      });

    const refreshUser = async (user = auth.currentUser()) => {
        console.log("AuthContextProvider.refreshUser -- user",user)

        const timeStart = performance.now()

        let _user = user


        if(_user) {

            console.log("AuthContextProvider.refreshUSer -- we do have a user", _user)


            if(_user.token && _user.token !== null) {
                console.log("AuthContextProvider.refreshUSer -- we have a token and will attempt to renew it")

                console.log("AuthContextProvider.refreshUSer -- token expiry minutes", (_user.token.expires_at - new Date().getTime())/1000/60)

                const originalToken = "" + _user.token.access_token
    
                const token = await _user.jwt()
                    .then((jwt)=> {
       
                        if(!jwt) {
                            console.log("AuthContextProvider.refreshUSer -- we did not get a token back from jwt")
                            throw Error ("Could not get token")
                        }

                        if(jwt !== originalToken){
                            console.log("AuthContextProvider.refreshUSer -- we got a jwt token back and it is a new one")
                        }

                        let _userState = {
                            ...userState,
                            user: _user,
                            email: _user.email,
                            userRoles: _user.app_metadata.roles,
                            username: _user.user_metadata.full_name ? _user.user_metadata.full_name : "Anonymous user",
                            token: jwt
                        }

                        if(!_userState.memberLoaded) {
                            console.log("AuthContextProvider.refreshUser -- our member was not loaded yet so we are calling loadmember - duration:", (performance.now() - timeStart))

                            return loadMember(_userState)
                        }
                        else {
                            return new Promise((resolve, reject) => {
                                resolve(_userState)
                            })
                        }
                        
                    })
                    .then(userStateWithMemberData => {
                        console.log("AuthContextProvider.refreshUSer -- received loadmember response (if called) - duration", userStateWithMemberData, (performance.now() - timeStart))


                        if(!userState || !userState.memberLoaded || userStateWithMemberData.token !== originalToken) {
                            console.log("AuthContextProvider.refreshUSer -- updating the userstate", userStateWithMemberData)
                            setUserState(userStateWithMemberData)
                        }
                        
                        else{
                            console.log("AuthContextProvider.refreshUSer -- no need to update the userstate", userStateWithMemberData)
                        }

                        return userStateWithMemberData.token
                    })
                    
                    .catch(error => {
                        console.log("AuthContextProvider.refreshUSer -- token renewal failed", error)
                        logoutUser()
                    })
                    .finally(() => {
                        console.log("AuthContextProvider.refreshUSer -- setting authReady")

                        setAuthReady(true)      

                        console.log("AuthContextProvider.refreshUSer -- duration", (performance.now() - timeStart))

                    })

                return new Promise((resolve, reject) => {
                    resolve(token)
                })

            }

            else {
                logoutUser()
                setAuthReady(true)

                console.log("AuthContextProvider.refreshUSer -- duration", (performance.now() - timeStart))

                return

            }
        }

        else {
            logoutUser()
            setAuthReady(true)

            console.log("AuthContextProvider.refreshUSer -- duration", (performance.now() - timeStart))

            return

        }
        
    }


    useEffect(() => {
        if(!userState)
            refreshUser()
    }, [])

    const loginUser = (email, password, callback) => {
        console.log("AuthContextProvider.loginUser -- triggered", email, password)

        var _userState = {}

        auth
            .login(email, password, true)
            .catch((error) => {
                error.breakchain = true
                console.error("AuthContextProvider.loginUser -- errorcatch after login", error)
                callback && callback(undefined, error)
                return error
            })
            .then((response) => {
                if(!response.breakchain) {
                    console.log("AuthContextProvider.loginUser -- login successful", response)

                    _userState = {
                        user: response,
                        email: response.email,
                        userRoles: response.app_metadata.roles,
                        username: response.user_metadata.full_name ? response.user_metadata.full_name : "Anonymous user",
                        token: response.token.access_token,
                    }
    
                    console.log("AuthContextProvider.loginUser -- loadMember called")
                    return loadMember(_userState)                  
                }
                return response
            })
            .then(userStateWithMemberData => {
                if(!userStateWithMemberData.breakchain) {
                    console.log("AuthContextProvider.loginUser -- received loadMember response", userStateWithMemberData)

                    console.log("AuthContextProvider.loginUser -- setting userstate", userStateWithMemberData)
    
                    setUserState(userStateWithMemberData)
                    callback && callback(userStateWithMemberData)
                }
                return userStateWithMemberData
            })
            .catch((error) => {
                if(!error.breakchain) {
                    console.error("Login failed", error)
                    callback && callback(undefined, error)
                }
                return error
            })
            
    }

    const signupUser = (name, email, password, language, callback) => {
        auth.signup(email, password, { full_name: name, language: language })
            .then((response) => {
                console.log('Confirmation email sent', response)
                callback && callback("Success")
            })
            .catch((error) => {
                console.error("Failed to signup", error)
                callback && callback("Failed to signup: " + error)
            })
    }

    const forgotPassword = (email, callback) => {
        auth
            .requestPasswordRecovery(email)
            .then((response) => {
                console.log('Recovery email sent', { response })
                callback && callback("Success")
            })
            .catch((error) => console.log('Error sending recovery mail: %o', error));
    }

    const logoutUser = (callback) => {
        const user = auth.currentUser()
        if(user) {
            user.logout()
            .then(response => {
                console.log("User logged out")
                setUserState( null)
                callback && callback("Success")
            } )
            .catch(error => {
              console.error("Failed to logout user: %o", error);
              setUserState( null)
              throw error;
            });
        }
        else {
            setUserState(null)
            callback && callback("Success")
        }
    }

    const acceptInvite = (token, name, password, callback) => {

        console.log("AuthContextProvider.acceptInvite -- triggered", token, password)

        var _userState = {}

        auth
            .acceptInvite(token, password, true)
            .then((response) => {
                console.log("AuthContextProvider.acceptInvite -- login successful", response)

                _userState = {
                    user: response,
                    email: response.email,
                    userRoles: response.app_metadata.roles,
                    username: name,
                    token: response.token.access_token,
                }

                console.log("AuthContextProvider.acceptInvite -- loadMember called")
                return loadMember(_userState)              

            })
            .then(userStateWithMemberData => {
                console.log("AuthContextProvider.acceptInvite -- received loadMember response", userStateWithMemberData)

                console.log("AuthContextProvider.acceptInvite -- setting userstate", userStateWithMemberData)

                setUserState(userStateWithMemberData)
                callback && callback("Success")

                let headers = {
                    Authorization: 'Bearer ' + _userState.token
                }
    
                fetch('/.netlify/functions/update-member', {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        email: _userState.user.email,
                        name: name
                    })
                }).then(response => {
                    if(!response.ok) {
                        throw Error('AuthContextProvider.acceptInvite -- failed to update name of member',response.errorMessage)
                    }
          
                    return response.json()
                })
                .then(response => {
                    console.log("AuthContextProvider.acceptInvite -- result of updating member's name", JSON.stringify(response), response)
                })
                .catch(err => {
                    console.error(err);
                })
            })
            
            .catch((error) => {
                console.error("Login failed", error)
                callback && callback()
            })
    }


    const recoverUser = (token, callback) => {

        let _userState = {}

        auth
            .recover(token, true)
            .then((response) => {
                console.log('Logged in as %s', JSON.stringify({ response }))

                _userState = {
                    user: response,
                    email: response.email,
                    userRoles: response.app_metadata.roles,
                    username: response.user_metadata.full_name ? response.user_metadata.full_name : "Anonymous user",
                    token: response.token.access_token
                }

                return loadMember(_userState)

            })
            .then(userStateWithMemberData => {
                setUserState(userStateWithMemberData)
                setRecoverFeedback("Complete")
                callback && callback(userStateWithMemberData)
            })
            .catch((error) => {
                console.log('Failed to verify recover token: %o', error)
                setRecoverFeedback("Error")
            } );
    }

    const confirmUser = (token, callback) => {
        if(!userState || !userState.token) {
            let _userState = {}

            auth
            .confirm(token, true)
            .then((response) => {
                console.log('User confirmed', JSON.stringify({ response }));
    
                _userState = {
                    user: response,
                    email: response.email,
                    userRoles: response.app_metadata.roles,
                    username: response.user_metadata.full_name ? response.user_metadata.full_name : "Anonymous user",
                    token: response.token.access_token
                }
                return loadMember(_userState)
    
            })
            .then(userStateWithMemberData => {
                setUserState(userStateWithMemberData)
                callback && callback(userStateWithMemberData)
            })
            .catch((error) => {
              console.log(error);
            });
        }
        else {
            console.log("authContext.confirmUser -- user already has a token", userState);
        }
    }

    const updatePassword = (password, callback) => {
        const user = auth.currentUser()
        if(user) {
            console.log("AuthContextProvider.updatePassword -- user identified", user)
            user.update({password: password})
            .then(response => {
                console.log("AuthContextProvider.updatePassword -- response",response)
                callback && callback("Success")
            })
            .catch(error => {
                console.log(error)
                callback("Failed to save new password")
                throw error
            })
        }
        else {
            console.error("AuthContextProvider.updatePassword -- no user known")
            callback("Failed to save new password. No user known.")
        }
    }
    
    const updateName = (name, callback) => {
        const user = auth.currentUser()
        if(user) {
            console.log("AuthContextProvider.updateName -- user identified", user)
            user.update({user_metadata: {full_name: name}})
            .then(response => {
                console.log("AuthContextProvider.updateName -- response",response)
                callback && callback("Success")
            })
            .catch(error => {
                console.log(error)
                callback("Failed to save new password")
                throw error
            })
        }
        else {
            console.error("AuthContextProvider.updateName -- no user known")
        }
    }
    

    const loadMember = async (_userState) => {

        return fetch('/.netlify/functions/load-members', 
        {
            method: 'POST',
            headers: {
                    Authorization: 'Bearer ' + _userState.token
                },              
            body: JSON.stringify({
                email: _userState.email
            })
        })
        .then(response => {
            if(!response.ok) {
                throw Error('AuthContextProvider.loadMember -- error loading', response)
            }

            console.log("AuthContextProvider.loadMember -- returning member promise")
            return response.json()
        })
        .then(response => {
            console.log("AuthContextProvider.loadMember -- loaded member", response)
            //const basicSubscription = response.length === 1 && response[0].activeSubscriptions && response[0].activeSubscriptions.find(s => s.type.toLowerCase() === "basic") ? true : false
            //const hasPro = response.length === 1 
            //    && response[0].activeSubscriptions 
            //    && response[0].activeSubscriptions.find(s => s.type.toLowerCase() === "pro") ? true : false
            //                   // && response.activeSubscription === "pro" 
            //                   // && response.activeSubscriptionStatus === "active" 
            //                   // && (!response.activeSubscriptionEndDate || new Date(response.activeSubscriptionEndDate) > new Date())
            const notifications = response.length === 1 && response[0] ? response[0].notifications : []

            console.log("AuthContextProvider.loadMember -- new userstate", {
                ..._userState,
                username: response[0].name,
                //basicSubscription: basicSubscription,
                hasPro: response.length === 1 && response[0].hasPro,
                proSubscriptionStatus: response.length === 1 && response[0].proSubscriptionStatus,
                proTrialEnd: response.length === 1 && response[0].proTrialEnd,
                formula: response.length === 1 && response[0].formula,
                memberLoaded: true,
                notifications: notifications,
                pricingTable: response.length === 1 && response[0].pricingTable,
                stripeID: response.length === 1 && response[0].stripeID
            })

            return {
                ..._userState,
                username: response[0].name,
                //basicSubscription: basicSubscription,
                hasPro: response.length === 1 && response[0].hasPro,
                proSubscriptionStatus: response.length === 1 && response[0].proSubscriptionStatus,
                proTrialEnd: response.length === 1 && response[0].proTrialEnd,
                formula: response.length === 1 && response[0].formula,
                memberLoaded: true,
                notifications: notifications,
                pricingTable: response.length === 1 && response[0].pricingTable,
                stripeID: response.length === 1 && response[0].stripeID
            }
        })
        .catch(error => {
            console.log("AuthContextProvider.loadMember -- error", error)
            
            throw error
        })


    }



    const context = { 
        authReady: authReady,
        userState: userState,
        refreshUser: refreshUser,
        loginUser: loginUser,
        signupUser: signupUser,
        forgotPassword: forgotPassword,
        logoutUser: logoutUser,
        confirmUser: confirmUser,
        recoverUser: recoverUser,
        acceptInvite: acceptInvite,
        updatePassword: updatePassword,
        recoverFeedback: recoverFeedback
    }


    console.log("AuthContextProvider -- returning context",context)

    return (
        <AuthContext.Provider value={context}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext