import React, { useEffect, useContext, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";

import AuthContext from '../stores/authContext'
import InfoPane from '../components/InfoPane';
import Comment from '../components/Comment';
import Home from "./Home";

import TextareaAutosize from 'react-textarea-autosize';

function PublicPage (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)
    let { id } = useParams();

    const [subject, setSubject] = useState({})
    const [comments, setComments] = useState([])
    const [myComment, setMyComment] = useState("")
    const [initialized, setInitialized] = useState(false)

    console.info("PublicPage", props, id);


    const loadConcept = async (id) => {
        console.log("PublicPage.loadConcept -- triggered", userState)


        let headers = {}
        const result = await fetch('/.netlify/functions/load-concepts', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                dbid: id
            })
            })
            .then(response => {
                if(!response.ok) {
                    throw Error('PublicPage.loadConcept -- error loading', response)
                }

                console.log("PublicPage.loadConcept -- returning response promise")
                return response.json()
            })
            .catch(err => {
                console.error(err)
                throw new Error("PublicPage.loadConcept -- Could not fetch or process load-concepts: " + err)
            })


        console.log("PublicPage.loadConcept -- result",result)
        if(result && result.length === 1) {
            console.log("PublicPage.loadConcept -- only result",result[0])
            setSubject(result[0])
        }

        
    }



    
    const loadComments = async (type, id) => {
        console.log("PublicPage.loadComments -- triggered")

        let headers = {}

        let body
        if(type === "concept") {
            body = {
                concept: id
            }
        }
        else if(type === "relation") {
            body = {
                masterRelation: id
            }
        }

        const result = await fetch('/.netlify/functions/load-comments', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
            })
            .then(response => {
                if(!response.ok) {
                    throw Error('PublicPage.loadComments -- error loading', response)
                }

                console.log("PublicPage.loadComments -- returning response promise")
                return response.json()
            })
            .catch(err => {
                console.error(err)
                throw new Error("PublicPage.loadComments -- Could not fetch or process load-concepts: " + err)
            })
            
        console.log("PublicPage.loadComments -- result", result)
        setInitialized(true)
        setComments(result)
        
    }


    

    const loadData = () => {
        if(id) {
            
            loadConcept(id)
            
            //loadComments("concept", id)
        }
    }

    useEffect(() => {
        console.log("PublicPage.useEffect -- user:", userState)      

        if(authReady) {
            loadData()
        }
        
    }, [authReady, userState, id])



    useEffect(() => {
        console.log("PublicPage.useEffect -- create-pagevisit:", authReady, userState, subject)

        if(authReady && (subject && subject.label)) {
            console.log("PublicPage.useEffect -- creating pagevisit")
            let headers
            if(userState) {
                refreshUser()
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }
    
            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: ("public/" + id),
                    comment: subject ? subject.label : ""
                })
            })
        }
        
    }, [authReady, userState, subject])





    return (
        
        <>

        

            <div className="DiscussPage">
                
                {authReady &&
                <>
                    <InfoPane 
                        {...props} 
                        element={subject}
                        showLinks={false} 
                        showExploreButton={true} 
                        showDiscussButton={true} 
                        onRate={loadData} 
                        onRateDown={() => {
                            loadComments("concept", id)
                        }} 
                        className="infoDiscussPane" />
                </>
                        

                }

            </div>


            {authReady && 
            <div className="box">
                <h1>Join {props.platform ? props.platform.platform : ""}!!</h1>

                <br/>
                <p>NinatoKa's goal is to support you as a therapist in unravelling the illness pathway from symptoms to cause, and to help you detect potential interventions.</p>
                
                <p>Go to <Link to="explore" onClick={(e) => {props.setActive("explore")}}>Explore</Link> to start your discovery!</p>

                <p>Go to <Link to="learn" onClick={(e) => {props.setActive("learn")}}>Learn</Link> to scroll through newly added data.</p>

                <p>Go to <Link to="contribute" onClick={(e) => {props.setActive("learn")}}>Contribute</Link> to contribute to the Ninatoka database.</p>

                <p>You can rate content up or down and add comments if you agree or disagree.</p>

                
                {!authReady && <p>...</p>}
                {authReady && userState && <p>Happy exploring {userState.username}.</p>}
                {authReady && !userState && <p><Link to="/login">Log in</Link></p>}
                {authReady && !userState && <p><Link to="/signup">Sign up</Link></p>}
            </div>}
            <br/>


        </>
        
    )
}

export default PublicPage;