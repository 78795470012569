import React, { useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import ExplorePage from './pages/ExplorePage';
import Contribute from "./pages/Contribute";
import NoPage from "./pages/NoPage";
import UnderConstructionPage from './pages/UnderConstructionPage';
import LearnPage from './pages/LearnPage';
import AccountPage from './pages/AccountPage';
import ReviewPage from './pages/ReviewPage';
import AdminInsightsPage from './pages/AdminInsightsPage';
import { AuthContextProvider } from './stores/authContext';
import MergeConceptsPage from './pages/MergeConceptsPage'
import AddRolePage from './pages/AddRolePage';
import LoginPage from './pages/LoginPage';
import { useNavigate } from "react-router-dom";
import PasswordReset from './components/PasswordReset';
import SignupPage from './pages/SignupPage';
import DiscussPage from './pages/DiscussPage';
import PublicPage from './pages/PublicPage';
import DiscussionOverviewPage from './pages/DiscussionOverviewPage';
import ShowRatingsPage from './pages/ShowRatingsPage';
import OverviewsPage from './pages/OverviewsPage';
import AcceptInvite from './components/AcceptInvite';
import ManageConceptTypesPage from './pages/ManageConceptTypesPage';
import {Helmet} from "react-helmet";
import "./index.css"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from './stores/authContext'
import ManageRolesPage from './pages/ManageRolesPage';


export default function ScreenSize(props) {
  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
    props.callback(window.innerWidth, window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion])

  return (
      <></>
  )
}

const App = (props) => {


  const [active, setActive] = useState()
  const [platform, setPlatform] = useState({})
  const [screenMode, setScreenMode] = useState("not yet set")
  const [descriptionTag, setDescriptionTag] = useState()
  const [keywordsTag, setKeywordsTag] = useState()
  const {userState, authReady, refreshUser} = useContext(AuthContext)

  const visitorLanguage = navigator.language.split('-')[0];
  console.log("visitor language", navigator.language, visitorLanguage);

  const availableLanguages = ['en', 'es']; // Example list of available languages
  const defaultLanguage = 'en'; // Set a default language
  
  const [language, setLanguage] = useState(() => {
    let languageFromStorage = localStorage.getItem("language");
    return languageFromStorage ? languageFromStorage : (availableLanguages.includes(visitorLanguage) ? visitorLanguage : defaultLanguage);
  })

  console.info("App -- entered");


 

  const setScreenSize = (width, height) => {
    //setScreenMode(width < 800 ? "mobile" : "desktop")
    setScreenMode(width < 800 ? "mobile" : "desktop")
  }

  

  useEffect(() => {
    console.log("Index -- setting screen size", window.innerWidth, window.innerHeight)
    setScreenSize(window.innerWidth, window.innerHeight) 
  }, [window.innerWidth, window.innerHeight])

  useEffect(() => {
    console.log("Index -- fetching platform -- entered useeffect")
    if(!platform.platform) {
      console.log("Index -- fetching platform")
        fetch('/.netlify/functions/platform', {
            method: 'POST',
            body: JSON.stringify({})
        })
        .then(response => {
            return response.json()
        })
        .then(response => {
            console.log("Index -- platform",response)
            setPlatform(response)
        })
    }

  }, [])


   

  var properties = {
    ...props,
    screenMode: screenMode,
    platform: platform,
    setActive: setActive,
    active: active,
    language: language,
    setLanguage: (l) => {
      setLanguage(l);
      localStorage.setItem("language", l);
    }
  }

  console.info("app -- all properties", properties)


  return (
      <>
        <Helmet>
          <title>{platform ? platform.platform : "loading"}</title>
          <meta name="description" content={platform ? platform.home_message : "loading"}/>
          <meta name="keywords" content={platform ? platform.keywords : "loading"}/>
        </Helmet>

        <ToastContainer
          position="bottom-right"
          autoClose={false}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />

      <AuthContextProvider>
          <ScreenSize callback={setScreenSize}/>
          
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Layout {...properties}/>}>
                      <Route index element={<Home {...properties}/>} />
                      <Route exact path="/explore" element={<ExplorePage {...properties}/>} />
                      <Route exact path="/contribute" element={<Contribute {...properties}/>} />
                      <Route exact path="/myconsults" element={<UnderConstructionPage />} />
                      <Route exact path="/learn" element={<LearnPage {...properties} />} />
                      <Route exact path="/discuss-latest" element={<DiscussionOverviewPage {...properties} />} />
                      <Route exact path="/account" element={<AccountPage {...properties} />} />
                      <Route exact path="/review/:type/:id" element={<ReviewPage {...properties}/>} />
                      <Route exact path="/contribute/:type/:from" element={<Contribute {...properties}/>} />
                      <Route exact path="/admininsights" element={<AdminInsightsPage {...properties}/>} />
                      <Route exact path="/mergeconcepts" element={<MergeConceptsPage {...properties}/>} />
                      <Route exact path="/createrole" element={<AddRolePage {...properties}/>} />
                      <Route exact path="/manageconcepttypes" element={<ManageConceptTypesPage {...properties}/>} />
                      <Route exact path="/manageroles" element={<ManageRolesPage {...properties}/>} />

                      <Route exact path="/overviews" element={<OverviewsPage {...properties}/>} />

                      <Route exact path="/explore/:selectedElementType/:selectedElementID" element={<ExplorePage {...properties}/>} />
                      <Route exact path="/explore-overview/:overview" element={<ExplorePage {...properties}/>} />

                      <Route exact path="/login" element={<LoginPage {...properties}/>} />
                      <Route exact path="/signup" element={<SignupPage {...properties}/>} />
                      <Route exact path="/accept-invite" element={<AcceptInvite {...properties}/>} />

                      <Route exact path="/password-reset" element={<PasswordReset {...properties}/>} />

                      <Route exact path="/discuss/:type/:id" element={<DiscussPage {...properties} />} />
                      <Route exact path="/public/:id" element={<PublicPage {...properties} />} />

                      <Route exact path="/ratings/:type/:id" element={<ShowRatingsPage {...properties} />} />

                      <Route exact path="/contribute-dialog" element={<Contribute {...properties} windowmode={"dialog"}/>} />
                      <Route exact path="/contribute-dialog/:type/:from" element={<Contribute {...properties} windowmode={"dialog"}/>} />


                      <Route path="*" element={<NoPage />} />
                      
                      
                  </Route>
              </Routes>
          </BrowserRouter>

      </AuthContextProvider>
      </>
  )
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div className={"app"}>

        <App />
    </div>

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);