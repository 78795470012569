import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'
import AuthContext from '../stores/authContext'
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import LanguageSwitcher from '../components/LanguageSwitcher';

const {PLATFORM} = require("../utils/constants");



const Layout = (props) => {

  let navigate = useNavigate()

  const [menuOpen, setMenuOpen] = useState(false)
  const {userState, authReady, logoutUser} = useContext(AuthContext)

  console.log("Layout -- props",props)

  useEffect(() => {
    userState && userState.notifications && 
    userState.notifications.forEach(n => 

        {
          let toastFunction = toast.info
          if(n.type === "Warning") {
            toastFunction = toast.warn
          }
          toastFunction(n.text, {
            onClose:() => {
              console.log("NOTIFICATION CLOSED")
              fetch('/.netlify/functions/create-notification-seen', 
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify({
                        notification: n.id
                    })
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error('Layout.useEffect -- error logging notification seen', response)
                    }
    
                    else {
                      console.log("Layout.useEffect -- logged notification seen", n.id, n.text)
                    }
                })
                
            }
          })
        }
      )
    
  }, [userState])  


  return (
    <>

        <Menu width={props.screenMode === "mobile" ? '100%' : '350px'} noOverlay disableOverlayClick disableAutoFocus isOpen={menuOpen} onStateChange={(state) => setMenuOpen(state.isOpen)}> 
            <Link className={props.active === "home" ? "menu-item active" : "menu-item"} to="/" onClick={() => {setMenuOpen(false);props.setActive("home")}}><i className="fa fa-home"></i> 
            &nbsp;
            {props.language === "en" && "GET STARTED"}
            {props.language === "es" && "COMENZAR"}
            <hr className="menu-item-split"/></Link>
            {<Link className={props.active === "explore" ? "menu-item active" : "menu-item"} to="/explore" onClick={() => {setMenuOpen(false);props.setActive("explore")}}><i className="glyphicon glyphicon-search"></i> 
            &nbsp;
            {props.language === "en" && "EXPLORE"}
            {props.language === "es" && "EXPLORAR"}
            <hr className="menu-item-split"/></Link>}

            {<Link className={props.active === "overviews" ? "menu-item active" : "menu-item"} to="/overviews" onClick={() => {setMenuOpen(false);props.setActive("overviews")}}><i className="glyphicon glyphicon-road"></i> 
            &nbsp;
            {props.language === "en" && "PATHWAYS & OVERVIEWS"}
            {props.language === "es" && "RUTAS Y PERSPECTIVAS"}
            <hr className="menu-item-split"/></Link>}

            {authReady && userState && userState.hasPro && <Link className={props.active === "learn" ? "menu-item active" : "menu-item"}  to="/learn" onClick={() => {setMenuOpen(false);props.setActive("learn")}}><i className="fa fa-graduation-cap"></i> 
            &nbsp;
            {props.language === "en" && "RECENT ADDITIONS"}
            {props.language === "es" && "ADICIONES RECIENTES"}

            <hr className="menu-item-split"/></Link>}
            {authReady && userState && userState.hasPro && <Link className={props.active === "discuss-latest" ? "menu-item active" : "menu-item"}  to="/discuss-latest" onClick={() => {setMenuOpen(false);props.setActive("discuss-latest")}}><i className="fa fa-graduation-cap"></i> 
            &nbsp;
            {props.language === "en" && "DISCUSS"}
            {props.language === "es" && "DISCUTIR"}
            <hr className="menu-item-split"/></Link>}

            {authReady && userState && userState.hasPro && <Link className={props.active === "contribute" ? "menu-item active" : "menu-item"} to="/contribute" onClick={() => {setMenuOpen(false);props.setActive("contribute")}}><i className="fa fa-group"></i> 
            &nbsp;
            {props.language === "en" && "CONTRIBUTE"}
            {props.language === "es" && "CONTRIBUIR"}
            <hr className="menu-item-split"/></Link>}

            {authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && <Link className={props.active === "mergeconcepts" ? "menu-item active" : "menu-item"} to="/mergeconcepts" onClick={() => {setMenuOpen(false);props.setActive("mergeconcepts")}}><i className="fa fa-group"></i> MERGE CONCEPTS<hr className="menu-item-split"/></Link>}
            {authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && <Link className={props.active === "createrole" ? "menu-item active" : "menu-item"} to="/createrole" onClick={() => {setMenuOpen(false);props.setActive("createrole")}}><i className="fa fa-group"></i> CREATE ROLES<hr className="menu-item-split"/></Link>}
            {authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && <Link className={props.active === "manageconcepttypes" ? "menu-item active" : "menu-item"} to="/manageconcepttypes" onClick={() => {setMenuOpen(false);props.setActive("manageconcepttypes")}}><i className="fa fa-group"></i> MANAGE CONCEPT TYPES<hr className="menu-item-split"/></Link>}
            {authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && <Link className={props.active === "manageroles" ? "menu-item active" : "menu-item"} to="/manageroles" onClick={() => {setMenuOpen(false);props.setActive("manageroles")}}><i className="fa fa-group"></i> MANAGE ROLES<hr className="menu-item-split"/></Link>}
            {authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && <Link className={props.active === "admininsights" ? "menu-item active" : "menu-item"} to="/admininsights" onClick={() => {setMenuOpen(false);props.setActive("admininsights")}}><i className="fa fa-group"></i> ADMIN INSIGHTS<hr className="menu-item-split"/></Link>}
            {authReady && userState && <Link className={props.active === "account" ? "menu-item active" : "menu-item"} to="/account" onClick={() => {
                setMenuOpen(false);
                props.setActive("account")


              }}><i className="glyphicon glyphicon-user"></i> 
            &nbsp;
            {props.language === "en" && "MY ACCOUNT"}
            {props.language === "es" && "MI CUENTA"}
              </Link>}


            <br/>
            <br/>
            {authReady && !userState && <Link className="menu-item" id="layoutlogin" key="layoutlogin" to="/login" onClick={() => {
                setMenuOpen(false)
                props.setActive()
            }} >
            &nbsp;
            {props.language === "en" && "SIGN IN"}
            {props.language === "es" && "INICIAR SESIÓN"}
            </Link>}
            {authReady && !userState && <Link className="menu-item" id="layoutsignup" key="layoutsignup" to="/signup" onClick={() => {
                setMenuOpen(false)
                props.setActive()
            }} >
            &nbsp;
            {props.language === "en" && "SIGN UP"}
            {props.language === "es" && "REGISTRARSE"}
            </Link>}
            {authReady && userState && <Link className="menu-item" id="layoutlogout" key="layoutlogout" to="/" onClick={() => {
                setMenuOpen(false)
                logoutUser()
                props.setActive("home")
            }} >
            &nbsp;
            {props.language === "en" && "SIGN OUT"}
            {props.language === "es" && "CERRAR SESIÓN"}
              </Link>}
              
          </Menu>

          <LanguageSwitcher {...props} />
          
          <div className="layout">
            <div className={"header forceninatokafont " + (props.platform ? props.platform.platform_raw : "")}>
              {props.platform ? props.platform.platform : ""}
            </div>

            <div className={props.screenMode === "mobile" ? "body mobile " + (props.platform ? props.platform.platform_raw : "") : "body desktop " + (props.platform ? props.platform.platform_raw : "") }>
            <Outlet />
            </div>
            <div className={"footer " + props.screenMode}>
            <div><img src="/images/mail.webp"/><span> info@ninatoka.com</span></div>

              <div id="social">
                <div><a href="https://www.instagram.com/ninatoka_com/"><img src="/images/insta.webp"/></a></div>
                <div><a href="https://www.facebook.com/profile.php?id=100089453866519"><img src="/images/fb.wix_mp"/></a></div>
                <div><a href="http://www.twitter.com/ninatokaCOM"><img src="/images/twitter.webp"/></a></div>
              </div>
              <div><span className="glyphicon glyphicon-copyright-mark"></span> 2022 by Visualized</div>


              <div>Screen mode: {props.screenMode}</div>
              {userState && userState.user && userState.user.token && <div>{"Remaining session time: " + Math.round((userState.user.token.expires_at - new Date().getTime())/1000/60) + "'"}</div>}
              <br/>
            </div>
          </div>
          
    </>
  )
  
}

export default Layout;