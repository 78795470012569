import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from '../stores/authContext'
import { Link } from "react-router-dom"; 



/**
 * 
 * @param {id} props 
 * @returns 
 */
function Subscribe (props) {
    let navigate = useNavigate()

    console.info("RelevantElementsPane render triggered");

    const {authReady, userState, loginUser, forgotPassword} = useContext(AuthContext)


        

    return (
           
        <>
       
            {authReady && !userState && 
                <div className={"box " + props.screenMode}>
                
                    <div id={"subscribe" + props.direction}>
                    
                        <p>
                        
                        
                            {props.language === "en" && 
                            <><br/><br/>Enjoy a free trial.
                            <br/>Sign up first, then choose your subscription with trial period to access all features.<br/>
                            </>}
                            {props.language === "es" && 
                            <><br/><br/>Disfruta de una prueba gratuita.
                            <br/>Regístrate primero, luego elige tu suscripción con período de prueba para acceder a todas las funciones.<br/>
                            </>}
                            
                        
                        </p>
                                
                        <div id={"subscribePriceTable" + props.screenMode}>
                            <button className="btn pricingtableimgbutton">
                            <img src={"/images/pricingtable.png"} id={"pricingtableimg"} alt="Sign up" onClick={(e) => {navigate("/signup")}}/>
                            </button>
        
                        </div>                
        
                    </div>
                
                </div>
            }
    
    
    
            {authReady && userState && !userState.hasPro && userState.pricingTable && 
                <div className={"box " + props.screenMode}>
    
                    <div id={"subscribe" + props.direction}>
                        {!userState.stripeID && 
                            
                        <p>
                            {props.language === "en" && 
                                <>Thank you for signing up! <br/>Without subscription, you are free to scan the overviews, but you will not be able to navigate further or do your own searches.
                                <br/><br/>Choose a monthly subscription for a 7-day trial or a yearly subscription for a 14-day trial to access all features.
                                <br/>
                                </>}
                                {props.language === "es" && 
                                <>Gracias por registrarte. <br/>Sin suscripción, puedes escanear los resúmenes, pero no podrás navegar más allá ni realizar tus propias búsquedas.
                                <br/><br/>Elige una suscripción mensual con un período de prueba de 7 días o una suscripción anual con un período de prueba de 14 días para acceder a todas las funciones.<br/>
                                <br/>
                                </>}
    
                            
                        </p>
                        }
        
                        {userState.stripeID && 
                        
                        <p>
                            {props.language === "en" && 
                                <>Thank you for signing up! <br/>You don't have an active subscription. <br/>You are free to scan the overviews, but you will not be able to navigate further or do your own searches.
                                <br/><br/>Choose your subscription to access all features of Ninatoka.<br/><br/>
                                </>}
                                {props.language === "es" && 
                                <>Gracias por registrarte. <br/>No tienes una suscripción activa. Puedes escanear las vistas generales, pero no podrás navegar más allá ni realizar tus propias búsquedas.
                                <br/><br/>Elige tu suscripción para acceder a todas las funciones de Ninatoka.<br/><br/>
                                </>}
        
                        
                        </p>
                        }
        
        
                        <div id={"subscribePriceTable" + props.screenMode}>
                        
                            <stripe-pricing-table pricing-table-id={userState.pricingTable.pricingTableID}
                                publishable-key={userState.pricingTable.pricingTableKey}
                                customer-email={userState.email}
                                client-reference-id={userState.dbid}>
                            </stripe-pricing-table>  
                        </div>                
        
                    </div>
               
                </div>
               }


        </>
    );    

}

export default Subscribe;