import React, { useEffect, useContext } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
import ContributeNode from '../components/ContributeNode';
import ContributeEdge from '../components/ContributeEdge';
import AuthContext from '../stores/authContext'


function ReviewPage (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)
    let { id, type } = useParams();

    console.info("reviewpage", props, type, id);

    useEffect(() => {
        console.log("ReviewPage.useEffect -- user:", userState)

        let headers

        if(authReady) {
            if(userState) {
                refreshUser()
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }
    
            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: ("review/" + type + "/" + id),
                })
            })
        }

      
    }, [authReady, userState])



    return (
        
        <>
            <div className={"title " + (props.platform ? props.platform.platform_raw : "")}>REVIEW</div>
            <div className="reviewPage">
                <div className="box">
                    {!authReady && <p><br/>...</p>}
                    {authReady && !userState && 
                        <>
                            <p><br/><br/>You must be logged in<br/><br/></p>
                            <p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link></p>
                        </>}
                    {authReady && userState && !userState.hasPro && 
                        <>
                        <p>You have no subscription at this moment. Sign up for a free trial to experience Ninatoka.</p>
                                                      
                        <stripe-pricing-table pricing-table-id={userState.pricingTable.pricingTableID}
                                publishable-key={userState.pricingTable.pricingTableKey}
                        customer-email={userState.email}
                        client-reference-id={userState.dbid}>
                        </stripe-pricing-table>  
                    </>}
                    {authReady && userState && type === "concept" && <ContributeNode {...props} windowmode={"dialog"} id={id} />}
                    {authReady && userState && type === "relation" && <ContributeEdge {...props} windowmode={"dialog"} id={id} />}
                </div>
            </div>


        </>
        
    )
}

export default ReviewPage;