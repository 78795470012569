exports.ORDER_BY_CREATIONDATE_DESC = "ORDER_BY_CREATIONDATE_DESC"
exports.ORDER_BY_UPDATEDATE_DESC = "ORDER_BY_UPDATEDATE_DESC"
exports.ORDER_BY_LABEL_LENGTH = "ORDER_BY_LABEL_LENGTH"
exports.ORDER_BY_LABEL = "ORDER_BY_LABEL"
exports.FILTER_UNVERIFIED = "FILTER_UNVERIFIED"
exports.FILTER_VERIFIED = "FILTER_VERIFIED"
exports.FILTER_ALL = "FILTER_ALL"
exports.PLATFORM_RAW = process.env.PLATFORM
//exports.PLATFORM_RAW = "tcm"
exports.PLATFORM = exports.PLATFORM_RAW === "ninatoka" ? "Ninatoka" : "TCM Connect"
exports.HOMEMESSAGE = {
    "en": exports.PLATFORM_RAW === "ninatoka" ? 
    "Ninatoka allows practitioners of integrative medicine to easily find connections and underlying causes of illnesses. The data is presented in a visual and interactive way, making it easy to understand and navigate. \nWith Ninatoka, practitioners can quickly and easily access a wealth of information to help them make more informed decisions about cause and treatment. \nWhether you're just starting out in the field of Integrative Medicine or are a seasoned practitioner, Ninatoka is an essential tool for anyone looking to improve their practice and better serve their patients." 
    : "TCM Connect is a powerful tool that allows practitioners of traditional Chinese medicine (TCM) to easily find connections and underlying causes of illnesses. The data is presented in a visually engaging way, making it easy to understand and navigate. \nOne of the key benefits of TCM Connect is that it is built by a community of experts who are passionate about TCM. This means that the data is constantly being updated and peer-reviewed to ensure its accuracy and quality. This makes TCM Connect an invaluable resource for practitioners looking to deepen their understanding of TCM and improve their ability to diagnose and treat patients. \nWith TCM Connect, practitioners can quickly and easily access a wealth of information, including potential interventions, to help them make more informed decisions about treatment. Whether you're just starting out in the field of TCM or are a seasoned practitioner, TCM Connect is an essential tool for anyone looking to improve their practice and better serve their patients." ,

    "es": exports.PLATFORM_RAW === "ninatoka" ? 
    "Ninatoka permite a los practicantes de medicina integrativa encontrar fácilmente conexiones y causas subyacentes de las enfermedades. Los datos se presentan de forma visual e interactiva, lo que facilita su comprensión y navegación. \nCon Ninatoka, los practicantes pueden acceder rápidamente y de manera sencilla a una gran cantidad de información para ayudarles a tomar decisiones más informadas sobre las causas y el tratamiento. \nYa sea que estés comenzando en el campo de la Medicina Integrativa o seas un practicante experimentado, Ninatoka es una herramienta esencial para cualquiera que busque mejorar su práctica y servir mejor a sus pacientes." 
    : "Ninatoka permite a los practicantes de medicina integrativa encontrar fácilmente conexiones y causas subyacentes de las enfermedades. Los datos se presentan de forma visual e interactiva, lo que facilita su comprensión y navegación. Con Ninatoka, los practicantes pueden acceder rápidamente y de manera sencilla a una gran cantidad de información para ayudarles a tomar decisiones más informadas sobre las causas y el tratamiento. Ya sea que estés comenzando en el campo de la Medicina Integrativa o seas un practicante experimentado, Ninatoka es una herramienta esencial para cualquiera que busque mejorar su práctica y servir mejor a sus pacientes." 
}




exports.KEYWORDS = exports.PLATFORM_RAW === "ninatoka" ? "medicin, functional medicin, orthomoleculair, orthomoleculaire, orthomolecular, kpni, cpni, pni, health, science, pubmed, student, therapist, knowledge base, knowledge, graph, " 
    : "TCM, Traditional Chinese Medicine, accupuncture, therapist, therapy, knowledge base, graph"
exports.LANDINGPAGEIMG = exports.PLATFORM_RAW === "ninatoka" ? ["integrativemedicinehumanhead.jpg"] : ["tcmtwomenlandingpage.jpg"]
exports.PRICINGTABLEIMG = exports.PLATFORM_RAW === "ninatoka" ? ["pricingtable.png"] : undefined
exports.STRIPE_PRICING_TABLE_ID = exports.PLATFORM_RAW === "ninatoka" ? process.env.STRIPE_PRICING_TABLE_ID : undefined
exports.STRIPE_PRICING_TABLE_KEY = exports.PLATFORM_RAW === "ninatoka" ? process.env.STRIPE_PRICING_TABLE_KEY : undefined
exports.STRIPE_PRICING_TABLE_NO_TRIAL_ID = exports.PLATFORM_RAW === "ninatoka" ? process.env.STRIPE_PRICING_TABLE_NO_TRIAL_ID : undefined
exports.STRIPE_PRICING_TABLE_NO_TRIAL_KEY = exports.PLATFORM_RAW === "ninatoka" ? process.env.STRIPE_PRICING_TABLE_NO_TRIAL_KEY : undefined
exports.FLEXMAILUSER = process.env.FLEXMAILUSER
exports.FLEXMAILPW = process.env.FLEXMAILPW
exports.FLEXMAILSOURCE = process.env.FLEXMAILSOURCE
exports.FLEXMAILINTEREST_BASIC = process.env.FLEXMAILINTEREST_BASIC