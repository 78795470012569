import { Link } from "react-router-dom"; 
import AuthContext from '../stores/authContext'
import React, { useState, useEffect, useContext } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { useNavigate } from "react-router-dom";
import { fillWeekGaps } from "../utils/utils";



/**
 * 
 * @param {*} props 
 * @returns 
 */
function StatsOverviews (props) {
    let navigate = useNavigate()

    console.info("StatsOverviews render triggered");

    const {userState, authReady, refreshUser, recoverUser, acceptInvite, confirmUser} = useContext(AuthContext)
    const [data, setData] = useState({labels: [], datasets: []})
    
    useEffect(() => {

        let headers
        if(userState) {
            refreshUser()

            headers = {
                Authorization: 'Bearer ' + userState.token
            }
        }

        fetch('/.netlify/functions/load-stats-overviews', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({})
        })
        .then(response => {
            return response.json()
        })
        .then(response => {
            console.log("StatsHighlightsPerWeek.useeffect -- response",response)
            let _labels = []
            let _visitData = []

            //let cumulativeTotal = 0
            response.perWeek.forEach(m => {
                _labels.push(m.year + "-" + m.week)
            })

     

            var dataset = [];

            response.perWeek.forEach(function(elem) {
                var overview = elem.overview;
                var data = Array(_labels.length).fill(0);
                var i = _labels.indexOf(elem.year + '-' + elem.week);
                if(i !== -1) {
                    data[i] = elem.amount;
                }
                
                var found = false;

                for(var i = 0; i < dataset.length; i++) {
                    if(dataset[i].label === overview) {
                    dataset[i].data = dataset[i].data.map(function(amount, j) {
                        return amount + data[j];
                    });
                    found = true;
                    break;
                    }

                }
                if(!found) {
                    dataset.push(
                        {
                            label: overview, 
                            data: data, 
                            backgroundColor: "rgb(" + Math.floor(Math.random() * 256) + "," + Math.floor(Math.random() * 256) + "," + Math.floor(Math.random() * 256) + ")"});
                }
            });


            console.log("StatsOverview -- outputArray", dataset)

            setData({labels: _labels, datasets: dataset})
            //setData({                labels: [2001, 2002, 2003],                datasets: [{label: "overviewa", data: [1,2,3]}, {label: "overviewb", data:[4,5,6]}]            })
        })
        .catch(error => {
            console.error(error)
        })

    }, [])
   
    ChartJS.register(
        CategoryScale,
        BarElement,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );

      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            //display: true,
            //text: 'How the knowledge of NinaToka grows...',
          },
        },
        scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
      };
    

    return (
        <>
                 
                {authReady && userState && 
            <>
                <div className={"chartMaxWidth " + props.screenMode === "mobile" ? "mobile" : "desktop"}>
                        <h2>Visits to overviews per week per overview</h2>
                        <Bar options={options} data={data} height="500px" width="400px"/>
                    </div>
                
            </>
            }
        </>
        
    );    

}

export default StatsOverviews;