

const UnderConstructionPage = () => {
    return (
    <>
        <div className={"title "}>UNDER CONSTRUCTION</div>
    
    </>
  )};
  
  export default UnderConstructionPage;