import React from 'react';

class RelevantElementsPane extends React.Component {
    constructor(props) {
        super(props);
        console.info("relevantelementspane  constructor triggered");
    }

    render() {
        console.info("RelevantElementsPane render triggered");

        const relevantElements = this.props.relevantElements;
        console.log("RelevantElementsPane.render -- relevantElements",relevantElements)
        const listItems = relevantElements.map((element) =>
            <div className="element" key={element.dbid}>
               
                {element.label}
                <span>&#160;</span>
                <a href="#"><i className="glyphicon glyphicon-remove" title="Remove from highlights" onClick={(e) => {
                    console.info("clicked clicked image", e, e.target);
                    this.props.toggleRelevant(element.dbid);
                    }}/></a>  
                <span>&#160;</span>
                <a onClick={(e) => {
                 this.props.select("concept", element.dbid)}} href="#" title={element.description}><i className="glyphicon glyphicon-expand"></i></a>
            </div>
                
        );
      
        return (
            <div className="box">
                <h2>Highlighted</h2>
                <p>Double-click nodes in the graph to highlight them.</p>
                <div className="flexRowLayout">{listItems}</div>
                
                
            </div>
        );    
    }
}

export default RelevantElementsPane;