import React, { useState, useEffect, useContext } from 'react';
import { ORDER_BY_CREATIONDATE_DESC, FILTER_VERIFIED, FILTER_UNVERIFIED, FILTER_ALL } from '../utils/constants.js';
import InfoPane from '../components/InfoPane.js'
import AuthContext from '../stores/authContext'
import { Link } from "react-router-dom"; 
import Comment from '../components/Comment.js';
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from '../utils/utils.js';


function DiscussionOverviewPage (props) {

    let navigate = useNavigate();


    const {userState, authReady, refreshUser} = useContext(AuthContext)

    const [comments, setComments] = useState([])
    const [loading, setLoading] = useState(false)
   
    const requestNextDiscussions = () => {
        const amount = props.amount ? props.amount : 10;

        if(userState && authReady) {

            setLoading(true)
    
            refreshUser()
            
            fetch('/.netlify/functions/load-comments', 
              {
                  method: 'POST',
                  headers: userState && {
                  Authorization: 'Bearer ' + userState.token
                  },              
                  body: JSON.stringify({
                    page: comments.length,
                    amount: amount,
                    sortMode: ORDER_BY_CREATIONDATE_DESC,
                    groupByData: true,
                    commentsPerData: 3
                  })
              })
            .then(response => {
                if(!response.ok) {
                    throw Error('DiscussionOverviewPage.requestNextConceptPage -- response NOK',response.errorMessage)
                }
      
                return response.json()
            })
            .then(response => {
                console.log("DiscussionOverviewPage.requestNextDiscussions -- result", JSON.stringify(response))
                setComments(comments.concat(response))
                setLoading(false)
            })
            .catch(err => {
                console.error(err);
            })
          }

    }

   

    const requestNewDiscussions = () => {
        var amount = props.amount ? props.amount : 10;

        if(userState && authReady) {

            setLoading(true)

            refreshUser()

            fetch('/.netlify/functions/load-comments', 
              {
                  method: 'POST',
                  headers: userState && {
                  Authorization: 'Bearer ' + userState.token
                  },              
                  body: JSON.stringify({
                    page: 0,
                    amount: amount,
                    sortMode: ORDER_BY_CREATIONDATE_DESC,
                    groupByData: true,
                    commentsPerData: 3
                  })
              })
            .then(response => {
                if(!response.ok) {
                    throw Error('DiscussionOverviewPage.requestNewDiscussions -- response NOK',response.errorMessage)
                }
      
                return response.json()
            })
            .then(response => {
                console.log("requestNewDiscussions.requestNextDiscussions -- result", JSON.stringify(response))
                setComments(response)            
                setLoading(false)
            })
            .catch(err => {
                console.error(err);
            })
          }

    }

  
    useEffect(() => {
        console.log("DiscussionOverviewPage.useEffect -- user:", userState)

        let headers

        if(authReady) {
            if(userState) {

                refreshUser()
    
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }
    
            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: "discuss-latest"
                })
            })
        }

        
    }, [authReady, userState])


    useEffect(() => {
     
        if(userState && authReady){
            requestNewDiscussions()
        }

    }, [authReady, userState])
        
    

    


    var commentsList = comments.map((cgroup, index) => {
        return (
            <div className="box" key={index}>

                <div className="toolbox gotoCommentsToolbox">
                    <button className="btnsmall" onClick={(e) => {
                                navigate("/discuss/" + (cgroup.relation ? "relation" : "concept") + "/" + (cgroup.relation ? parseInt(cgroup.relation) : parseInt(cgroup.concept)));
                            }} title="See discussion"><i className="glyphicon glyphicon-expand expand" id="comment" /></button>
                </div>
                <h2>{capitalizeFirstLetter(cgroup.datalabel)}</h2>
                <br/>

                {cgroup.comments.map((c, index) => {
                    return (
                        <Comment key={index} comment={c} className="commentBoxNoBorder" />
                    )
                    
                })}

            </div>
        )

        
        }
        )


    console.log("DiscussionOverviewPage -- roles: ", !userState ? null : userState.roles)

    return (
        <div className={"standardbodywrapper " + props.screenMode}>

            <div className={"discussionOverviewPage " + props.screenMode}>

                <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titlediscussoverview">LATEST DISCUSSIONS</div>
                <div>
                    {!authReady && <p><br/><br/>...</p>}
                    {authReady && !userState && 
                    <>
                        <p><br/><br/>You must be logged in<br/><br/></p>
                        <p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link></p>
                    </>}
                    {authReady && userState && 
                    <>

                
                    {commentsList}
                    {loading && <p><br/>...</p>}
                    
                    <div className="toolbox learnToolbar"><button title="Load more" onClick={requestNextDiscussions}><span className="glyphicon glyphicon-menu-right right"/></button></div>
                    </>}

                    {authReady && userState && !userState.hasPro && 
                    <>
                        {!userState.stripeID && 
                                <p>You have no subscription at this moment. Sign up for a free trial to experience Ninatoka.</p>
                            }
                            {userState.stripeID && 
                                <p>You have no subscription at this moment.</p>
                            }  

                        <stripe-pricing-table pricing-table-id={userState.pricingTable.pricingTableID}
                                publishable-key={userState.pricingTable.pricingTableKey}
                        customer-email={userState.email}
                        client-reference-id={userState.email}>
                        </stripe-pricing-table>                      
                        
                    </>}
                </div>
            
            </div>


        </div>
    );
    
}

export default DiscussionOverviewPage;