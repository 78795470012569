import React, { useEffect, useContext, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";

import AuthContext from '../stores/authContext'
import InfoPane from '../components/InfoPane';
import Comment from '../components/Comment';
import TextareaAutosize from 'react-textarea-autosize';

function DiscussPage (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)
    let { id, type } = useParams();

    const [subject, setSubject] = useState({})
    const [comments, setComments] = useState([])
    const [myComment, setMyComment] = useState("")
    const [initialized, setInitialized] = useState(false)

    console.info("DiscussPage", props, type, id);


    const loadConcept = async (id) => {
        console.log("DiscussPage.loadConcept -- triggered", userState)


        let headers
        if(userState) {
            refreshUser()
            headers = {
                Authorization: 'Bearer ' + userState.token
            }

            const result = await fetch('/.netlify/functions/load-concepts', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    dbid: id
                })
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error('DiscussPage.loadConcept -- error loading', response)
                    }
    
                    console.log("DiscussPage.loadConcept -- returning response promise")
                    return response.json()
                })
                .catch(err => {
                    console.error(err)
                    throw new Error("DiscussPage.loadConcept -- Could not fetch or process load-concepts: " + err)
                })
    

            console.log("DiscussPage.loadConcept -- result",result)
            if(result && result.length === 1) {
                console.log("DiscussPage.loadConcept -- only result",result[0])
                setSubject(result[0])
            }

        }

        else {
            console.log("DiscussPage.loadConcept -- No user known")
        }
        
    }

    const loadRelation = async (id) => {
        console.log("DiscussPage.loadRelation -- triggered")

        let headers
        if(userState) {
            refreshUser()
            headers = {
                Authorization: 'Bearer ' + userState.token
            }

            const result = await fetch('/.netlify/functions/load-relations', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    dbid: id
                })
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error('DiscussPage.loadRelation -- error loading', response)
                    }
    
                    console.log("DiscussPage.loadRelation -- returning response promise")
                    return response.json()
                })
                .catch(err => {
                    console.error(err)
                    throw new Error("DiscussPage.loadRelation -- Could not fetch or process load-concepts: " + err)
                })
    
                console.log("DiscussPage.loadRelation -- result",result)
                if(result && result.length === 1) {
                    console.log("DiscussPage.loadRelation -- only result",result[0])
                    setSubject(result[0])
                }
        }

        else {
            console.log("DiscussPage.loadRelation -- No user known")
        }
        
    }

    
    const loadComments = async (type, id) => {
        console.log("DiscussPage.loadComments -- triggered")

        let headers
        if(userState) {
            refreshUser()
            headers = {
                Authorization: 'Bearer ' + userState.token
            }

            let body
            if(type === "concept") {
                body = {
                    concept: id
                }
            }
            else if(type === "relation") {
                body = {
                    masterRelation: id
                }
            }

            const result = await fetch('/.netlify/functions/load-comments', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error('DiscussPage.loadComments -- error loading', response)
                    }
    
                    console.log("DiscussPage.loadComments -- returning response promise")
                    return response.json()
                })
                .catch(err => {
                    console.error(err)
                    throw new Error("DiscussPage.loadComments -- Could not fetch or process load-concepts: " + err)
                })
                
            console.log("DiscussPage.loadComments -- result", result)
            setInitialized(true)
            setComments(result)
        }
    }

    const handleCommentSubmit = (e) => {
        e.preventDefault()
        createComment()
        setMyComment("")
    }

    const createComment = async () => {
        console.log("DiscussPage.createComment -- triggered")
        console.log("DiscussPage.createComment -- typeof comment", typeof(myComment))

        let headers
        if(userState && userState.hasPro) {
            refreshUser()
            headers = {
                Authorization: 'Bearer ' + userState.token
            }

            let body
            if(type === "concept") {
                body = {
                    concept: id,
                    comment: myComment && typeof myComment === "string" ? myComment.trim() : undefined
                }
            }
            else if(type === "relation") {
                body = {
                    relation: id,
                    comment: myComment && typeof myComment === "string" ? myComment.trim() : undefined
                }
            }

            const result = await fetch('/.netlify/functions/create-comment', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error('DiscussPage.createComment -- error', response)
                    }
    
                    console.log("DiscussPage.createComment -- returning response promise")
                    return response.json()
                })
                .catch(err => {
                    console.error(err)
                    throw new Error("DiscussPage.createComment -- error: " + err)
                })
                
            loadComments(type, id)
        }
    }

    const loadData = () => {
        if(userState && type && id) {
            if(type === "concept") {
                loadConcept(id)
            }
            else if(type === "relation") {
                loadRelation(id)
            }

            loadComments(type, id)
        }
    }

    useEffect(() => {
        console.log("DiscussPage.useEffect -- user:", userState)

        loadData()

    }, [authReady, userState, type, id])


    useEffect(() => {
        console.log("DiscussPage.useEffect -- user:", userState, authReady, subject)

        if(authReady && (!userState || (subject && subject.label))) {
            console.log("DiscussPage.userEffect -- logging page visit", authReady, userState, subject ? "has subject" : "doesnt have subject")
            let headers
            if(userState) {
                refreshUser()
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }
    
            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: ("discuss/" + type + "/" + id),
                    comment: subject ? subject.label : ""
                })
            })
        }

    }, [authReady, userState, subject])


    return (
        
        <div className={"standardbodywrapper " + props.screenMode}>
            
            <div className={"discussPage " + props.screenMode}>
                <div className={"title " + (props.platform ? props.platform.platform_raw : "")}>DISCUSS</div>

                {!authReady && <p><br/><br/>...</p>}
                {authReady && !userState && 
                    <>
                        <p><br/><br/>You must be logged in<br/><br/></p>
                        <p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link></p>
                    </>}
                {authReady && userState && 
                <>
                    <h2>Subject</h2>
                    <InfoPane 
                        {...props} 
                        element={subject}
                        showLinks={false} 
                        showExploreButton={true} 
                        showDiscussButton={false} 
                        onRate={loadData} 
                        onRateDown={() => {
                            loadComments(type, id)
                        }} className="infoDiscussPane" />
                </>
                        

                }

                <br/>
                <h2>Comments</h2>
                {authReady && userState && initialized && (!comments || comments.length === 0) && 
                <p>There are no comments yet. Be the first to leave a comment.</p>
                }

                {authReady && userState && !initialized && 
                <p>Loading...</p>
                }

                {authReady && userState && userState.hasPro && 
                <div className="box maxwidth">
                    <form onSubmit={handleCommentSubmit}>
                        <TextareaAutosize placeholder="Enter your comment..." value={myComment} onChange={(e) => setMyComment(e.target.value)} className="mine comment"/><br/>
                        <div className="toolbox commentToolbox">
                            <button className="btnsmall" title="Submit comment" value="Submit my comment" onClick={handleCommentSubmit} disabled={!myComment.trim()}><i className="glyphicon glyphicon-send send" id="send" /></button>
                        </div>
                    </form>
                </div>
                
                }

                {authReady && userState && comments.map(comment => <Comment key={comment.id} comment={comment} className="maxwidth commentBox" />)}
            </div>


        </div>
        
    )
}

export default DiscussPage;