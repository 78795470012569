import React, { useState, useEffect, useContext } from 'react';
import ContributeNode from '../components/ContributeNode';
import ContributeEdge from '../components/ContributeEdge';
import AuthContext from '../stores/authContext'
import {useParams} from "react-router-dom";
import { Link } from "react-router-dom"; 


function Contribute (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)
    let { type, from } = useParams();


    const [mode, setMode] = useState(props.initialMode ? props.initialMode : ( type ? type : "concept"))



    useEffect(() => {
        console.log("Contribute.useEffect -- user:", userState)

        let headers
        if(userState) {
            refreshUser()
            headers = {
                Authorization: 'Bearer ' + userState.token
            }
        }

        fetch('/.netlify/functions/create-pagevisit', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                visitedPage: "contribute"
            })
        })
    }, [authReady, userState])
        


    return (

        <div className={"standardbodywrapper " + props.screenMode}>            
            
            <div className={"contributePage " + props.screenMode}>
                <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titlecontribute">CONTRIBUTE</div>
                <div className="box">
                    {!authReady && <><br/><p>...</p></>}

                    {authReady && !userState && 
                    <>
                        <p><br/><br/>You must be logged in<br/><br/></p>
                        <p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link></p>
                    </>}
                    {authReady && userState && 
                        <>
                        <br/>
                        <label>Type of contribution</label>

                            <form className="contributeFilters">
                                <div>
                                    <input type="radio" name="knowledgetype" value="concept" onChange={(e) => setMode(e.target.value)} checked={mode === "concept"}/>
                                    <span>&#160;Concept</span>
                                </div>
                                <div>
                                    <input type="radio" name="knowledgetype" value="relation" onChange={(e) => setMode(e.target.value)}  checked={mode === "relation"}/>
                                    <span>&#160;Relation between concepts</span>
                                </div>
                                <br/>
                            
                            </form>
                            
                            {mode === "concept" && <ContributeNode windowmode={props.windowmode}/>}
                            {mode === "relation" && <ContributeEdge windowmode={props.windowmode} sourceId={from} />}
                        
                        </>
                    }
                    {authReady && userState && !userState.hasPro && 
                    <>
                    {!userState.stripeID && 
                                <p>You have no subscription at this moment. Sign up for a free trial to experience Ninatoka.</p>
                            }
                            {userState.stripeID && 
                                <p>You have no subscription at this moment.</p>
                            }  
                                                      
                        <stripe-pricing-table pricing-table-id={userState.pricingTable.pricingTableID}
                                publishable-key={userState.pricingTable.pricingTableKey}
                        customer-email={userState.email}
                        client-reference-id={userState.email}>
                        </stripe-pricing-table>                      
                        </>}
                </div>
            </div>
        </div>
    )
}


  
export default Contribute;