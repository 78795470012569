import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../stores/authContext'
import {useParams} from "react-router-dom";
import Searcher from '../components/Searcher';
import { Link } from "react-router-dom"; 

function MergeConceptsPage (props) {

    const {userState, authReady, login, signup, refreshUser} = useContext(AuthContext)
    let { id } = useParams();

    const [conceptToKeep, setConceptToKeep] = useState()
    const [conceptToDelete, setConceptToDelete] = useState()
    const [mergeFeedback, setMergeFeedback] = useState()


    useEffect(() => {
        console.log("MergeConceptsPage.useEffect -- user:", userState)
        if(authReady) {
            let headers
            if(userState) {
                refreshUser()
    
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }
    
            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: "mergeconcepts"
                })
            })
        }
     
    }, [authReady, userState])
        

    useEffect(() => {
        console.log("MergeConceptsPage.useEffect -- user:", userState)

        if(id) {
            let headers
            if(userState) {
                refreshUser()
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }

            fetch('/.netlify/functions/load-concepts', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    dbid: id
                })
            })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if(response && response.length === 1) {
                    setConceptToDelete(response[0])
                }
            })
            .catch(err => {
                console.error("MergeConceptsPage.useEffect -- error retrieving concept to delete", err)
            })
        }
    }, [authReady, userState, id])


    const handleSubmit = (event) => {
        event.preventDefault();

        if(authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin")) {
            setMergeFeedback("Merging...")

            let headers
            if(userState) {
                refreshUser()
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }
            fetch('/.netlify/functions/merge-concepts', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    conceptToKeep: conceptToKeep.dbid,
                    conceptToDelete: conceptToDelete.dbid
                })
            })
            .then((response) => {
                if(!response.ok) {
                    console.error("MergeConceptsPage.handleSubmit -- failed", response)
                    setMergeFeedback("Merge failed")
                }
                else {
                    setMergeFeedback("Concepts successfully merged")
                }

                return response.json()
            })
            .then((response) => {
                console.log("MergeConceptsPage.handleSubmit -- response", response)
            })
            .catch(err => {
                console.error("MergeConceptsPage.handleSubmit -- error merging", err)
            })
        }

        else {
            setMergeFeedback("You have insufficient rights to do this")
        }
    }


    return (

        <div className={"standardbodywrapper " + props.screenMode}>            

            <div className={"simplePage " + props.screenMode}>
                <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titlemergeconcepts">MERGE CONCEPTS</div>
                <div className="simplePageChildren">
                    {!authReady && <p>...</p>}
                    {authReady && userState && userState.userRoles && !userState.userRoles.find(r => r === "admin") 
                        &&  <><br/><p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link> with an administrator account.</p></>}
                    {authReady && !userState 
                        &&  <><br/><p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link> with an administrator account.</p></>}

                    
                    {authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && 
                        <>
                            <div className="box">
                                <form onSubmit={handleSubmit}>
                                    <br />
                                    <label>Choose concept to keep</label>
                                    <Searcher mode="concept" requiresInput={true} value={conceptToKeep} callback={(value) => {
                                        setConceptToKeep(value)
                                    }} />
                                    <label>Choose concept to delete</label>
                                    <Searcher mode="concept" requiresInput={true} value={conceptToDelete} callback={(value) => {
                                        setConceptToDelete(value)
                                    }} />

                                    <span  className="toolbox">{mergeFeedback}</span>

                                    <div className="toolbox"><input type="submit" className="btnsmall" value={"Merge"}/></div>
                                </form>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}


  
export default MergeConceptsPage;