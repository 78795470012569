import React, { useEffect, useContext, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";


import Login from '../components/Login'

function LoginPage (props) {

    return (
        
        <div className={"standardbodywrapper " + props.screenMode}>
            <div className={"simplePage " + props.screenMode}>
                <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titlelogin">
                    {props.language === "en" && "LOG IN"}
                    {props.language === "es" && "INICIAR SESIÓN"}
                </div>
            <Login {...props}/>
            </div>
        </div>
    )
}

export default LoginPage;