import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from '../stores/authContext';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import TextareaAutosize from 'react-textarea-autosize';


/**
 * Expects props: element, onSelect function
 */
function InfoPane(props) {

    let navigate = useNavigate();

    const {userState, authReady, refreshUser} = useContext(AuthContext);

    const[confidencePoints, setConfidencePoints] = useState(props.element.confidencePoints);
    const[rateDownDialogOpen, setRateDownDialogOpen] = useState(false);
    const[rateDownComment, setRateDownComment] = useState("");
    const[initialized, setInitialized] = useState(false);
    const[translatedDescription, setTranslatedDescription] = useState("");
    const[doTranslation, setDoTranslation] = useState(localStorage.getItem("doTranslation") === "true" || localStorage.getItem("doTranslation") === true ? true : false);

    console.info("infopane -- props",props);
    console.info("infopane render triggered:",props.element.label);

    const rate = (direction, masterId) => {
        
        if(userState && authReady) {

            refreshUser()

            if(props.element.type === "concept") {
                fetch('/.netlify/functions/rate-concept', 
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify({
                        concept: masterId,
                        direction: direction
                    })
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error(response.errorMessage)
                    }
            
                    console.log("InfoPane.rate -- returning response promise")
                    return response.json()
                })
                .then(response => {
                    console.log("InfoPane.rate -- response", response)
                    setConfidencePoints(response.confidencePoints)
                    props.onSelect && props.onSelect("concept", props.element.dbid)
                    props.onRate && props.onRate("concept", props.element.dbid)
                })
                .catch(err => {
                    console.error(err);
                })
            }
            

            else if(props.element.type === "relation") {
                fetch('/.netlify/functions/rate-relation', 
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify({
                        relation: masterId,
                        direction: direction
                    })
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error(response.errorMessage)
                    }
            
                    console.log("InfoPane.rate -- returning response promise")
                    return response.json()
                })
                .then(response => {
                    console.log("InfoPane.rate -- response", response)
                    setConfidencePoints(response.confidencePoints)
                    props.onSelect && props.onSelect("relation", props.element.dbid)
                    props.onRate && props.onRate("relation", props.element.dbid)
                })
                .catch(err => {
                    console.error(err);
                })
            }
        }
            
    }


    useEffect(() => {
        setConfidencePoints(props.element.confidencePoints)
    }, [props.element.confidencePoints])

    useEffect(() => {
        if(props.element && props.element.label && !initialized) {
            setInitialized(true)
            console.log("InfoPane.useEffect -- initialized")
        }
    }, [props.element])


    useEffect(() => {
        if(false && props.element && props.element.description && props.language !== "en" && doTranslation) {
            fetch('/.netlify/functions/translate', 
            {
                method: 'POST',
                headers: userState && {
                Authorization: 'Bearer ' + userState.token
                },              
                body: JSON.stringify({
                    target_language: props.language,
                    text_original: props.element.description
                })
            })
            .then(response => {
                if(!response.ok) {
                    throw Error(response.errorMessage)
                }
        
                console.log("InfoPane.translate -- returning response promise")
                return response.json()
            })
            .then(response => {
                console.log("InfoPane.translate -- response", response)
                setTranslatedDescription(response.text_translated);
            })
            .catch(err => {
                console.error(err);
                
            })
        }
        else {
            setTranslatedDescription("")
        }
    }, [props.element.description, props.language, doTranslation])


        var relationsList = [];
   
        if(props.showLinks && props.element && props.element.relations) {
            relationsList = props.element.relations.map(r => 
                <p key={r.id}>{props.onSelect ? <><a href="#" title={r.description} onClick={(e) => {
                    console.info("clicked li", e, e.target)
                    props.onSelect("relation", r.dbid) 
                }}><i id={r.dbid} className="glyphicon glyphicon-expand"></i></a><span>&#160;</span></> : <></>}{r.role.toLowerCase()} {r.targetLabel}</p>
                );
            console.info("relations found", props.element.label, props.element.relations);
        }
        else console.info("no relations available");


        var source;
        if(props.showLinks && props.element && props.element.sourceDetails) {
            source = <p>
                {props.onSelectAndExpand ? <> <a onClick={(e) => {props.onSelectAndExpand("concept", props.element.sourceDetails.dbid)}} href="#" title={props.element.sourceDetails.description}><i className="glyphicon glyphicon-expand"></i></a><span>&#160;</span></> : <></>}
                <span>{props.element.sourceDetails.label}</span>
                <br/>
            </p>}
        
        var target;
        if(props.showLinks && props.element && props.element.targetDetails) {
            target = <p>
            {props.onSelectAndExpand ? <><a onClick={(e) => {
                 props.onSelectAndExpand("concept", props.element.targetDetails.dbid)}} href="#" title={props.element.targetDetails.description}><i className="glyphicon glyphicon-expand"></i></a><span>&#160;</span></> : <></>}
            
            <span>{props.element.targetDetails.label}</span>
            
            <br/></p>
        }




        var createdOn
        if(props.element && props.element.createdOn) {
            try {
                createdOn = new Date(props.element.createdOn).toLocaleDateString(undefined, { day: 'numeric', year: 'numeric', month: 'short'}) 
            } catch(e) {
                createdOn = props.element.createdOn
            }
        }
        
        var createdBy
        if(props.element && props.element.createdBy) {
            createdBy = props.element.createdBy
        }

        var updatedOn
        if(props.element && props.element.updatedOn) {
            try {
                updatedOn = new Date(props.element.updatedOn).toLocaleDateString(undefined, { day: 'numeric', year: 'numeric', month: 'short'}) 
            } catch(e) {
                updatedOn = props.element.updatedOn
            }
        }
        
        var updatedBy
        if(props.element && props.element.updatedBy) {
            updatedBy = props.element.updatedBy
        }


        var numberOfComments = 0
        if(props.element && props.element.numberOfComments) {
            numberOfComments = props.element.numberOfComments
        }

        var reviewLink = "/review/" + props.element.type + "/" + props.element.dbid;

        var addRelationButton;
        if(props.element.type === "node" || props.element.type === "concept") {
            addRelationButton = <button className="btnsmall" onClick={(e) => {
                window.open("/contribute-dialog/relation/" + props.element.dbid, 'popUpWindow','height=875,width=600,left=10,top=10,,scrollbars=yes,menubar=no')
            }} title="Add a relation" ><i className="glyphicon glyphicon-plus add" id="add" /></button>
        }

        var exploreButton;
        if(props.showExploreButton) {
            exploreButton = <button className="btnsmall" onClick={(e) => {
                navigate("/explore/" + props.element.type + "/" + props.element.dbid)
                props.setActive("explore")
            }} title="Explore this knowledge" ><i className="glyphicon glyphicon-expand expand" id="explore" /></button>
        }

        var highlightButton;
        console.info("infopane -- onhighlight", props.onHighlight);
        console.info("infopane -- onselect", props.onSelect);

        console.info("infopane -- onSelectAndExpand", props.onSelectAndExpand);

        if(false && props.onHighlight && (props.element.type === "node" || props.element.type === "concept")) {
            console.info("infopane -- creating onhighlight button")
            highlightButton = <button className="btnsmall" onClick={(e) => {
                props.onHighlight(props.element.dbid);
            }} title="Add to highlighted concepts"><i className="glyphicon glyphicon-bookmark highlight" id="highlight" /></button>
        }

        let className = "box overflow" + (props.className ? " " + props.className : "");

        let discussId
        if(props.element.type === "concept") {
            discussId = props.element.dbid
        } else if (props.element.type === "relation") {
            discussId = props.element.isMaster ? props.element.dbid : props.element.masterRelationId
        }

        let discussLink = discussId ? "/discuss/" + props.element.type + "/" + discussId : ""
        let ratingsLink = discussId ? "/ratings/" + props.element.type + "/" + discussId : ""


        console.log("InfoPane -- confidence points",props.element.confidencePoints)
        console.log("InfoPane -- confidence points string",parseInt(""+props.element.confidencePoints))

        const closeRateDownDialog = () => setRateDownDialogOpen(false)
        const openRateDownDialog = () => setRateDownDialogOpen(true)

        const onRateDown = (e) => {
            e.preventDefault()
            createComment()
            rate("down",parseInt(discussId))
            setRateDownComment("")
            closeRateDownDialog()

            props.onRateDown && props.onRateDown(props.element.type, props.element.dbid)
        }
    
        const createComment = async () => {
            console.log("InfoPane.createComment -- triggered")

            if(!rateDownComment || !(typeof rateDownComment === "string") || !rateDownComment.trim()) {
                console.log("InfoPane.createComment -- rateDownComment is empty or of wrong type")
                return
            }
    
            let headers
            if(userState) {
                refreshUser()
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
    
                let body
                if(props.element.type === "concept") {
                    body = {
                        concept: discussId,
                        comment: "Rated down with comment: \n\n" + rateDownComment.trim()
                    }
                }
                else if(props.element.type === "relation") {
                    body = {
                        relation: discussId,
                        comment: "Rated down with comment: \n\n" + rateDownComment.trim()
                    }
                }
    
                const result = await fetch('/.netlify/functions/create-comment', {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(body)
                    })
                    .then(response => {
                        if(!response.ok) {
                            throw Error('InfoPane.createComment -- error', response)
                        }
        
                        console.log("InfoPane.createComment -- returning response promise")
                        return response.json()
                    })
                    .catch(err => {
                        console.error(err)
                        throw new Error("InfoPane.createComment -- error: " + err)
                    })
                    
            }
        }
    
        /**
         * Turned translation option through openai off because it takes too much time and time outs if the text is a bit too long. 
         * Better solution would be to translate after save with a background function, and storing it in the database.
         */
        return (
            <>

            <div className={className}>

                <div className="newlines">

                
                <img src={props.element.type === "concept" ? "/images/node2.png" : "/images/edge2.png"} className="knowledgeTypeIcon" alt={props.element.type === "concept" ? "Concept" : "Relation"} title={props.element.type === "concept" ? "Concept" : "Relation"}/>

                   
                {props.element.nodeType && props.element.nodeType !== "Other" && props.element.nodeType !== "Unknown" && 
                <><span className="knowledgeType"><i>&nbsp;{props.element.nodeType.toLowerCase()}</i></span></>
                }
                {(!props.element.nodeType || props.element.nodeType === "Other" || props.element.nodeType === "Unknown") && 
                <><span className="knowledgeType"><i>&nbsp;{props.element.type}</i></span></>
                }

                    <h2 className="wordWrap">
  
                    {props.element && 
                        <>{props.element.label}</>
                    }
                    {props.element && props.element.synonyms && <>&nbsp;({props.element.synonyms})</>}
                    
                    {!initialized && "..."}
                    </h2>


                    {!initialized && <><p>Loading...</p><br/></>}

                    

                    {false && props.element && props.element.description && props.language !== "en" && doTranslation && !translatedDescription && <><br/><span>Translating...</span><br/></>}

                    {props.element && props.element.description && !(doTranslation && translatedDescription) && <><br/><span>{props.element.description}</span><br/></>}

                    {props.element && props.element.description && props.language && doTranslation && translatedDescription && <><br/><span>{translatedDescription}</span><br/></>}

                    
                    {false && props.element && props.element.description && props.language !== "en" && 
                    <><br/><label class="switch">
                        <input type="checkbox" checked={doTranslation} onClick={(e) => {
                            console.log("dotranslation: button clicked",e.target.checked);
                            localStorage.setItem("doTranslation", e.target.checked);
                            setDoTranslation(e.target.checked);
                        } }/>
                        <span class="switch-slider round"></span>&nbsp;
                        {props.language === "es" && "Mostrar traducción"}
                        
                    </label><br/></>}


                    {props.element && props.element.reference && (props.element.reference.startsWith("http") || props.element.reference.startsWith("www")) && 
                    <><br/><span className="wordWrap reference"><u>Ref:</u></span>{" "}<span className="wordWrap reference"><a href={props.element.reference} target="new">{props.element.reference}</a></span></> 
                    }

                    {props.element && props.element.reference && !(props.element.reference.startsWith("http") || props.element.reference.startsWith("www")) && 
                    <><br/><span className="wordWrap reference"><u>Ref:</u></span><br/><span className="wordWrap reference">{props.element.reference}</span></>
                    }
                    {(!props.element || !props.element.reference) && 
                        <><br/><span className="wordWrap reference"><u>Ref:</u>{" "}<span className="warning reference">The contributor did not provide a reference</span></span></>
                    }

                </div>

                <div>
                    
                    <br/><span className="alignright indentright"><i><a href={ratingsLink}>{confidencePoints ? confidencePoints.toString() : 0}</a> 
                    &nbsp;
                    {props.language === "en" && "confidence points"}
                    {props.language === "es" && "puntos de confianza"}
                    <span>&#160;</span></i>
                    {props.language === "en" && <span className={confidencePoints && confidencePoints > 0 ? "glyphicon glyphicon-ok textGreen alignright" : "glyphicon glyphicon-warning-sign textOrange alignright"} title={confidencePoints && confidencePoints > 0 ? "Passed verification" : "Rate this content up to get it acknowledged."}/>} 
                    {props.language === "es" && <span className={confidencePoints && confidencePoints > 0 ? "glyphicon glyphicon-ok textGreen alignright" : "glyphicon glyphicon-warning-sign textOrange alignright"} title={confidencePoints && confidencePoints > 0 ? "Verificación aprobada" : "Evalúa este contenido positivamente para que sea reconocido."}/>} 
                    </span>
                    <br/><span className="alignright indentright"><i><a href={discussLink}>{numberOfComments}</a> 
                    &nbsp;
                    {props.language === "en" && "comments"}
                    {props.language === "es" && "comentarios"}
                    </i></span>
                    <br/><span className="alignright indentright"><i>
                    &nbsp;
                    {props.language === "en" && <>Added on {createdOn} by {createdBy}</>}
                    {props.language === "es" && <>Agregado el {createdOn} por {createdBy}</>}
                    </i></span>
                    {updatedOn && 
                        <><br/><span className="alignright indentright"><i>
                        {props.language === "en" && <>Edited on {updatedOn} by {updatedBy}</>}
                        {props.language === "es" && <>Modificado el {updatedOn} por {updatedBy}</>}
                        </i></span></>
                    }


                    <br/>

                    {userState && userState.hasPro && 
                    <div className="toolbox alignright">
                        {exploreButton}
                        {highlightButton}

                        {props.language === "en" && <button className="btnsmall" disabled={!authReady || !userState} onClick={(e) => {                 
                                console.log("rate content up: " + props.element.label)
                                rate("up",parseInt(discussId))
                            }} title="If you agree with this data, rate it up"><i className="glyphicon glyphicon-thumbs-up rateUp" id="rateContentUp" /></button>}
                        {props.language === "es" && <button className="btnsmall" disabled={!authReady || !userState} onClick={(e) => {                 
                                console.log("rate content up: " + props.element.label)
                                rate("up",parseInt(discussId))
                            }} title="Si estás de acuerdo con estos datos, califícalos positivamente"><i className="glyphicon glyphicon-thumbs-up rateUp" id="rateContentUp" /></button>}

                        {props.language === "en" && <button className="btnsmall" disabled={!authReady || !userState} onClick={(e) => {          
                                if(authReady && userState) {
                                    console.log("rate content down: " + props.element.label)
                                    openRateDownDialog()
                                    //rate("down",parseInt(discussId))
                                }
                            }} title="If you do not agree with this data, rate it down"><i className="glyphicon glyphicon-thumbs-down rateDown" id="rateContentDown" /></button>}

                        {props.language === "es" && <button className="btnsmall" disabled={!authReady || !userState} onClick={(e) => {          
                                if(authReady && userState) {
                                    console.log("rate content down: " + props.element.label)
                                    openRateDownDialog()
                                    //rate("down",parseInt(discussId))
                                }
                            }} title="Si no estás de acuerdo con estos datos, califícalos negativamente"><i className="glyphicon glyphicon-thumbs-down rateDown" id="rateContentDown" /></button>}
         
                        <Popup modal open={rateDownDialogOpen} onClose={closeRateDownDialog}>
                            <div>
                                <a className="close" onClick={closeRateDownDialog}>
                                    &times;
                                </a>
                               
                                <div className="dialogTitle">
                                    {props.language === "en" && "Please leave a comment before rating this data down"}
                                    {props.language === "es" && "Por favor, deja un comentario antes de calificar negativamente estos datos"}
                                </div>
                                <br/>
                                <div className="box maxwidth">
                                    <form onSubmit={onRateDown}>
                                        {props.language === "en" && <><TextareaAutosize placeholder="Enter your comment..." value={rateDownComment} onChange={(e) => setRateDownComment(e.target.value)} className="mine comment"/><br/></>}
                                        {props.language === "es" && <><TextareaAutosize placeholder="Ingresa tu comentario..." value={rateDownComment} onChange={(e) => setRateDownComment(e.target.value)} className="mine comment"/><br/></>}
                                        <div className="toolbox dialogToolbox">
                                            {props.language === "en" && <button className="btnsmall" title="Submit comment and rate down" value="Submit comment and rate down" onClick={onRateDown} disabled={!rateDownComment.trim()}><i className="glyphicon glyphicon-thumbs-down rateDown" id="send" /></button>}
                                            {props.language === "en" && <button className="btnsmall" title="Enviar comentario y calificar negativamente" value="Submit comment and rate down" onClick={onRateDown} disabled={!rateDownComment.trim()}><i className="glyphicon glyphicon-thumbs-down rateDown" id="send" /></button>}
                                            
                                        </div>                       
                                    </form>

                                            
                                </div>
                                
                                

                            </div>
                        </Popup>

                        {props.language === "en" && <>{props.showDiscussButton && 
                            <button className={"btnsmall" + (numberOfComments ? " highlight" : "")} onClick={(e) => {
                                console.log("comment: " + props.title)
                                navigate("/discuss/" + props.element.type + "/" + parseInt(discussId))
                                props.setActive("discuss")
                            }} title="Discuss"><i className="glyphicon glyphicon-comment comment" id="comment" /></button>
                        }</>}
                        {props.language === "es" && <>{props.showDiscussButton && 
                            <button className={"btnsmall" + (numberOfComments ? " highlight" : "")} onClick={(e) => {
                                console.log("comment: " + props.title)
                                navigate("/discuss/" + props.element.type + "/" + parseInt(discussId))
                                props.setActive("discuss")
                            }} title="Discutir"><i className="glyphicon glyphicon-comment comment" id="comment" /></button>
                        }</>}

                        {userState && userState.userRoles && (userState.userRoles.find(r => r === "admin") || userState.userRoles.find(r => r === "contributor")) && <>
                            <button className="btnsmall" onClick={(e) => {
                                window.open(reviewLink, 'popUpWindow','height=875,width=600,left=10,top=10,,scrollbars=yes,menubar=no')
                                //navigate(reviewLink)
                                //props.setActive("review")
                            }} title="Provide a new version of this data" ><i className="glyphicon glyphicon-edit edit" id="update" /></button>
                        </>}


                        {userState && userState.userRoles && (userState.userRoles.find(r => r === "admin") || userState.userRoles.find(r => r === "contributor")) && <>
                            {addRelationButton}
                        </>}
                    
                       {props.language === "en" && <>{props.element.ratedByUser && 
                        <><button className="btnsmall" onClick={(e) => {                 
                                console.log("undo rating: " + props.element.label)
                                rate("undo",parseInt(discussId))
                            }} title="Undo my rating"><i className="glyphicon glyphicon-ban-circle"  /></button></>
                        }</>}   
                        {props.language === "es" && <>{props.element.ratedByUser && 
                        <><button className="btnsmall" onClick={(e) => {                 
                                console.log("undo rating: " + props.element.label)
                                rate("undo",parseInt(discussId))
                            }} title="Deshacer mi calificación"><i className="glyphicon glyphicon-ban-circle"  /></button></>
                    }</>}

                            
                    </div>
                    }



                    
                    {(props.showLinks && props.element) ? <><br/></> : ""}

                    {userState && userState.hasPro && (source || target) && <p><br/><br/><br/><label>
                    {props.language === "en" && "Explore source and target concepts..."}
                    {props.language === "es" && "Explorar conceptos de origen y destino..."}
                    </label></p>}
                    {userState && userState.hasPro && props.showLinks && props.element.type === "concept" && <p><br/><br/><br/><label>
                        {props.language === "en" && "Explore relations..."}
                        {props.language === "es" && "Explorar relaciones"}
                    
                    </label></p>}
                    {userState && userState.hasPro && relationsList}
                    {userState && userState.hasPro && props.showLinks && props.element && props.element.type === "concept" && !props.element.isExpanded && 
                        <>
                            <p>
                                {props.language === "en" && <><a onClick={(e) => {props.onSelectAndExpand("concept", props.element.dbid)}} href="#" title="Check for more relations"><i className="glyphicon glyphicon-expand"></i></a></>}
                                {props.language === "es" && <><a onClick={(e) => {props.onSelectAndExpand("concept", props.element.dbid)}} href="#" title="Buscar más relaciones"><i className="glyphicon glyphicon-expand"></i></a></>}
                                <span>&#160;
                                    {props.language === "en" && "...check for more relations"}
                                    {props.language === "es" && "...buscar más relaciones"}                             
                                
                                </span>
                            </p>
                        </>  
                    }

                    {userState && userState.hasPro && source}
                    {userState && userState.hasPro && target}
                    
                   
                </div>
            </div>
            </>
        );
    
}

export default InfoPane;