import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from '../stores/authContext'
import { Link } from "react-router-dom"; 



/**
 * 
 * @param {id} props 
 * @returns 
 */
function PasswordReset (props) {
    console.info("RelevantElementsPane render triggered");

    const {updatePassword} = useContext(AuthContext)

    const [password, setPassword] = useState("")
    const [feedback, setFeedback] = useState("")
    const {userState, recoverFeedback} = useContext(AuthContext)



    const handleUpdatePassword = (e) => {
        e.preventDefault()

        if(!password) {
            setFeedback("Please provide a new password.")
        }

        else {
            updatePassword(password, (result) => {
                if(result === "Success") {
                    setFeedback("Successfully updated password. Enjoy your stay.")
                }
                else {
                    setFeedback("Failed to update password. Request a new password recovery link.")
                }
               
            })
        }
        
        
    }

    return (
        <div className={"standardbodywrapper " + props.screenMode}>
            <div className={"simplePage " + props.screenMode}>
                <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titlepasswordreset">PASSWORD RESET</div>
                <div className="box">
                    <form onSubmit={handleUpdatePassword}>
                        <br/>
                        <p>
                        <label>New password *</label>
                        <input required type="password" className="mine" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </p>
                        <p>
                            {feedback}
                        </p>
                        <p>
                        <button disabled={!userState} onClick={handleUpdatePassword}>Update password</button>                        
                        {!userState && recoverFeedback !== "Error" && <>Hold on a second... loading your user data.</>}
                        
                        </p>   
                        {recoverFeedback === "Error" && <p>Something's up. Could not load your user. Maybe your recovery email has expired. 
                        You can <Link to="/login">try again</Link>, but you'll need to wait a while before you can receive a new email.</p>}                 
                    </form>
                </div>
            </div>
           
        </div>
        
    );    

}

export default PasswordReset;