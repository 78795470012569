import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../stores/authContext';
import { Link } from "react-router-dom"; 
import { useNavigate } from "react-router-dom";
import { STRIPE_PRICING_TABLE_ID, STRIPE_PRICING_TABLE_KEY } from '../utils/constants';
import {FLEXMAILUSER, FLEXMAILPW, FLEXMAILSOURCE} from '../utils/constants';
import Popup from 'reactjs-popup';
import Login from '../components/Login';
import base64 from 'base-64';

function AccountPage (props) {
    const {userState, authReady, refreshUser, logoutUser} = useContext(AuthContext);
    const [member, setMember] = useState();
    const [myName, setMyName] = useState("");
    const [myLanguage, setMyLanguage] = useState("");
    const [loading, setLoading] = useState(false);
    const [subscriptionManagementLink, setSubscriptionManagementLink] = useState();
    const [subscriptionManagementLinkStatus, setSubscriptionManagementLinkStatus] = useState("not loaded");
    const [loginDialogOpen, setLoginDialogOpen] = useState(false);
    const [changeFeedback, setChangeFeedback] = useState("");


    let navigate = useNavigate()

    console.info("AccountPage --", props);

    var roles = userState && userState.userRoles && userState.userRoles.map((r) => <li key={r}>{r}</li>);


 
    useEffect(() => {
        console.log("AccountPage.useEffect -- user:", userState)

        let headers

        if(authReady) {
            setLoginDialogOpen(!userState);

            if(userState) {

                setLoading(true)

                refreshUser()
    
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
    
                fetch('/.netlify/functions/load-members', {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        email: userState.user.email
                    })
                }).then(response => {
                    if(!response.ok) {
                        throw Error('AccountPage.useEffect -- response NOK',response.errorMessage)
                    }
          
                    return response.json()
                })
                .then(response => {
                    console.log("AccountPage.useEffect -- result", JSON.stringify(response), response)
                    
                    if(response.length === 1) {
                        setMember(response[0])
                        setMyName(response[0].name)
                        setMyLanguage(response[0].communicationLanguage ? response[0].communicationLanguage : "en")
                    }
                    else {
                        console.error("AccountPage.useEffect -- unexpected result length", response)
                        setMember(response[0])
                        setMyName(response[0].name)
                        setMyLanguage(response[0].communicationLanguage ? response[0].communicationLanguage : "en")
                    }
    
                    setLoading(false)
                })
                .catch(err => {
                    console.error(err);
                })

                fetch('/.netlify/functions/create-stripe-manage-link', {
                    method: 'POST',
                    headers: userState && {
                        Authorization: 'Bearer ' + userState.token
                        },              
                    })
                    .then((res) => {
                        if(!res.ok) {
                            throw Error('AccountPage -- error loading stripe link', res)
                        }

                        else {
                            return res.json()
                        }
                        
                    } )
                    .then((link) => {
                        setSubscriptionManagementLink(link)
                        setSubscriptionManagementLinkStatus("success")
                    })
                    .catch((err) => {
                        console.error(err)
                        setSubscriptionManagementLinkStatus("error")
                    } )
                

            }
    
            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: "account"
                })
            })
        }

     
    }, [authReady, userState])

    const onNameChange = (event) => {
        setMyName(event.target.value)
    }

    const handleSubmit = () => {
        console.log("AccountPage -- handleSubmit triggered");
        if(userState) {
            refreshUser()

            let headers = {
                Authorization: 'Bearer ' + userState.token
            }

            let data = {
                email: userState.user.email,
                name: myName,
                communicationLanguage: myLanguage,
            };

            console.log("AccountPage -- doing update",data);

            fetch('/.netlify/functions/update-member', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data)
            }).then(response => {
                if(!response.ok) {
                    setChangeFeedback("Error saving changes");
                    throw Error('AccountPage.useEffect -- response NOK',response.errorMessage)
                }
                setChangeFeedback("Changes applied");
                return response.json()
            })
            .then(response => {
                console.log("AccountPage.useEffect -- result", JSON.stringify(response), response)
            })
            .catch(err => {
                console.error(err);
            })
        }
    }

    console.log("AccountPage -- title class", "title " + (props.platform ? props.platform.platform_raw : ""))

    console.log("AccountPage -- ", member)

    console.log("AccountPage -- pricingTable", props.pricingTable)


    return (
        <div className={"standardbodywrapper " + props.screenMode}>

            <Popup className={"popup" + props.screenMode} modal open={loginDialogOpen} onClose={() => {setLoginDialogOpen(false)}}>

                <div>
                    <a className="close" onClick={() => {setLoginDialogOpen(false)}}>
                        &times;
                    </a>
                    
                    <div className="dialogTitle">
                        {props.language === "en" && "Sign in to your account"}
                        {props.language === "es" && "Inicia sesión en tu cuenta de Ninatoka"}
                    </div>
                    <br/>
                    <div className="dialogWrapper">
                    <Login {...props} navigate={window.location.pathname}/>            
                    </div>
                    


                </div>
            </Popup>

            <div className={"simplePage " + props.screenMode}>
                <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titleaccount">MY ACCOUNT</div>

                {(!authReady || loading) && <><br/><p>...</p></>}
                {!userState && <><br/><p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link> to enjoy NinaToka</p></>}

                {authReady && userState && member && 
                
                <div className="box">
                    
                    <br></br><h2>My subscription</h2>
                    <br/>
                    {userState && member && !member.hasPro && userState.pricingTable && <>

                        {!userState.stripeID && 
                            <p>You have no subscription at this moment. Sign up for a free trial to experience Ninatoka.</p>
                        }
                        {userState.stripeID && 
                            <p>You have no subscription at this moment.</p>
                        }
                        
                                                  
                        <stripe-pricing-table pricing-table-id={userState.pricingTable.pricingTableID}
                            publishable-key={userState.pricingTable.pricingTableKey}
                            customer-email={member.email}
                            client-reference-id={member.dbid}>
                        </stripe-pricing-table>   



                        </>
                    }


                    {userState && member && member.hasPro &&
                        <>
                            <p>You have an active subscription. Enjoy it!  </p>
                            
                            <button disabled={!subscriptionManagementLink} className="btn" onClick={(e) => {window.location.href = subscriptionManagementLink}}>Manage subscription</button>
                            {subscriptionManagementLinkStatus === "error" && 
                            <p>Something seems wrong with your account. Contact barbara@ninatoka.com for help.</p>} 
                        </>
                    }

                </div>
                }

                {authReady && userState && member && 

                    <div className="box">
                        <h2>My profile</h2>
                                                    
                            <br/>
                            <form>
                                <label>My email *</label><br/>
                                <input required disabled={true} type="text" className="mine" value={member.email}/><br/>
                                <br/>
                                
                                
                            
                                <label>My name *</label><br/>
                                <input required type="text" className="mine" onChange={onNameChange} placeholder="Jane Doe" value={myName}/><br/>
                                <br/>


                                <label>
                                {props.language === "en" && "Preferred communication language *"}
                                {props.language === "es" && "Idioma de comunicación preferido *"}
                                </label>
                                <select value={myLanguage} onChange={(e) => setMyLanguage(e.target.value)}>
                                    <option value="nl">{props.language === "en" && "Dutch"}{props.language === "es" && "Neerlandés"}</option>
                                    <option value="en">{props.language === "en" && "English"}{props.language === "es" && "Inglés"}</option>
                                    <option value="es">{props.language === "en" && "Spanish"}{props.language === "es" && "Español"}</option>
                                </select>
                                <br/><br/>

                                {roles && userState.userRoles.find(r => r === "admin") && 
                                <><p>Your roles:</p> <ul>{roles}</ul><br/></>}


                            </form>
                            <p>{changeFeedback}</p>

                            <button disabled={!myName || !myName.trim()} className="btn" onClick={handleSubmit}>
                                    {props.language === "en" && "Apply changes"}
                                    {props.language === "es" && "Aplicar cambios"}
                                </button>
                            
                    </div>
                }

                
            </div>
        </div>
    )
  

}




export default AccountPage;