import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../stores/authContext'
import { Link } from "react-router-dom"; 
import StatsNewMembersPerWeek from '../components/StatsNewMembersPerWeek'
import StatsVisitorsPerWeek from '../components/StatsVisitorsPerWeek';
import StatsAnonymousVisitorsPerWeek from '../components/StatsAnonymousVisitorsPerWeek'
import StatsPages from '../components/StatsPages';
import StatsRatingsPerWeek from '../components/StatsRatingsPerWeek';
import StatsExplorationsPerWeek from '../components/StatsExplorationsPerWeek';
import StatsHighlightsPerWeek from '../components/StatsHighlightsPerWeek';
import StatsOverviews from '../components/StatsOverviews';
import StatsActiveUsers from '../components/StatsActiveUsers';
import StatsConversionPerWeek from '../components/StatsConversionPerWeek';

import {
    FilteringState,
    IntegratedFiltering,
  } from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableFilterRow,
  } from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import {
    SortingState,
    IntegratedSorting,
} from '@devexpress/dx-react-grid';
  
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import StatsTopCampaigns from '../components/StatsTopCampaigns';
import StatsRecentTopCampaigns from '../components/StatsRecentTopCampaigns';

const {ORDER_BY_CREATIONDATE_DESC, ORDER_BY_LABEL_LENGTH, FILTER_VERIFIED, FILTER_UNVERIFIED, FILTER_ALL } = require("../utils/constants");


function AdminInsightsPage (props) {

    const {userState, authReady, refreshUser} = useContext(AuthContext)

    const[members, setMembers] = useState([])
    const[highlights, setHighlights] = useState([])
    const[visits, setVisits] = useState([])
    const[explorations, setExplorations] = useState([])
    const[ratings, setRatings] = useState([])
    const[userFilter, setUserFilter] = useState("")
    const[pageFilter, setPageFilter] = useState([]);
    const[exploreFilter, setExploreFilter] = useState([]);
    const[mode, setMode] = useState("stats")

    useEffect(() => {
        console.log("AdminInsightsPage.useEffect -- user:", userState)

        if(authReady) {
            let headers
            if(userState) {
                refreshUser()
    
                headers = {
                    Authorization: 'Bearer ' + userState.token
                }
            }
    
            fetch('/.netlify/functions/create-pagevisit', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    visitedPage: "admininsights"
                })
            })
        }
      
    }, [authReady, userState])

    useEffect(() => {
        console.log("AdminInsightsPage.useEffect -- triggered")

        if(authReady, userState && userState.userRoles && userState.userRoles.find(r => r === "admin")) {

            refreshUser()

            if (mode === "pagevisits") {
                
                console.log("reloading pagevisits", pageFilter);


                fetch('/.netlify/functions/load-pagevisits', 
                    {
                        method: 'POST',
                        headers: userState && {
                        Authorization: 'Bearer ' + userState.token
                        },              
                        body: JSON.stringify(
                            {
                                page: 0,
                                amount: 50,
                                username: pageFilter.find(f => f.columnName === "username") ? pageFilter.find(f => f.columnName === "username").value : "",
                                user: pageFilter.find(f => f.columnName === "user") ? pageFilter.find(f => f.columnName === "user").value : "",
                                visitedPage: pageFilter.find(f => f.columnName === "visitedPage") ? pageFilter.find(f => f.columnName === "visitedPage").value : "",
                                comment: pageFilter.find(f => f.columnName === "comment") ? pageFilter.find(f => f.columnName === "comment").value : "",
                                source: pageFilter.find(f => f.columnName === "source") ? pageFilter.find(f => f.columnName === "source").value : "",
                                sourceRef: pageFilter.find(f => f.columnName === "sourceRef") ? pageFilter.find(f => f.columnName === "sourceRef").value : "",
                                time: pageFilter.find(f => f.columnName === "time") ? pageFilter.find(f => f.columnName === "time").value : "",
                                sortMode: ORDER_BY_CREATIONDATE_DESC
                            })
                    })
                .then(response => {
                    if(!response.ok) {
                        throw Error('AdminInsightsPage.useEffect -- response NOK',response.errorMessage)
                    }

            
                    return response.json()
                })
                .then(response => {
                    console.log("AdminInsightsPage.useEffect -- response", response)
                    setVisits(response);
                })
                .catch(err => {
                    console.error(err);
                })
                
            }

 
            else if (mode === "explorations") {
           
                fetch('/.netlify/functions/load-explorations', 
                    {
                        method: 'POST',
                        headers: userState && {
                        Authorization: 'Bearer ' + userState.token
                        },              
                        body: JSON.stringify(
                            {
                                page: 0,
                                amount: 50,
                                username: exploreFilter.find(f => f.columnName === "username") ? exploreFilter.find(f => f.columnName === "username").value : "",
                                user: exploreFilter.find(f => f.columnName === "user") ? exploreFilter.find(f => f.columnName === "user").value : "",
                                concept: exploreFilter.find(f => f.columnName === "concept") ? exploreFilter.find(f => f.columnName === "concept").value : "",
                                
                                sortMode: ORDER_BY_CREATIONDATE_DESC
                            })
                    })
                .then(response => {
                    if(!response.ok) {
                        throw Error('AdminInsightsPage.useEffect -- response NOK',response.errorMessage)
                    }

            
                    return response.json()
                })
                .then(response => {
                    console.log("AdminInsightsPage.useEffect -- response", response)
                    setExplorations(response);
                })
                .catch(err => {
                    console.error(err);
                })
                
            }

            else if (mode === "ratings") {

                console.log("loading ratings");
           
                fetch('/.netlify/functions/load-ratings', 
                    {
                        method: 'POST',
                        headers: userState && {
                        Authorization: 'Bearer ' + userState.token
                        },              
                        body: JSON.stringify(
                            {
                                page: 0,
                                amount: 50,
                                query: userFilter,
                                sortMode: ORDER_BY_CREATIONDATE_DESC
                            })
                    })
                .then(response => {
                    if(!response.ok) {
                        throw Error('AdminInsightsPage.useEffect -- response NOK',response.errorMessage)
                    }

            
                    return response.json()
                })
                .then(response => {
                    console.log("AdminInsightsPage.useEffect -- ratings response", response)

                    setRatings(response);
                })
                .catch(err => {
                    console.error(err);
                })
                
            }

            else if (mode === "members") {
           
                fetch('/.netlify/functions/load-members', 
                    {
                        method: 'POST',
                        headers: userState && {
                        Authorization: 'Bearer ' + userState.token
                        },              
                        body: JSON.stringify(
                            {
                                page: 0,
                                amount: 500
                            })
                    })
                .then(response => {
                    if(!response.ok) {
                        throw Error('AdminInsightsPage.useEffect -- response NOK',response.errorMessage)
                    }

            
                    return response.json()
                })
                .then(response => {
                    console.log("AdminInsightsPage.useEffect -- response", response)
                    setMembers(response);
                })
                .catch(err => {
                    console.error(err);
                })
                
            }
        }

    }, [userState, authReady, userFilter, pageFilter, exploreFilter, mode]);



    useEffect(() => {
        console.log("AdminInsightsPage.useEffect -- members", members)
        console.log("AdminInsightsPage.useEffect -- visits", visits)
        console.log("AdminInsightsPage.useEffect -- highlights", highlights)

        console.log("AdminInsightsPage.useEffect -- explorations", explorations)
        console.log("AdminInsightsPage.useEffect -- ratings", ratings)

    }, [members, visits, highlights, explorations, ratings])




    var memberRows = [];

    
    members.forEach(m => {
        let member = [];
        console.log("going through member ", m);
        member["name"] = m.name;
        member["email"] = m.email;
        member["member since"] = m.profileAssignedOn;
        member["last visit"] = m.lastVisit;
        member["subscription"] = (m.hasPro ? "Has pro" : "No pro") + " - " + m.proSubscriptionStatus + " - " + m.formula;

        memberRows.push(member);
    });

    const DateFormatter = ({ value }) => value.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1');

    const DateTypeProvider = props => (
    <DataTypeProvider
        formatterComponent={DateFormatter}
        {...props}
    />
    );

    var membersGrid =    
        
            <Grid
                rows={memberRows}
                columns={[
                    { name: 'name', title: 'Name' },
                    { name: 'email', title: 'Email' },
                    { name: 'member since', title: 'Member since' },
                    { name: 'last visit', title: 'Last visit' },
                    { name: 'subscription', title: 'Subscription' },

                ]}
                >
                <FilteringState defaultFilters={[]}  />
                <IntegratedFiltering />
                <SortingState defaultSorting={[{ columnName: 'name', direction: 'asc' }]}/>
                <IntegratedSorting />
                <DateTypeProvider
                    for={["member since", "last visit"]}
                    />
                <Table />
                <TableHeaderRow showSortingControls />
                <TableFilterRow />
            </Grid>
        



    var explorationRows = [];


    explorations.forEach(e => {
        let exploration = [];
        console.log("going through member ", e);
        exploration["user"] = e.user;
        exploration["username"] = e.username;
        exploration["concept"] = e.concept;
        exploration["time"] = e.time;

        explorationRows.push(exploration);
    });


    var explorationsGrid =    
        
            <Grid
                rows={explorationRows}
                columns={[
                    { name: 'username', title: 'Username' },
                    { name: 'user', title: 'Email' },
                    { name: 'concept', title: 'Concept' },
                    { name: 'time', title: 'Time' }

                ]}
                >
                <FilteringState filters={exploreFilter} onFiltersChange={setExploreFilter}/>
                <IntegratedFiltering />
                <SortingState defaultSorting={[{ columnName: 'time', direction: 'desc' }]}/>
                <IntegratedSorting />
                <DateTypeProvider
                    for={["time"]}
                    />
                <Table />
                <TableHeaderRow showSortingControls />
                <TableFilterRow />
            </Grid>
 

    var ratingsHtmlList = ratings.map(r => 
        <tr key={"rating-" + r.username + r.label + r.time}>
            <td>{r.username}</td>
            <td>{r.user}</td>
            <td>{r.label}</td>
            <td>{r.contributionPoints}</td>
            <td>{r.time ? new Date(r.time.toString()).toLocaleDateString(undefined, { day: 'numeric', year: 'numeric', month: 'short'}) + " - " + new Date(r.time.toString()).toLocaleTimeString(undefined, {hour: '2-digit', minute:'2-digit'}) : "?"}</td>  
        </tr>)



    
    var visitRows = []

    visits.forEach((v) => {
        let visit = [];
        visit["username"] = v.username;
        visit["user"] = v.user;
        visit["visitedPage"] = v.visitedPage;
        visit["comment"] = v.comment;
        visit["source"] = v.source;
        visit["sourceRef"] = v.sourceRef;
        visit["time"] = v.time;

        visitRows.push(visit);
    });


    

    var visitsGrid =    
        
            <Grid
                rows={visitRows}
                columns={[
                    { name: 'username', title: 'Name' },
                    { name: 'user', title: 'Email' },
                    { name: 'visitedPage', title: 'Page' },
                    { name: 'comment', title: 'Details' },
                    { name: 'source', title: 'Source'},
                    { name: 'sourceRef', title: 'Source ref.'},
                    { name: 'time', title: 'Time' },

                ]}
                >
                <FilteringState filters={pageFilter} onFiltersChange={setPageFilter}/>
                <IntegratedFiltering />
                <SortingState defaultSorting={[{ columnName: 'time', direction: 'desc' }]}/>
                <IntegratedSorting />
                <DateTypeProvider
                    for={["time"]}
                    />
                <Table />
                <TableHeaderRow showSortingControls />
                <TableFilterRow />
            </Grid>

    var statsHtml = <>

            <StatsActiveUsers {...props} />
            <StatsRecentTopCampaigns {...props} />
            <StatsNewMembersPerWeek {...props}/>
            <StatsConversionPerWeek {...props}/>
            <StatsOverviews {...props} />
            
            <StatsVisitorsPerWeek {...props}/>
            <StatsAnonymousVisitorsPerWeek {...props}/>
            <StatsPages {...props}/>
            <StatsRatingsPerWeek {...props}/>
            <StatsExplorationsPerWeek {...props}/>
            <StatsHighlightsPerWeek {...props}/>
    </>

  
  let ratingsHtml = <>        
            <table className="padding-table-columns">
                <tbody>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Data</th>
                        <th>Contribution points</th>
                        <th>Time</th>
                    </tr>
                
                    {ratingsHtmlList}
                </tbody>
            </table>
            <br/>
    </>

    return(
        <>
            {!authReady && <p>...</p>}

            {userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && 
            <div className={"standardbodywrapper " + props.screenMode}>
                

                <div className={"adminInsightsPage " + props.screenMode}>
                    <div className={"title " + (props.platform ? props.platform.platform_raw : "")} id="titleadmininsights">ADMIN INSIGHTS</div>
                    <div className="toolbox">
                    
                        <button onClick={e => setMode("stats")} className={mode === "stats" ? "btnsmall active" : "btnsmall"}>Stats</button>
                        <button onClick={e => setMode("members")} className={mode === "members" ? "btnsmall active" : "btnsmall"}>Members</button>
                        <button onClick={e => setMode("pagevisits")} className={mode === "pagevisits" ? "btnsmall active" : "btnsmall"}>Page visits</button>
                        <button onClick={e => setMode("explorations")} className={mode === "explorations" ? "btnsmall active" : "btnsmall"}>Explorations</button>
                        <button onClick={e => setMode("ratings")} className={mode === "ratings" ? "btnsmall active" : "btnsmall"}>Ratings</button>
                    </div>

                    {mode !== "stats" && mode !== "members" && 
                            <input type="text" className="mine" onChange={(e) => setUserFilter(e.target.value)} placeholder="Filter" value={userFilter} id="admininsightsfilter"/>
                    }

                    <div className="statsPage">


                        {mode === "stats" ? statsHtml : ""}

                        {mode === "members" ? membersGrid : ""}
                        {mode === "pagevisits" ? visitsGrid : ""}
                        {mode === "explorations" ? explorationsGrid : ""}
                        {mode === "ratings" ? ratingsHtml : ""}
                    </div>

                    <br/><br/><br/>

                </div>
            </div>}
            {authReady && userState && userState.userRoles && !userState.userRoles.find(r => r === "admin") 
                    &&  <><br/><p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link> with an administrator account.</p></>}
                {authReady && !userState 
                &&  <><br/><p><Link to="/login">Log in</Link> or <Link to="/signup">sign up</Link> with an administrator account.</p></>}
        </>
    );
}

export default AdminInsightsPage;