import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import { Link } from "react-router-dom"
import AuthContext from '../stores/authContext';



/**
 * Expects props: element, onSelect function
 */
function OverviewCard(props) {

    let navigate = useNavigate()

    const {userState, authReady, refreshUser} = useContext(AuthContext)


    console.info("OverviewCard -- props",props)


        
    const handleDelete = (e) => {
        e.preventDefault()

        console.log("OverviewCard.handleDelete -- triggered", e.target)

        fetch('/.netlify/functions/delete-overview', 
        {
            method: 'POST',
            headers: userState && {
            Authorization: 'Bearer ' + userState.token
            },              
            body: JSON.stringify({
                dbid: parseInt(props.overview.id)
            })
        })
        .then(response => {
          if(!response.ok) {
              throw Error('You must be logged in to delete this')
          }

          console.log("OverviewsPage.handleDelete -- response", response)

          return response.json()
              
        })
        .then(response => {
            console.log("OverviewsPage.handleDelete -- response parsed", response)

            props.triggerLoad()
        })
        .catch(err => {
            console.error("OverviewsPage.handleDelete -- error",err);

        })

    }


        const createdOn = new Date(props.overview.createdOn).toLocaleDateString(undefined, { day: 'numeric', year: 'numeric', month: 'short'}) + " " + new Date(props.overview.createdOn).toLocaleTimeString(undefined, {hour: '2-digit', minute:'2-digit'})
        const updatedOn = props.overview.updatedOn ? new Date(props.overview.updatedOn).toLocaleDateString(undefined, { day: 'numeric', year: 'numeric', month: 'short'}) + " " + new Date(props.overview.updatedOn).toLocaleTimeString(undefined, {hour: '2-digit', minute:'2-digit'}) : undefined

        console.log("OverviewCard -- createdOn", createdOn)
        console.log("OverviewCard -- updatedOn", updatedOn)

        let className = "box newlines" + (props.className ? " " + props.className : "")

        return (
            <div className={className}>
                <div className="newlines">
                    <h2>{props.overview.name}</h2>
                    <br/>
                    <p>{props.overview.description}</p>
                    <br/>
                 
                </div>
               

                <div>
                    <span className="alignright indentright"><i>{props.overview.createdBy} - {updatedOn ? updatedOn : createdOn}</i></span>
                    
                    {authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && 
                        <><br/><span className="alignright indentright"><i>Status: {props.overview.status}</i></span></>
                    }
                
                    <br/>
                    <div className="toolbox">
                        <button className="btnsmall" onClick={(e) => {
                            navigate("/explore-overview/" + props.overview.id)
                            props.setActive("explore")
                        }} title="Explore this knowledge" ><i className="glyphicon glyphicon-expand expand" id="explore" /></button>
                        {authReady && userState && userState.userRoles && userState.userRoles.find(r => r === "admin") && <button className="btnsmall" onClick={(e) => handleDelete(e)} value={props.overview.id} title="Delete"><i className="glyphicon glyphicon-trash trash" id="rateContentUp" /></button>}
                    </div>
                    
                </div>
                

               
            </div>
        );
    
}

export default OverviewCard;