import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from '../stores/authContext'

import Searcher from '../components/Searcher'
import { ORDER_BY_LABEL_LENGTH } from '../utils/constants';
import TextareaAutosize from 'react-textarea-autosize';




export const MODE_UPDATE = "MODE_UPDATE";
export const MODE_CREATE = "MODE_CREATE";

/**
 * 
 * @param {id} props 
 * @returns 
 */
function ContributeEdge (props) {

    let navigate = useNavigate();

    const {userState, authReady, refreshUser} = useContext(AuthContext)

    const [description, setDescription] = useState("")
    const [reference, setReference] = useState(localStorage.getItem("reference"))
    const [source, setSource] = useState()
    const [target, setTarget] = useState()
    const [role, setRole] = useState()
    const [relationId, setRelationId] = useState(props.id)
    const [saveFeedback, setSaveFeedback] = useState("")
    const [existingRelations, setExistingRelations] = useState([])
    const [loaded, setLoaded] = useState(false)


    useEffect(() => {

        setRelationId(props.id)

        if(props.id && userState && authReady) {

            refreshUser()

        
            fetch('/.netlify/functions/load-relations', 
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify({
                        dbid: props.id,
                    })
                })
            .then(response => {
                if(!response.ok) {
                    throw Error('You must be logged in to view this content')
                }
        
                console.log("returning response promise")
                return response.json()
            })
            .then(response => {
                if(response && response.length === 1) {
                    console.log("ContributeEdge.useEffect --", response)

                    setDescription(response[0].description)
                    setReference(response[0].reference)
                    setSource(response[0].sourceDetails)
                    setTarget(response[0].targetDetails)
                    setRole(response[0].roleDetails)   
                    setLoaded(true)
                }
            })
            .catch(err => {
                console.error(err);
            })

        }
        if(!props.id) {
            setLoaded(true)
        }

      
    }, [props.id, userState, authReady])



    useEffect(() => {

        if(props.sourceId && userState && authReady) {

            refreshUser()

        
            fetch('/.netlify/functions/load-concepts', 
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify({
                        dbid: props.sourceId,
                    })
                })
            .then(response => {
                if(!response.ok) {
                    throw Error('You must be logged in to view this content')
                }
        
                console.log("returning response promise")
                return response.json()
            })
            .then(response => {
                if(response && response.length === 1) {
                    console.log("ContributeEdge.useEffect --", response)
                    setSource(response[0])
                }
            })
            .catch(err => {
                console.error(err);
            })

        }

      
    }, [props.sourceId, userState, authReady])


    

    const handleSubmit = (event) => {
        event.preventDefault();

        if(!source || !target || !role) {
            setSaveFeedback("Please enter all required fields.")
        }

        else {
            const conceptData = {
                dbid: props.id,
                sourceDetails: source,
                targetDetails: target,
                roleDetails: role,
                description: description ? description.trim() : undefined,
                reference: reference ? reference.trim() : undefined
            }

            if(!props.id && userState && authReady) {

                refreshUser()

                setSaveFeedback("Saving...")
        
                fetch('/.netlify/functions/create-relation', 
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify(conceptData)
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error("Failed create relationship", response ? response.json() : "no json feedback")
                    }

                    return response.json()

                })
                .then(response => {
                    console.log("ContributeNode.handleSubmit -- create-concept response",response)
                    setSaveFeedback("Success")
                    if(props.windowmode === "dialog") {
                        window.close()
                    }
                })
                .catch(err => {
                    console.error(err);
                    setSaveFeedback("Save failed")
                })
            }

            else if (props.id && userState && authReady) {

                refreshUser()

                setSaveFeedback("Saving...")

                fetch('/.netlify/functions/update-relation', 
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify(conceptData)
                })
                .then(response => {
                    if(!response.ok) {
                        throw Error("Failed update relationship", response ? response.json() : "no json feedback")
                    }

                    return response.json()

                })
                .then(response => {
                    console.log("ContributeNode.handleSubmit -- update-concept response",response)
                    setSaveFeedback("Success")
                    if(props.windowmode === "dialog") {
                        window.close()
                    }
                })
                .catch(err => {
                    console.error(err);
                    setSaveFeedback("Save failed. This relation might already exist.")
                })

                localStorage.setItem("reference", reference)
            }

            else {
                alert('Not logged in');
            }
        }
  
    }

    const handleDelete = (event) => {
        event.preventDefault();


        fetch('/.netlify/functions/delete-relation', 
        {
            method: 'POST',
            headers: userState && {
            Authorization: 'Bearer ' + userState.token
            },              
            body: JSON.stringify({
                dbid: parseInt(props.id)
            })
        })
        .then(response => {
          if(!response.ok) {
              throw Error('You must be logged in to create this content')
          }

          console.log("ReviewEdge.handleDelete -- response", response)

          return response.json()
              
        })
        .then(response => {
            console.log("ReviewEdge.handleDelete -- response parsed", response)

            setSaveFeedback("Successfully deleted")
            setSource({})
            setRole({})
            setTarget({})
            setDescription({})
            setReference({})
            setRelationId()
            if(props.windowmode === "dialog") {
                window.close()
            }
        })
        .catch(err => {
            console.error("ReviewEdge.handleDelete -- error",err);
            setSaveFeedback("Deletion failed")

        })

    }

    const onSourceChange = (newSource) => {
        console.log("ContributeEdge.onSourceChange -- triggered", newSource)
        
        setSource(newSource)
        setSaveFeedback("")

        if(newSource) {


            if(userState && authReady) {

                refreshUser()
    
                fetch('/.netlify/functions/load-relations', 
                  {
                      method: 'POST',
                      headers: userState && {
                      Authorization: 'Bearer ' + userState.token
                      },              
                      body: JSON.stringify({
                          amount: 20,
                          sortMode: ORDER_BY_LABEL_LENGTH,
                          sourceId: newSource.dbid,
                          targetId: target ? target.dbid : undefined
                      })
                  })
                .then(response => {
                    console.log("ContributeEdge.onSourceChange -- response before json'd", response)
                    if(!response.ok) {
                        throw Error('You must be logged in to view this content')
                    }
          
                    console.log("returning response promise")
                    return response.json()
                })
                .then(response => {
                    console.log("ContributeEdge.onSourceChange -- response after json'd", response)

                    setExistingRelations(response.filter(r => parseInt(r.dbid) !== parseInt(props.id)))
                    
                })
                .catch(err => {
                    console.error(err);
                })
              }
        }
        else setExistingRelations([])
    }

    const onTargetChange = (newTarget) => {
        setTarget(newTarget)
        setSaveFeedback("")

        if(newTarget) {
            
            if(userState && authReady) {

                refreshUser()
    
                fetch('/.netlify/functions/load-relations', 
                  {
                      method: 'POST',
                      headers: userState && {
                      Authorization: 'Bearer ' + userState.token
                      },              
                      body: JSON.stringify({
                          amount: 20,
                          sortMode: ORDER_BY_LABEL_LENGTH,
                          sourceId: source ? source.dbid : undefined,
                          targetId: newTarget.dbid
                      })
                  })
                .then(response => {
                    if(!response.ok) {
                        throw Error('You must be logged in to view this content')
                    }
          
                    console.log("returning response promise")
                    return response.json()
                })
                .then(response => {
                    
                    setExistingRelations(response.filter(r => parseInt(r.dbid) !== parseInt(props.id)))
                    
                })
                .catch(err => {
                    console.error(err);
                })
              }
        }
        else setExistingRelations([])
    }


    console.log("ContributeEdge -- existingRelations", existingRelations)
    var existingRelationsHtml = existingRelations.map(r => 
        <p key={r.dbid}>{r.label}</p>
        );
 
    return (
        <>
            <form onSubmit={handleSubmit}>
                <br/>
                <label htmlFor="sourceContribute">Source concept *</label><br/>

                <Searcher isDisabled={!loaded || props.id} mode="concept" {...props}  requiresInput={true} callback={onSourceChange} value={source} />
                
                <label htmlFor="relationContribute">Relation *</label><br/>

                <Searcher isDisabled={!loaded} mode="role" {...props} callback={(e) => {
                    setRole(e)
                    setSaveFeedback("")
                }} value={role}/>
                
                <label htmlFor="targetContribute">Target concept *</label><br/>

                <Searcher isDisabled={!loaded || props.id} mode="concept" {...props}  requiresInput={true} callback={onTargetChange} value={target} />               

                {existingRelations.length > 0 ? <label>Maybe your relation was already added?</label> : ""}
                <div>{existingRelationsHtml}</div>

                <label htmlFor="descriptionContribute">Description</label><br/>
                <TextareaAutosize disabled={!loaded} placeholder='Write a description about the relation you are contributing.' 
                    className="longtextarea" 
                    value={description} 
                    onChange={(e) => {
                        setDescription(e.target.value)
                        setSaveFeedback("")
                    }}/><br/>
                <label className="mine" htmlFor="referenceContribute">Reference</label><br/>
                <TextareaAutosize disabled={!loaded} placeholder="Provide a reference for this knowledge. This can be a URL, a book, an article,..." 
                    value={reference} 
                    onChange={(e) => {
                        setReference(e.target.value)
                        setSaveFeedback("")
                    }}/><br/>

                <span  className="toolbox">{saveFeedback}</span>

                <div className="toolbox"><input disabled={!source || !target || !role || !loaded} type="submit" className="btnsmall" value={relationId ? "Update" : "Contribute"}/></div>
            </form>

            {relationId && userState && userState.userRoles && userState.userRoles.find((r) => r ==="admin") && 
            <div className="toolbox"><button title="admins only" onClick={handleDelete} className="btnsmall">Delete relation from database</button></div>}
        </>
    )
    
}

export default ContributeEdge;