import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import AuthContext from '../stores/authContext'
import { ORDER_BY_LABEL_LENGTH } from '../utils/constants';
import TextareaAutosize from 'react-textarea-autosize';
import Searcher from './Searcher';



export const MODE_UPDATE = "MODE_UPDATE";
export const MODE_CREATE = "MODE_CREATE";


    /**
     * 
     * @param {id} props 
     */
function ContributeNode (props) {

    console.info("contributenode",props);

    let navigate = useNavigate();

    const {userState, authReady, refreshUser} = useContext(AuthContext)

    const [nodeName, setNodeName] = useState("")
    const [synonyms, setSynonyms] = useState("")
    const [description, setDescription] = useState("")
    const [reference, setReference] = useState(localStorage.getItem("reference"))
    const [conceptType, setConceptType] = useState({})
    const [existingConcepts, setExistingConcepts] = useState([])
    const [saveFeedback, setSaveFeedback] = useState([])
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {

        if(props.id !== undefined) {
            console.log("ContributeNode.useEffect -- [props.id, userState, authReady] props.id:",props.id)

            if(userState && authReady) {

                refreshUser()
    
                fetch('/.netlify/functions/load-concepts', 
                  {
                      method: 'POST',
                      headers: userState && {
                      Authorization: 'Bearer ' + userState.token
                      },              
                      body: JSON.stringify({
                          dbid: parseInt(props.id),
                      })
                  })
                .then(response => {
                    if(!response.ok) {
                        throw Error('You must be logged in to view this content')
                    }
          
                    console.log("returning response promise")
                    return response.json()
                })
                .then(response => {
                    console.log("ContributeNode.useEffect -- [props.id, userState, authReady] response",JSON.stringify(response))

                    
                    if(response && response.length === 1) {
                        console.log("ContributeNode.useEffect --", response)
                        setNodeName(response[0].label)
                        setSynonyms(response[0].synonyms)
                        setDescription(response[0].description)
                        setReference(response[0].reference)


                        setConceptType({label: response[0].nodeType, dbid: response[0].nodeTypeID})
                        setLoaded(true)
                    }
                })
                .catch(err => {
                    console.error(err)
                })
              }
          
              else console.error("no user known so not requesting the data")
    
        }

        else {
            console.log("ContributeNode.useEffect -- [props.id, userState, authReady] no props.id")
            setLoaded(true)
        }
    }, [props.id, userState, authReady]);
 

    const handleChangeDescription = (event) => {
        console.info("description change", event.target.value)
        setDescription(event.target.value)
        setSaveFeedback("")
    }

    const handleChangeReference = (event) =>  {
        setReference(event.target.value)
        setSaveFeedback("")
    }

    const handleChangeSynonyms = (event) => {
        setSynonyms(event.target.value)
        setSaveFeedback("")
    }

    const handleSubmit = (event) =>  {
        event.preventDefault();

        if(!nodeName) {
            setSaveFeedback("Please enter the required fields.")
        }

        else {
            const conceptData = {
                dbid: props.id,
                name: nodeName ? nodeName.trim() : undefined,
                synonyms: synonyms ? synonyms.trim() : undefined,
                description: description ? description.trim() : undefined,
                reference: reference ? reference.trim() : undefined,
                nodeType: conceptType.label,
                nodeTypeID: conceptType.dbid
            }
    
            if(!props.id && userState && authReady) {
    
                setSaveFeedback("Saving...")
    
                refreshUser()
        
                fetch('/.netlify/functions/create-concept', 
                  {
                      method: 'POST',
                      headers: userState && {
                      Authorization: 'Bearer ' + userState.token
                      },              
                      body: JSON.stringify(conceptData)
                  })
                .then(response => {
                    if(!response.ok) {
                        throw Error("Error saving concept: " + response.errorMessage)
                    }
    
                    return response.json()
    
                })
                .then(response => {
                    console.log("ContributeNode.handleSubmit -- create-concept response",response)
                    setSaveFeedback("Success")
                    if(props.windowmode === "dialog") {
                        window.close()
                    }
                })
                .catch(err => {
                    console.error(err);
                    setSaveFeedback("Save failed. A concept with this name might already exist")
                })
            }
          
            else if(props.id && userState && authReady) {
    
                setSaveFeedback("Saving...")
    
                refreshUser()
    
                fetch('/.netlify/functions/update-concept', 
                {
                    method: 'POST',
                    headers: userState && {
                    Authorization: 'Bearer ' + userState.token
                    },              
                    body: JSON.stringify(conceptData)
                })
                .then(response => {
                  if(!response.ok) {
                      throw Error("Error saving concept", response.errorMessage)
                  }
        
                  setSaveFeedback("Success")
                  if(props.windowmode === "dialog") {
                    window.close()
                    }
                })
                .catch(err => {
                    console.error(err);
                    setSaveFeedback("Save failed. Another concept with this name might already exist")
    
                })
            }

            localStorage.setItem("reference", reference)
        }
      

    }
    
    const handleDelete = (event) =>  {
        refreshUser()

        fetch('/.netlify/functions/delete-concept', 
        {
            method: 'POST',
            headers: userState && {
                Authorization: 'Bearer ' + userState.token
                },              
            body: JSON.stringify({
                dbid: parseInt(props.id)
            })
        })
        .then(response => {
          if(!response.ok) {
              throw Error('You must be logged in to create this content')
          }

          setSaveFeedback("Successfully deleted")
          setNodeName("")
          setSynonyms("")
          setDescription("")
          setReference("")
          setConceptType(undefined)
          if(props.windowmode === "dialog") {
            window.close()
            }
        })
        .catch(err => {
            console.error(err);
            setSaveFeedback("Deletion failed")
        })

    }

    const onNameChange = (event) => {
        setNodeName(event.target.value);
        setSaveFeedback("");
        if(event.target.value && event.target.value.trim()) {


            if(userState && authReady) {

                refreshUser()
    
                fetch('/.netlify/functions/load-concepts', 
                  {
                      method: 'POST',
                      headers: userState && {
                      Authorization: 'Bearer ' + userState.token
                      },              
                      body: JSON.stringify({
                          query: event.target.value ? event.target.value.trim() : undefined,
                          amount: 20,
                          sortMode: ORDER_BY_LABEL_LENGTH
                      })
                  })
                .then(response => {
                    if(!response.ok) {
                        throw Error('You must be logged in to view this content')
                    }
          
                    console.log("returning response promise")
                    return response.json()
                })
                .then(response => {
                    
                    setExistingConcepts(response.filter(r => parseInt(r.dbid) !== parseInt(props.id)))
                    
                })
                .catch(err => {
                    console.error(err);
                })
              }
          
        }
        else setExistingConcepts([]);
    }




    var existingConceptsHtml = existingConcepts.map(c => 
        <p key={c.dbid}>{c.label}</p>
        );

    return (
        <>
            {userState &&
            <>
            <form onSubmit={handleSubmit}>
                <br/>
                
                <label htmlFor="nodeNameContribute">Name for the concept *</label><br/>
                <input disabled={!loaded} required type="text" className="mine" onChange={onNameChange} placeholder="Name your concept" value={nodeName}/><br/><br/>
                <label htmlFor="synonymsContribute">Synonyms</label><br/>
                <input disabled={!loaded} type="text" className="mine" onChange={handleChangeSynonyms} placeholder="Name your concept" value={synonyms}/><br/><br/>
                {existingConcepts.length > 0 ? <label>Maybe your node was already added?</label> : ""}
                <div>{existingConceptsHtml}</div>
                {existingConcepts.length > 0 ? <br/> : ""}
                
                <label htmlFor="nodeNameContribute">Type of concept</label><br/>

                <Searcher isDisabled={!loaded} requiresInput={false} callback={(e) => setConceptType(e)} mode="concepttype" value={conceptType}/>


                <br/>

                <label htmlFor="descriptionContribute">Description</label><br/>
                <TextareaAutosize disabled={!loaded} placeholder="Provide a description for the concept you are adding. You may copy from elsewhere if you provide the reference." className="longtextarea" value={description} onChange={handleChangeDescription}/><br/>
                <label htmlFor="referenceContribute">Reference</label><br/>
                <TextareaAutosize disabled={!loaded} placeholder="Provide a reference for this knowledge. This can be a URL, a book, an article,..." value={reference} onChange={handleChangeReference}/><br/>
                <span  className="toolbox">{saveFeedback}</span>
                <div className="toolbox"> <input disabled={!nodeName || !nodeName.trim() || !loaded} type="submit" className="btnsmall" value={props.id ? "Update" : "Contribute"} /></div>
            </form>

            <div className="toolbox">{props.id && userState && userState.userRoles && userState.userRoles.find((r) => r === "admin") && <button title="admins only" onClick={handleDelete} className="btnsmall">Delete concept from database</button>}</div>
            </>
            }
        </>
    )
    
}


export default ContributeNode;