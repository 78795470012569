import { Link } from "react-router-dom"; 
import AuthContext from '../stores/authContext'
import React, { useState, useEffect, useContext } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { useNavigate } from "react-router-dom";



/**
 * 
 * @param {*} props 
 * @returns 
 */
function StatsRecentTopCampaigns (props) {
    let navigate = useNavigate()

    console.info("StatsRecentTopCampaigns render triggered");

    const {userState, authReady, refreshUser, recoverUser, acceptInvite, confirmUser} = useContext(AuthContext)
    const [data, setData] = useState({labels: [], datasets: []})
    
    useEffect(() => {

        let headers
        if(userState) {
            refreshUser()

            headers = {
                Authorization: 'Bearer ' + userState.token
            }
        }

        fetch('/.netlify/functions/load-stats-recent-top-campaigns', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({days: 30})
        })
        .then(response => {
            return response.json()
        })
        .then(response => {
            console.log("StatsRecentTopCampaigns.useeffect -- response",response)
            let _labels = []
            let _campaignData = {
                label: "Visit count",
                data: [],
                backgroundColor: '#88A36C',
            }

            response.campaigns.forEach(c => {
                _labels.push(c.campaign);
                let amount = c.visitCount ? c.visitCount : 0;
                _campaignData.data.push(amount);
            })

            console.log("StatsRecentTopCampaigns -- _campaignData",_campaignData)

            setData({labels: _labels, datasets: [_campaignData]})
        })
        .catch(error => {
            console.error(error)
        })

    }, [])
   
    ChartJS.register(
        CategoryScale,
        BarElement,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );

      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            //display: true,
            //text: 'How the knowledge of NinaToka grows...',
          },
        },
      };
    

    return (
        <>
                 
                {authReady && userState && 
            <>
                <div className={"chartMaxWidth " + props.screenMode === "mobile" ? "mobile" : "desktop"}>
                        <h2>Top campaigns past 3 months</h2>
                        <Bar options={options} data={data} width="400px"  height="400px"/>
                    </div>
                
            </>
            }
        </>
        
    );    

}

export default StatsRecentTopCampaigns;