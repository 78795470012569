
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

exports.capitalizeFirstLetter = capitalizeFirstLetter
 
exports.parseKnowledgeEdge = (parseData) => {
    console.info("load-relations.parseKnowledgeEdge -- ");

    var relation = {};

    const connectedFrom = parseData.n1 ? parseData.n1.properties.Name : undefined
    const connectedFromIDClean = parseData.n1 ? parseInt(parseData.n1.identity) : undefined
    const connectedFromID = parseData.n1 ? "N"+connectedFromIDClean : undefined
    const connectedFromDescription = parseData.n1 ? parseData.n1.properties.Description : undefined
    const connectedFromReference = parseData.n1 ? parseData.n1.properties.Reference : undefined
    const connectedFromCreatedBy = parseData.n1 ? parseData.n1.properties.CreatedBy : undefined
    const connectedFromCreatedOn = parseData.n1 ? parseData.n1.properties.CreatedOn : undefined
    const connectedFromUpdatedOn = parseData.n1 && parseData.n1.properties.UpdatedOn ? parseData.n1.properties.UpdatedOn.toString() : undefined
    const connectedFromUpdatedBy = parseData.n1 ? parseData.n1.properties.UpdatedBy : undefined

    const connectedFromConfidencePoints = parseData.n1 ? parseInt(parseData.n1.properties.ConfidencePoints) : undefined
    const connectedTo = parseData.n2 ? parseData.n2.properties.Name : undefined
    const connectedToIDClean = parseData.n2 ? parseInt(parseData.n2.identity) : undefined
    const connectedToID = parseData.n2 ? "N"+connectedToIDClean : undefined
    const connectedToDescription = parseData.n2 ? parseData.n2.properties.Description : undefined
    const connectedToReference = parseData.n2 ? parseData.n2.properties.Reference : undefined
    const connectedToCreatedBy = parseData.n2 ? parseData.n2.properties.CreatedBy : undefined
    const connectedToCreatedOn = parseData.n2 ? parseData.n2.properties.CreatedOn : undefined
    const connectedToUpdatedBy = parseData.n2 && parseData.n2.properties.UpdatedOn ? parseData.n2.properties.UpdatedOn.toString() : undefined
    const connectedToUpdatedOn = parseData.n2 ? parseData.n2.properties.UpdatedOn : undefined

    const connectedToConfidencePoints = parseData.n2 ? parseInt(parseData.n2.properties.ConfidencePoints) : undefined
    const relationRole = parseData.role ? parseData.role.properties.Name : (parseData.r ? parseData.r.properties.Role : undefined)
    const relationRoleId = parseData.r ? parseInt(parseData.r.properties.roleId) : undefined
    const relationIDClean = parseData.r ? parseInt(parseData.r.identity) : undefined
    const relationID = parseData.r ? "E"+relationIDClean : undefined
    const relationDescription = parseData.r ? parseData.r.properties.Description : undefined
    const relationReference = parseData.r ? parseData.r.properties.Reference : undefined
    const relationCreatedBy = parseData.r ? parseData.r.properties.CreatedBy : undefined
    const relationConfidencePoints = parseData.r ? parseInt(parseData.r.properties.ConfidencePoints) : undefined
    const relationUpdatedOn = parseData.r && parseData.r.properties.UpdatedOn ? parseData.r.properties.UpdatedOn.toString() : undefined
    const relationUpdatedBy = parseData.r && parseData.r.properties.UpdatedBy ? parseData.r.properties.UpdatedBy : undefined
    const nodeTypen1 = parseData.n1 ? parseData.n1.properties.NodeType : undefined
    const nodeTypen2 = parseData.n2 ? parseData.n2.properties.NodeType : undefined    



    /**
    console.info("Found record", record);
    console.info("Start node", connectedFrom, ""+connectedFromID);
    console.info("End node", connectedTo, ""+connectedToID);
    console.info("Relation", role, ""+roleID);
    */


    if (
        connectedFromID !== "" &&
        connectedFromID !== null && connectedFromID !== undefined) {
        //console.info("inserting",connectedFrom);
        relation.source = 
            {
                id: connectedFromID, 
                label: capitalizeFirstLetter(connectedFrom), 
                name: connectedFrom,
                type:"concept", 
                dbid: connectedFromIDClean,
                description: connectedFromDescription,
                reference: connectedFromReference,
                createdBy: connectedFromCreatedBy,
                createdOn: connectedFromCreatedOn ? connectedFromCreatedOn.toString() : "",
                updatedBy: connectedFromUpdatedBy,
                updatedOn: connectedFromUpdatedOn,
                confidencePoints: connectedFromConfidencePoints,
                nodeType: parseData.n1NodeType,
                nodeTypeID: parseData.n1NodeTypeID ? parseInt(parseData.n1NodeTypeID) : undefined,
                shape: parseData.n1Shape
            };
    }
    

    if (
        connectedToID !== "" &&
        connectedToID !== null && connectedToID !== undefined) {
        //console.info("inserting",connectedTo);
        relation.target = 
            {
                id: connectedToID, 
                label: capitalizeFirstLetter(connectedTo), 
                name: connectedTo,
                type:"concept", 
                dbid:  connectedToIDClean, 
                description: connectedToDescription,
                reference: connectedToReference,
                createdBy: connectedToCreatedBy,
                createdOn: connectedToCreatedOn ? connectedToCreatedOn.toString() : "",
                updatedBy: connectedToUpdatedBy,
                updatedOn: connectedToUpdatedOn,
                confidencePoints: connectedToConfidencePoints,
                nodeType: parseData.n2NodeType,
                nodeTypeID: parseData.n2NodeTypeID ? parseInt(parseData.n2NodeTypeID) : undefined,
                shape: parseData.n2Shape
            };
    }

    if(relationID !== "" && relationID !== null && relationID !== undefined) {    
      relation.relation = 
          {
              id: relationID,
              dbid: relationIDClean,
              source: connectedFromID,
              sourceLabel: connectedFrom,
              target: connectedToID,
              targetLabel: connectedTo,
              description: relationDescription,
              sourcedbid: connectedFromIDClean,
              targetdbid: connectedToIDClean,
              reference: relationReference,
              createdBy: relationCreatedBy,
              label: capitalizeFirstLetter(connectedFrom) + " " + relationRole.toLowerCase() + " " + connectedTo,
              role: relationRole,
              roleId: relationRoleId,
              createdOn: parseData.r.properties.CreatedOn ? parseData.r.properties.CreatedOn.toString() : "",
              updatedOn: relationUpdatedOn,
              updatedBy: relationUpdatedBy,
              isMaster: parseData.r.properties.isMaster,
              masterRelationId: parseData.r.properties.Master ? parseInt(parseData.r.properties.Master) : null,
              type: "relation",
              confidencePoints: relationConfidencePoints
          };
      }

      if(parseData.role && parseData.reciprocalRole) {
        relation.roleDetails = {
          dbid: parseInt(parseData.role.identity), 
          label: parseData.role.properties.Name,
          reciprocalid: parseInt(parseData.reciprocalRole.identity),
          reciprocallabel: parseData.reciprocalRole.properties.Name
        }
        relation.relation.role = parseData.role.properties.Name
      }

      console.log("utils -- parseKnowledgeEdge -- relation", relation.relation)

    return relation;

  }

  // Returns the ISO week of the date.
exports.getWeek = function(date) {
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7);
}


exports.fillWeekGaps = function(result) {

  var completedResult = []

  var previousYear
  var previousWeek

  result.forEach(yw => {
      if(!previousYear) {
          previousYear = yw.year
          previousWeek = yw.week - 1
      }

      while((yw.year === previousYear && previousWeek < yw.week - 1) || (yw.year > previousYear && yw.week !== 1) ) {
          if(previousWeek < 52) {
              completedResult.push({
                  year: parseInt(previousYear),
                  week: parseInt(previousWeek + 1),
                  //relationsAmount: parseInt(0),
                  //conceptsAmount: parseInt(0)
              })
              previousYear = parseInt(previousYear)
              previousWeek = parseInt(previousWeek + 1)
          }
          else {
              completedResult.push({
                  year: parseInt(previousYear + 1),
                  week: parseInt(1),
                  //relationsAmount: parseInt(0),
                  //conceptsAmount: parseInt(0)
              })
              previousYear = previousYear + 1
              previousWeek = 1
          }
      }

      completedResult.push(yw)

      previousYear = yw.year
      previousWeek = yw.week
  })

  return completedResult
}

// Returns the four-digit year corresponding to the ISO week of the date.
Date.prototype.getWeekYear = function(date) {
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  return date.getFullYear();
}